import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MSG_ERROR_MESSAGE, ServerEntity } from '@app/constants-enums/constants';
import { LoginService } from '@app/services/login.service';
import { Subscription } from 'rxjs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { ContractLifeCycleValEnum, ErpTypeValEnum } from "@app/constants-enums/enums";
import { ToastrService } from 'ngx-toastr';
import { DxDataGridComponent } from 'devextreme-angular';
import { ARcreditMemos, ExtraCommissionBasedRules, Invoices, MiscellaneousDeductionRules } from '@app/models/rebates-contract.model';
import { RebateService } from '@app/services/contract-service/rebate/rebate.service';
import { CurrencyOptions, PageOptions } from '@app/models/common.model';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { environment } from '@environments/environment';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import saveAs from 'file-saver';
declare const ExcelJS: ExcelJS;
import * as moment from 'moment';
import { ServerMethods } from '@app/constants-enums/constants';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
import { ViewEndusersInvoiceDetailPopUpModel, ViewInvoiceDetailPopUpModel } from '@app/models/popup.model';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';

@Component({
	selector: 'app-rebates-tierbase-calculation-view',
	templateUrl: './rebates-tierbase-calculation-view.component.html',
	styleUrls: ['./rebates-tierbase-calculation-view.component.css'],
	providers: [CustomCurrencyPipe, CustomNumberNoRoundingPipe]
})
export class RebatesTierbaseCalculationViewComponent implements OnInit {

	public invoiceDetails: any;
	@ViewChild('invoicesDataGrid') invoicesDataGrid: DxDataGridComponent;
	@ViewChild('extraRebatesBasedRulesDataGrid') extraRebatesBasedRulesDataGrid: DxDataGridComponent;
	@ViewChild('arcreditmemosDataGrid') arcreditmemosDataGrid: DxDataGridComponent;
	public pageOptions: PageOptions = new PageOptions();
	public apInvoice: any;
	private dataOutReqSubscription: Subscription;
	public invoicesDataSource: {};
	public extraRebatesBasedRulesDataSource: {};
	public arcreditmemosDataSource: {};
	public invoicesSource: Invoices[];
	public extraRebatesBasedRulesSource: ExtraCommissionBasedRules[];
	public arcreditmemosSource: ARcreditMemos[];
	public mainDataSource: any[] = [];
	public settlementPeriodStartDate: any;
	public settlementPeriodEndDate: any;
	public isQBOnlineErpUser: boolean = false;
	public isQBErpUser: boolean = false;
	@ViewChild('itemsInvoicesDataSourceRef') itemsInvoicesDataSourceRef: DxDataGridComponent;
	private getUomLstSbsn: Subscription;
	public listOfUoms: any[] = [];
	public listOfUomsHeaderFilter: any[] = [];
	public listOfRebateBasis: any[] = [];
	public listOfRebateBasisHeaderFilter: any[] = [];
	public listOfTierBasis: any[] = [];
	public listOfTierBasisHeaderFilter: any[] = [];
	public itemsInvoicesDataSource: any;
	public filterValue: any[];
	public selectedBuyingGroupMember: string;
	public listOfDocTypeHeaderFilter: any[] = [{ value: 'invoice', text: 'Invoice' }, { value: 'creditmemo', text: 'Credit Memo' }];
	@ViewChild('revenueDataSourceRef') revenueDataSourceRef: DxDataGridComponent;
	public revenueColumnArray: any[] = [];
	public listOfRevenues: any;
	public revenueAverage = 0;
	public revenueAccuredTillDate = 0;
	public revenueEstimatedTotal = 0;
	private getRevenueDetailSbsn: Subscription;
	public isShowDatagrid: boolean = false;
	public revenuefilterValue: any[] = [];
	@ViewChild('rebateDataSourceRef') rebateDataSourceRef: DxDataGridComponent;
	public isShowRebateDatagrid: boolean = false;
	public rebateColumnArray: any[] = [];
	public listOfRebates: any;
	public rebatefilterValue: any[] = [];
	private getRebateDetailSbsn: Subscription;
	public rebateAverage = 0;
	public rebateAccuredTillDate = 0;
	public rebateEstimatedTotal = 0;
	public actionFrom: string;
	public enable_classification_for_rebate: boolean = false; // Use For NETSUTE Erp Manage in system config
	public decimalPointPercentageFormat: string = '1.0-0';
	// below field came from commission calculation view
	@Input() isCallFromCommissionContract: boolean = false;
	@Input() settlementPeriodStart: any;
	@Input() settlementPeriodEnd: any;
	public decimalPointForCurrency: number;
	public globalCurrencySymbol: string;
	public isShowInvoiceDetailPopUp: boolean;
	public viewInvoiceDetailProps: ViewInvoiceDetailPopUpModel = new ViewInvoiceDetailPopUpModel();
	public isShowCardcodeColumn: boolean;
	public listOfInPricelist: any[] = [{ value: true, text: 'Yes' }, { value: false, text: 'No' }];
	public listOfActions: any[] = [];
	public isShowEndUserInvoiceDetailPopUp: boolean;
	public viewEndUserInvoiceDetailProps: ViewEndusersInvoiceDetailPopUpModel = new ViewEndusersInvoiceDetailPopUpModel();
	public listOfFeaturesCustomFields: any[] = [];
	private getDownloadExcelBlobFileSbsn: Subscription;
	public customCurrencyOption: CurrencyOptions = new CurrencyOptions();

	constructor(private datePipe: DatePipe,
		private _LoginService: LoginService,
		private _RebateService: RebateService,
		private _ToastrService: ToastrService,
		private _RestApiService: RestApiService,
		private _CustomCurrencyPipe: CustomCurrencyPipe,
		private _AppCommonSrvc: AppCommonSrvc,
		private _LoaderService: LoaderService,
		private _CustomNumberNoRoundingPipe: CustomNumberNoRoundingPipe) {
		this.doCustomizeTotalSummary = this.doCustomizeTotalSummary.bind(this);
		this.doCustomizeSubTotalSummary = this.doCustomizeSubTotalSummary.bind(this);
		this.doCustomizeTextTotalSummary = this.doCustomizeTextTotalSummary.bind(this);
		this.getCurrencyDisplayValue = this.getCurrencyDisplayValue.bind(this);
	}

	ngOnInit() {
		// get global currency		
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.decimalPointPercentageFormat = this._LoginService.decimalPointPercentageFormat;
		this.invoiceDetails = this._RebateService.getInvoiceDetails();
		this.actionFrom = this._RebateService.getActionFrom();
		this.settlementPeriodStartDate = this.datePipe.transform(this.invoiceDetails.settlementPeriodStart, 'yyyy-MM-dd');
		this.settlementPeriodEndDate = this.datePipe.transform(this.invoiceDetails.settlementPeriodEnd, 'yyyy-MM-dd');
		this.listOfActions = this._RebateService.getListOfActionsForReportDatagrid();
		// check Is QB User (no need to view code of QB user)
		if (this._LoginService.loginUser.account_detail.app_settings.erp_type) {
			if (this._LoginService.loginUser.account_detail.enable_classification_for_rebate && this.invoiceDetails.rebateType === 'sales')
				this.enable_classification_for_rebate = this._LoginService.loginUser.account_detail.enable_classification_for_rebate;
			if (this._LoginService.loginUser.account_detail.app_settings.erp_type === ErpTypeValEnum.QUICKBOOKS_ONLINE) {
				this.isQBOnlineErpUser = true;
			}
			if (this._LoginService.loginUser.account_detail.app_settings.erp_type === ErpTypeValEnum.QUICKBOOKS) {
				this.isQBErpUser = true;
			}
		}
		setTimeout(() => {
			this.customCurrencyOption = this._RebateService.getCustomCurrencyOption(this.invoiceDetails);
			this.globalCurrencySymbol = this._RebateService.globalCurrencySymbol;
		}, 100);
		this.doCheckUseCustomersFrom();
		this.fetchListOfUoms();
		this.listOfTierBasis = this._RebateService.getTierBasis();
		this.listOfRebateBasis = this._RebateService.getRebateBasis(this.globalCurrencySymbol);
		if (this.enable_classification_for_rebate) {
			this.listOfRebateBasis = this._RebateService.getRebateBasisForClasses(this.globalCurrencySymbol);
		}
		this.listOfTierBasisHeaderFilter = this.getHeaderFilter(this.listOfTierBasis, 'code', 'label');
		this.listOfRebateBasisHeaderFilter = this.getHeaderFilter(this.listOfRebateBasis, 'code', 'label');
		this.listOfFeaturesCustomFields = this._AppCommonSrvc.getFeatureCustomFields();

		// setTimeout(() => {
		// 	this.setMainDataSource();
		// }, 100);
		// this.loadInvoicesDataSource();
		// this.loadExtraRebatesBasedRulesDataSource();
		// this.loadArcreditmemosSourceDataSource();

		this.setItemsInvoicesColumnOption();
		this.loadItemInvoicesDataSource();

		//As per discussed with Prasanna for now not show rebate status changes Date-17112021
		if (this._LoginService.loginUser.account_detail.contract_lifecycle_management === ContractLifeCycleValEnum.ALWAYS_ON) {
			this.setRevenueDataGridColumns();
			this.setRebateDataGridColumns();
		}
		//End
	}

	ngOnDestroy(): void {
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		if (this.getRevenueDetailSbsn) {
			this.getRevenueDetailSbsn.unsubscribe();
		}
		if (this.getRebateDetailSbsn) {
			this.getRebateDetailSbsn.unsubscribe();
		}
	}

	fullNameColumn_calculateCellValue(rowData) {
		const length = rowData.fiscal_sequence.toString().length;
		let fiscal_sequence = rowData.fiscal_sequence;
		if (length === 1) {
			fiscal_sequence = '0' + rowData.fiscal_sequence;
		}
		return fiscal_sequence + '  ' + rowData.monthYear;
	}
	private setMainDataSource() {
		this.mainDataSource = [];
		// if (this.invoicesDataSource) {
		this.mainDataSource.push({ "key": "invoices", "name": "Invoice" });
		// }

		// if (this.extraRebatesBasedRulesSource && this.extraRebatesBasedRulesSource.length > 0) {
		// this.mainDataSource.push({ "key": "extraCommissionBasedRules", "name": "Additional incentives" });
		// }

		// if (this.arcreditmemosSource && this.arcreditmemosSource.length > 0) {
		// this.mainDataSource.push({ "key": "arcreditmemos", "name": "A/R Credit Memo" });
		// }
	}

	checkMasterDetails(objMasterDetails, key) {
		if (objMasterDetails.data) {
			if (objMasterDetails.data.key === key) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	public onExporting(e) {
		const context = this;
		const workbook = new ExcelJS.Workbook();
		let invoicesSheet, extraRebatesBasedRulesSheet, arCreditMemosSheet;

		if (context.invoicesDataGrid && context.invoicesDataGrid.instance) {
			invoicesSheet = workbook.addWorksheet('Invoice');
		}
		if (context.extraRebatesBasedRulesDataGrid && context.extraRebatesBasedRulesDataGrid.instance) {
			extraRebatesBasedRulesSheet = workbook.addWorksheet('Additional incentives');
		}
		if (context.arcreditmemosDataGrid && context.arcreditmemosDataGrid.instance) {
			arCreditMemosSheet = workbook.addWorksheet('AR Credit Memo');
		}

		let sheetName = '';
		let subusername = '';
		if (this.invoiceDetails.individualOrGroup === 'individual' && this.invoiceDetails.rebateType === 'sales') {
			subusername = this.invoiceDetails.selectCustomer || '';
		}
		if (this.invoiceDetails.individualOrGroup === 'individual' && this.invoiceDetails.rebateType === 'purchasing') {
			subusername = this.invoiceDetails.selectVendor || '';
		}
		if (this.invoiceDetails.individualOrGroup === 'buyinggroup') {
			subusername = this.invoiceDetails.selectBuyingGroup || '';
		}
		const apInvoiceNumber = this.invoiceDetails.apInvoiceNumber || '';
		let fileName = '';
		if (apInvoiceNumber) {
			fileName = apInvoiceNumber + '_';
		}
		if (subusername) {
			fileName = subusername + '_';
		}
		fileName = fileName + 'Rebates_Calculation.xlsx';
		if (this.invoiceDetails.apInvoiceNumber) {
			sheetName = this.invoiceDetails.apInvoiceNumber;
			fileName = this.invoiceDetails.apInvoiceNumber + '_' + fileName;
		}
		if (subusername) {
			sheetName = sheetName + '-' + subusername;
		}
		// const worksheet = workbook.addWorksheet(sheetName);

		if (context.invoicesDataGrid && context.invoicesDataGrid.instance) {
			exportDataGrid({
				worksheet: invoicesSheet,
				component: context.invoicesDataGrid.instance,
				autoFilterEnabled: false,
				keepColumnWidths: true,
				customizeCell: (options) => {
					const { gridCell, excelCell } = options;
					if (gridCell.rowType === "data") {
						if (gridCell.column.dataField === 'uomcode') {
							const uomCodeIndex = this.listOfUoms.findIndex(itemEle => itemEle.code === gridCell.data.uomcode);
							let uomName = '';
							if (uomCodeIndex !== -1) {
								uomName = this.listOfUoms[uomCodeIndex].name || '';
							}
							excelCell.value = uomName.toString().toUpperCase();
						}
						if (gridCell.column.dataField === 'price') {
							excelCell.value = this._CustomCurrencyPipe.transform(gridCell.data.price || 0, this.customCurrencyOption);
						}
						if (gridCell.column.dataField === 'commissionRate') {
							if (gridCell.data.commissionRate) {
								if (gridCell.data.commissionRateIn === '$') {
									excelCell.value = this._CustomCurrencyPipe.transform(gridCell.data.commissionRate || 0, this.customCurrencyOption);
								} else if (gridCell.data.commissionRateIn === '%') {
									excelCell.value = gridCell.data.commissionRate + gridCell.data.commissionRateIn;
								} else {
									excelCell.value = gridCell.data.commissionRate;
								}
							}
						}
						if (gridCell.column.dataField === 'commissionAmount' || gridCell.column.dataField === 'linetotal') {
							excelCell.value = '';
							if (gridCell.value) {
								excelCell.value = parseFloat(gridCell.value.toString()).toFixed(2);
							}
						}
					}
				}
			}).then(function (dataGridRange) {
				if (context.extraRebatesBasedRulesDataGrid && context.extraRebatesBasedRulesDataGrid.instance) {
					const footerRowIndex = dataGridRange.to.row + 2;
					const footerRow = invoicesSheet.getRow(footerRowIndex);
					invoicesSheet.mergeCells(footerRowIndex, 1, footerRowIndex, 9);
					const settlementVal = parseFloat(context.invoiceDetails.settlementValue).toFixed(2);
					footerRow.getCell(1).value = 'Total Rebates : $' + settlementVal;
					footerRow.getCell(1).font = { 'bold': true, name: 'Segoe UI Light', size: 16 };
					footerRow.getCell(1).alignment = { horizontal: 'right' };

					return exportDataGrid({
						worksheet: extraRebatesBasedRulesSheet,
						component: context.extraRebatesBasedRulesDataGrid.instance,
						autoFilterEnabled: true,
						keepColumnWidths: true,
						customizeCell: (options) => {
							const { gridCell, excelCell } = options;
							if (gridCell.rowType === "data") {
								if (gridCell.column.dataField === 'commissionAmount') {
									excelCell.value = '';
									if (gridCell.value) {
										excelCell.value = parseFloat(gridCell.value.toString()).toFixed(2);
									}
								}
							}
						}
					});
				}
			}).then(function () {
				if (context.arcreditmemosDataGrid && context.arcreditmemosDataGrid.instance) {
					return exportDataGrid({
						worksheet: arCreditMemosSheet,
						component: context.arcreditmemosDataGrid.instance,
						autoFilterEnabled: true,
						keepColumnWidths: true,
						customizeCell: (options) => {
							const { gridCell, excelCell } = options;
							if (gridCell.rowType === "data") {
								if (gridCell.column.dataField === 'commissionAmount' || gridCell.column.dataField === 'linetotal') {
									excelCell.value = '';
									if (gridCell.value) {
										excelCell.value = parseFloat(gridCell.value.toString()).toFixed(2);
									}
								}
							}
						}
					});
				}
			}).then(function () {
				// https://github.com/exceljs/exceljs#writing-xlsx

				workbook.xlsx.writeBuffer().then(function (buffer) {
					saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
				});
			});
			e.cancel = true;
		} else {
			this._ToastrService.info('Invoice data not found !', 'info', { closeButton: true, tapToDismiss: true });
		}
	}


	private loadInvoicesDataSource() {
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			contractguid: this.invoiceDetails.guid,
			section: 'invoices',
			contracttype: 'rebatescontracts'
		};
		if (this.isCallFromCommissionContract) {
			loadParams.settlementPeriodStart = this.settlementPeriodStart;
			loadParams.settlementPeriodEnd = this.settlementPeriodEnd;
		}
		this.invoicesDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	private loadExtraRebatesBasedRulesDataSource() {
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			contractguid: this.invoiceDetails.guid,
			section: 'extraCommissionBasedRules',
			contracttype: 'rebatescontracts'
		};
		if (this.isCallFromCommissionContract) {
			loadParams.settlementPeriodStart = this.settlementPeriodStart;
			loadParams.settlementPeriodEnd = this.settlementPeriodEnd;
		}
		this.extraRebatesBasedRulesDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	private loadArcreditmemosSourceDataSource() {
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			contractguid: this.invoiceDetails.guid,
			section: 'arcreditmemosSource',
			contracttype: 'rebatescontracts'
		};
		if (this.isCallFromCommissionContract) {
			loadParams.settlementPeriodStart = this.settlementPeriodStart;
			loadParams.settlementPeriodEnd = this.settlementPeriodEnd;
		}
		this.arcreditmemosDataSource = AspNetData.createStore({
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParams
		});
	}

	// Method used to set Column Caption
	private setItemsInvoicesColumnOption() {
		setTimeout(() => {
			if (this.invoiceDetails.rebate_for === 'buyinggroup' && this.invoiceDetails.paymentType === 'split') {
				this.itemsInvoicesDataSourceRef.instance.columnOption('commissionAmount', 'caption', 'Final Amount (' + this.globalCurrencySymbol + ')');
			} else {
				this.itemsInvoicesDataSourceRef.instance.columnOption('commissionAmount', 'caption', 'Rebate Amount (' + this.globalCurrencySymbol + ')');
			}
		}, 100);
	}

	// Method used to fetch List of Items
	private loadItemInvoicesDataSource() {
		let queryString = environment.URL_DATAOUT;
		const loadParams: any = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.AP_INVOICE_REPORT,
			contractguid: this.invoiceDetails.guid,
			section: 'items',
			contracttype: 'rebatescontracts',
		}
		if (this.invoiceDetails.rebate_for === 'distribution' || this.invoiceDetails.rebate_for === 'distribution_differential') {
			loadParams.is_dropdown = 'true';
			loadParams.section = 'invoices';
		}
		if (this.isCallFromCommissionContract) {
			loadParams['settlementPeriodStart'] = this.settlementPeriodStart;
			loadParams['settlementPeriodEnd'] = this.settlementPeriodEnd;
		}
		if (this.invoiceDetails.rebate_for === 'buyinggroup') {
			const seletedBuyinggroupOrMember = this._RebateService.getBuyingGroupDetails();
			if (seletedBuyinggroupOrMember && seletedBuyinggroupOrMember.cardcode) {
				this.selectedBuyingGroupMember = seletedBuyinggroupOrMember.name;
				if (seletedBuyinggroupOrMember.type === 'buyinggroup') {
					loadParams.section = 'buyinggroupitems';
				}
				if (seletedBuyinggroupOrMember.type === 'buyinggroupmember') {
					loadParams.section = 'buyinggroupmemberitems';
					this.filterValue = [
						['clientCode', '=', seletedBuyinggroupOrMember.cardcode]
					]
				}
			}
		}
		this.itemsInvoicesDataSource = AspNetData.createStore({
			loadUrl: queryString,
			loadParams: loadParams
		});
	}

	// Method used to display zero if negative summary value
	public doCustomizeTotalSummary(e) {
		if (e.value < 0) {
			return 'Rebate Total: ' + this._CustomCurrencyPipe.transform(0, this.customCurrencyOption);
		} else {
			return 'Rebate Total: ' + this._CustomCurrencyPipe.transform(e.value || 0, this.customCurrencyOption);
		}
	}

	public doInitializedItemInvoiceReport(event) {
		setTimeout(() => {
			this.doAddDynamicFeatureCustomColumns(event);
		}, 200);
	}


	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		if (this.invoiceDetails.rebate_for === 'distribution' || this.invoiceDetails.rebate_for === 'distribution_differential') {
			toolbarItems.unshift(
				// {
				// 	location: 'after',
				// 	template: 'templateSettlementPeriod',
				// },
				{
					location: 'after',
					template: 'templateActionBtns',
					visible: true,
				});
		}
		// e.toolbarOptions.items.unshift(
		// 	{
		// 		location: 'after',
		// 		template: 'templateSettlementPeriod',
		// 	},
		// 	{
		// 		location: 'after',
		// 		widget: 'dxButton',
		// 		options: {
		// 			icon: 'xlsxfile',
		// 			text: "Export",
		// 			onClick: (event: any) => {
		// 				this.doExportToExcel(event);
		// 			}
		// 		}
		// 	}
		// );
	}

	public doCellClickForInvoiceDetails(event: any) {
		if (event.row && event.row.rowType === 'data') {
			if (event.data.hasOwnProperty(event.column.dataField) && event.data[event.column.dataField] !== 0 && (event.column.dataField === 'invoiceCode' || event.column.dataField === 'docnum')) {
				if (this.invoiceDetails.rebate_for === 'distribution' || this.invoiceDetails.rebate_for === 'distribution_differential') {
					this.viewEndUserInvoiceDetailProps = new ViewEndusersInvoiceDetailPopUpModel();
					this.viewEndUserInvoiceDetailProps.docNo = event.data[event.column.dataField];
					this.viewEndUserInvoiceDetailProps.docType = 'enduserinvoice';
					this.doOpenEndUserInvoiceDetailsPopup();
				} else {
					this.viewInvoiceDetailProps = new ViewInvoiceDetailPopUpModel();
					this.viewInvoiceDetailProps.docNo = event.data[event.column.dataField];
					this.viewInvoiceDetailProps.docType = 'invoice';
					this.doOpenInvoiceDetailsPopup();
				}
			}
		}
	}

	private doOpenInvoiceDetailsPopup() {
		this.isShowInvoiceDetailPopUp = false;
		setTimeout(() => {
			this.isShowInvoiceDetailPopUp = true;
		}, 100);
	}

	private doOpenEndUserInvoiceDetailsPopup() {
		this.isShowEndUserInvoiceDetailPopUp = false;
		setTimeout(() => {
			this.isShowEndUserInvoiceDetailPopUp = true;
		}, 100);
	}

	public doExportToExcel(event) {
		if (this._LoginService.loginUser.account_detail.contract_lifecycle_management === ContractLifeCycleValEnum.ALWAYS_ON) {
			this.doExportToExcelForAlwaysOn(event);
		} else {
			this.doExportToExcelForAlwaysOff(event);
		}
	}

	//As per discussed with Prasanna for now not show rebate status changes Date-17112021
	public doExportToExcelForAlwaysOff(event) {
		if (this.itemsInvoicesDataSourceRef.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();

		const fileName = this.invoiceDetails.name.toString().replace(/\s+/g, '_') + '_' + 'Rebate.xlsx';
		let worksheet = workbook.addWorksheet('Rebate');
		const groupIndexesWithSingleItem = [];
		const listOfMasterDetailsRows = [];
		exportDataGrid({
			worksheet: worksheet,
			component: this.itemsInvoicesDataSourceRef.instance,
			keepColumnWidths: true,
			autoFilterEnabled: false,
			topLeftCell: { row: 5, column: 1 },
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;

				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'tier_basis') {
						listOfMasterDetailsRows.push({
							rowIndex: excelCell.fullAddress.row + 1,
							data: gridCell.data
						});
						excelCell.value = '';
						const tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === gridCell.data.tier_basis);
						if (tierIndex !== -1) {
							excelCell.value = this.listOfTierBasis[tierIndex].label;
						}
					}
					if (gridCell.column.dataField === 'rebate_basis') {
						excelCell.value = '';
						const rebateBasisIndex = this.listOfRebateBasis.findIndex(rb => rb.code === gridCell.data.rebate_basis);
						if (rebateBasisIndex !== -1) {
							excelCell.value = this.listOfRebateBasis[rebateBasisIndex].label;
						}
					}
					if (gridCell.column.dataField === 'commissionRate') {
						if (gridCell.data.commissionRate) {
							if (gridCell.data.commissionRateIn === '$') {
								excelCell.value = this._CustomCurrencyPipe.transform(gridCell.data.commissionRate, this.customCurrencyOption);
							} else if (gridCell.data.commissionRateIn === '%') {
								excelCell.value = this._CustomNumberNoRoundingPipe.transform(gridCell.data.commissionRate) + gridCell.data.commissionRateIn;
							} else {
								excelCell.value = gridCell.data.commissionRate;
							}
						}
					}
					if (gridCell.column.dataField === 'commissionAmount') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = this._CustomCurrencyPipe.transform(gridCell.data.commissionAmount || 0, this.customCurrencyOption);
						}
					}
					if (gridCell.column.dataField === 'split_percentage') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = this._CustomNumberNoRoundingPipe.transform(gridCell.data.split_percentage) + '%';
						}
					}
					if (gridCell.column.dataField === 'commissionAmountBeforeSplit') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = this._CustomCurrencyPipe.transform(gridCell.data.commissionAmountBeforeSplit || 0, this.customCurrencyOption);
						}
					}
				}
			}
		}).then((cellRange) => {
			const headerRow = worksheet.getRow(2);
			headerRow.height = 30;
			worksheet.mergeCells(2, 2, 2, 9);
			let secondCellValue = this.invoiceDetails.name;
			if (this.invoiceDetails.rebate_for === 'individual' || this.invoiceDetails.rebate_for === 'growth_incentive') {
				secondCellValue = this.invoiceDetails.name;
				if (this.invoiceDetails.rebateType === 'sales') {
					headerRow.getCell(1).value = 'Customer Name';
					headerRow.keepColumnWidths = true;
				}
				if (this.invoiceDetails.rebateType === 'purchasing') {
					headerRow.getCell(1).value = 'Vendor Name';
				}
			}
			if (this.invoiceDetails.rebate_for === 'buyinggroup') {
				headerRow.getCell(1).value = 'Buyinggroup Name';
				headerRow.keepColumnWidths = true;
				secondCellValue = secondCellValue + ' (' + this.selectedBuyingGroupMember + ')';
			}
			headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 14 };
			headerRow.getCell(1).alignment = { horizontal: 'left' };
			headerRow.getCell(2).value = secondCellValue;
			headerRow.getCell(2).font = { name: 'Segoe UI Light', size: 14 };
			headerRow.getCell(2).alignment = { horizontal: 'left' };

			worksheet.mergeCells(4, 2, 4, 9);
			const headerRowForSettlementPeriod = worksheet.getRow(4);
			headerRowForSettlementPeriod.getCell(1).value = 'Settlement Period ';
			headerRowForSettlementPeriod.getCell(1).font = { name: 'Segoe UI Light', size: 12 };
			headerRowForSettlementPeriod.getCell(1).alignment = { horizontal: 'left' };
			headerRowForSettlementPeriod.getCell(2).value = this.invoiceDetails.settlementPeriodStart + ' To ' + this.invoiceDetails.settlementPeriodEnd;
			headerRowForSettlementPeriod.getCell(2).font = { name: 'Segoe UI Light', size: 12 };
			headerRowForSettlementPeriod.getCell(2).alignment = { horizontal: 'left' };

			groupIndexesWithSingleItem.sort((a, b) => b - a).forEach((rowIndex) => {
				worksheet.getRow(rowIndex + 1).outlineLevel = undefined;
				worksheet.spliceRows(rowIndex, 1);
			});


			let offset = 0;

			const insertRow = (index: number, rowOffset: any, outlineLevel: any) => {
				const currentIndex = index + rowOffset;
				const row = worksheet.insertRow(currentIndex, [], "n");
				for (let j = worksheet.rowCount + 1; j > currentIndex; j--) {
					worksheet.getRow(j).outlineLevel = worksheet.getRow(j - 1).outlineLevel;
				}
				row.outlineLevel = outlineLevel;
				return row;
			};

			let columnsField = [
				"document_type",
				"invoiceCode",
				"uomcode",
				"quantity",
				"linetotal"
			];
			let columns = [
				"Doc. Type",
				"Doc. No",
				"Doc. UOM",
				"Doc. Qty",
				"Line Total (" + this.globalCurrencySymbol + ")"
			];
			let isRebateBasisDollarPerUom = false;
			let isRebateBasisPercentage = false;
			for (let i = 0; i < listOfMasterDetailsRows.length; i++) {
				isRebateBasisDollarPerUom = false;
				isRebateBasisPercentage = false;
				if (listOfMasterDetailsRows[i].data.rebate_basis === 'dollarperuom') {
					isRebateBasisDollarPerUom = true;
				}
				if (listOfMasterDetailsRows[i].data.rebate_basis === 'percentage') {
					isRebateBasisPercentage = true;
				}
				columnsField = [
					"document_type",
					"invoiceCode",
					"clientCode",
					"clientName",
					"uomcode",
					"quantity",
					"linetotal"
				];
				columns = [
					"Doc. Type",
					"Doc. No",
					"Customer Code",
					"Customer Name",
					"Doc. UOM",
					"Doc. Qty",
					"Line Total (" + this.globalCurrencySymbol + ")"
				];
				if (isRebateBasisDollarPerUom) {
					const quantityIndex = columnsField.findIndex(field => field === 'quantity');
					let contractSelectedUomIndex = columnsField.findIndex(field => field === 'contract_selectedUom');
					if (contractSelectedUomIndex === -1) {
						if (quantityIndex !== -1) {
							columnsField.splice(quantityIndex + 1, 0, 'contract_selectedUom');
							columns.splice(quantityIndex + 1, 0, 'Contract UOM');
						}
					}

					const contractQuantityIndex = columnsField.findIndex(field => field === 'contract_quantity');
					if (contractQuantityIndex === -1) {
						contractSelectedUomIndex = columnsField.findIndex(field => field === 'contract_selectedUom');
						if (contractSelectedUomIndex !== -1) {
							columnsField.splice(contractSelectedUomIndex + 1, 0, 'contract_quantity');
							columns.splice(contractSelectedUomIndex + 1, 0, 'Contract Qty');
						}
					}
				}

				if (isRebateBasisPercentage) {
					const cashDiscountIndex = columnsField.findIndex(field => field === 'cashdiscount');
					if (cashDiscountIndex === -1) {
						const linetotalIndex = columnsField.findIndex(field => field === 'linetotal');
						if (linetotalIndex !== 1) {
							columnsField.splice(linetotalIndex + 1, 0, 'cashdiscount');
							columns.splice(linetotalIndex + 1, 0, 'Cash Discount (' + this.globalCurrencySymbol + ')');
						}
					}

					const miscdeductioninventoryIndex = columnsField.findIndex(field => field === 'miscdeductioninventory');
					if (miscdeductioninventoryIndex === -1) {
						const cashdiscountIndex = columnsField.findIndex(field => field === 'cashdiscount');
						if (cashdiscountIndex !== 1) {
							columnsField.splice(cashdiscountIndex + 1, 0, 'miscdeductioninventory');
							columns.splice(cashdiscountIndex + 1, 0, 'Misc. Deduction (%)');
						}
					}

					const miscDedAmountIndex = columnsField.findIndex(field => field === 'miscDedAmount');
					if (miscDedAmountIndex === -1) {
						const miscdeductioninventoryIndex = columnsField.findIndex(field => field === 'miscdeductioninventory');
						if (miscdeductioninventoryIndex !== 1) {
							columnsField.splice(miscdeductioninventoryIndex + 1, 0, 'miscDedAmount');
							columns.splice(miscdeductioninventoryIndex + 1, 0, 'Misc. Deduction Amount (' + this.globalCurrencySymbol + ')');
						}
					}

					const finalAmountIndex = columnsField.findIndex(field => field === 'finalAmount');
					if (finalAmountIndex === -1) {
						const miscDedAmountIndex = columnsField.findIndex(field => field === 'miscDedAmount');
						if (miscDedAmountIndex !== 1) {
							columnsField.splice(miscDedAmountIndex + 1, 0, 'finalAmount');
							columns.splice(miscDedAmountIndex + 1, 0, 'Final Line Total (' + this.globalCurrencySymbol + ')');
						}
					}
				}
				if (this.enable_classification_for_rebate) {
					const documentTypeIndex = columnsField.findIndex(field => field === 'document_type');
					if (documentTypeIndex !== -1) {
						columnsField.splice(documentTypeIndex + 1, 0, 'itemname');
						columns.splice(documentTypeIndex + 1, 0, 'Items');
					}
				}
				if (this.isShowCardcodeColumn) {
					const clientNameIndex = columnsField.findIndex(field => field === 'clientName');
					if (clientNameIndex !== -1) {
						columnsField.splice(clientNameIndex + 1, 0, 'cardcode');
						columns.splice(clientNameIndex + 1, 0, 'Line Item Customer Code');
					}
				}

				let row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
				const columnIndex = cellRange.from.column;
				const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
				// row.height = 24;
				// worksheet.mergeCells(row.number, columnIndex, row.number, 7);
				if (listOfMasterDetailsRows[i].data.invoices && listOfMasterDetailsRows[i].data.invoices.length > 0) {
					row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
					columns.forEach((columnName, currentColumnIndex) => {
						row.keepColumnWidths = true;
						Object.assign(row.getCell(columnIndex + currentColumnIndex), {
							value: columnName,
							fill: {
								type: "pattern",
								pattern: "solid",
								fgColor: { argb: "BEDFE6" }
							},
							font: { bold: true },
							border: {
								bottom: borderStyle,
								left: borderStyle,
								right: borderStyle,
								top: borderStyle
							}
						});
					});

					let subTotalLineTotal = 0;
					listOfMasterDetailsRows[i].data.invoices.forEach((invoiceDetails, invoiceIndex) => {
						row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
						row.keepColumnWidths = true;
						columnsField.forEach((columnName, currentColumnIndex) => {
							const cellObject = {
								value: invoiceDetails[columnName],
								fill: {
									type: "pattern",
									pattern: "solid",
									fgColor: { argb: "BEDFE6" }
								},
								border: {
									bottom: borderStyle,
									left: borderStyle,
									right: borderStyle,
									top: borderStyle
								},
								alignment: { horizontal: 'left' }
							};

							if (columnName === 'invoiceCode') {
								cellObject.alignment.horizontal = 'right';
							}
							if (columnName === 'quantity') {
								cellObject.alignment.horizontal = 'right';
							}
							if (columnName === 'contract_quantity') {
								cellObject.alignment.horizontal = 'right';
							}

							if (columnName === 'document_type') {
								const document_type = invoiceDetails[columnName] || '';
								const docTypeIndex = this.listOfDocTypeHeaderFilter.findIndex(dt => dt.value.toString().toLowerCase() === document_type.toString().toLowerCase())
								if (docTypeIndex !== -1) {
									cellObject.value = this.listOfDocTypeHeaderFilter[docTypeIndex].text;
								}
							}
							if (columnName === 'uomcode') {
								const uomcode = invoiceDetails[columnName] || '';
								const uomIndex = this.listOfUoms.findIndex(uom => uom.code.toString().toLowerCase() === uomcode.toString().toLowerCase());
								if (uomIndex !== -1) {
									cellObject.value = this.listOfUoms[uomIndex].name.toString().toUpperCase();
								}
							}

							if (columnName === 'contract_selectedUom') {
								const contract_selectedUom = invoiceDetails[columnName] || '';
								const uomContractIndex = this.listOfUoms.findIndex(uom => uom.code.toString().toLowerCase() === contract_selectedUom.toString().toLowerCase());
								if (uomContractIndex !== -1) {
									cellObject.value = this.listOfUoms[uomContractIndex].name.toString().toUpperCase();
								}
							}

							if (columnName === 'linetotal') {
								const linetotal = invoiceDetails[columnName] || 0;
								if (!isRebateBasisPercentage) {
									subTotalLineTotal = subTotalLineTotal + linetotal;
								}
								cellObject.value = this._CustomCurrencyPipe.transform(linetotal, this.customCurrencyOption);
								cellObject.alignment.horizontal = 'right';
							}

							if (columnName === 'cashdiscount') {
								const cashdiscount = invoiceDetails[columnName] || 0;
								cellObject.value = this._CustomCurrencyPipe.transform(cashdiscount, this.customCurrencyOption);
								cellObject.alignment.horizontal = 'right';
							}

							if (columnName === 'miscdeductioninventory') {
								const miscdeductioninventory = invoiceDetails[columnName] || 0;
								cellObject.value = this._CustomNumberNoRoundingPipe.transform(+miscdeductioninventory);
								cellObject.alignment.horizontal = 'right';
							}

							if (columnName === 'miscDedAmount') {
								const miscDedAmount = invoiceDetails[columnName] || 0;
								cellObject.value = this._CustomCurrencyPipe.transform(miscDedAmount, this.customCurrencyOption);
								cellObject.alignment.horizontal = 'right';
							}

							if (columnName === 'finalAmount') {
								const finalAmount = invoiceDetails[columnName] || 0;
								if (isRebateBasisPercentage) {
									subTotalLineTotal = subTotalLineTotal + finalAmount;
								}
								cellObject.value = this._CustomCurrencyPipe.transform(finalAmount, this.customCurrencyOption);
								cellObject.alignment.horizontal = 'right';
							}

							Object.assign(row.getCell(columnIndex + currentColumnIndex), cellObject);
							row.keepColumnWidths = true;
						});
					});

					row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
					let rowLastCell: number;
					row.keepColumnWidths = true;
					if (isRebateBasisDollarPerUom) {
						worksheet.mergeCells(row.number, columnIndex, row.number, 6);
						rowLastCell = 7;
					} else if (isRebateBasisPercentage) {
						worksheet.mergeCells(row.number, columnIndex, row.number, 8);
						rowLastCell = 9;
					} else {
						worksheet.mergeCells(row.number, columnIndex, row.number, 4);
						rowLastCell = 5;
					}
					Object.assign(row.getCell(columnIndex), {
						value: 'Sub Total',
						fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						alignment: { horizontal: 'right' }
					});

					Object.assign(row.getCell(rowLastCell), {
						value: this._CustomCurrencyPipe.transform(subTotalLineTotal, this.customCurrencyOption),
						fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
						font: { bold: true },
						border: {
							bottom: borderStyle,
							left: borderStyle,
							right: borderStyle,
							top: borderStyle
						},
						alignment: { horizontal: 'right' }
					});

					row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
				}
				offset--;
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
			event.cancel = true;
		});
	}
	//End As per discussed with Prasanna for now not show rebate stats changes Date-17112021

	public doExportToExcelForAlwaysOn(event) {
		if (this.itemsInvoicesDataSourceRef.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();

		const fileName = this.invoiceDetails.name.toString().replace(/\s+/g, '_') + '_' + 'Rebate.xlsx';
		let worksheetRevenue = workbook.addWorksheet('Estimated Revenue');
		let worksheet = workbook.addWorksheet('Rebate');
		let worksheetRebate = workbook.addWorksheet('Estimated Rebate');
		const groupIndexesWithSingleItem = [];
		const listOfMasterDetailsRows = [];
		exportDataGrid({
			worksheet: worksheetRevenue,
			component: this.revenueDataSourceRef.instance,
			autoFilterEnabled: true,
			topLeftCell: { row: 5, column: 1 },
		}).then((cellRange) => {
			const revenueAverageRow = worksheetRevenue.getRow(1);
			revenueAverageRow.getCell(1).value = 'Average Revenue';
			revenueAverageRow.getCell(1).font = { name: 'Segoe UI Light', size: 12 };
			revenueAverageRow.getCell(1).alignment = { horizontal: 'left' };
			revenueAverageRow.getCell(2).value = this._CustomCurrencyPipe.transform(this.revenueAverage || 0, this.customCurrencyOption);
			revenueAverageRow.getCell(2).font = { bold: true, name: 'Segoe UI Light', size: 12 };
			revenueAverageRow.getCell(2).alignment = { horizontal: 'left' };

			const revenueAccuredTillDateRow = worksheetRevenue.getRow(2);
			revenueAccuredTillDateRow.getCell(1).value = 'Total Revenue to Date';
			revenueAccuredTillDateRow.getCell(1).font = { name: 'Segoe UI Light', size: 12 };
			revenueAccuredTillDateRow.getCell(1).alignment = { horizontal: 'left' };
			revenueAccuredTillDateRow.getCell(2).value = this._CustomCurrencyPipe.transform(this.revenueAccuredTillDate || 0, this.customCurrencyOption);
			revenueAccuredTillDateRow.getCell(2).font = { bold: true, name: 'Segoe UI Light', size: 12 };
			revenueAccuredTillDateRow.getCell(2).alignment = { horizontal: 'left' };

			const revenueEstimatedTotalRow = worksheetRevenue.getRow(3);
			revenueEstimatedTotalRow.getCell(1).value = 'Estimated Total';
			revenueEstimatedTotalRow.getCell(1).font = { name: 'Segoe UI Light', size: 12 };
			revenueEstimatedTotalRow.getCell(1).alignment = { horizontal: 'left' };
			revenueEstimatedTotalRow.getCell(2).value = this._CustomCurrencyPipe.transform(this.revenueEstimatedTotal || 0, this.customCurrencyOption);
			revenueEstimatedTotalRow.getCell(2).font = { bold: true, name: 'Segoe UI Light', size: 12 };
			revenueEstimatedTotalRow.getCell(2).alignment = { horizontal: 'left' };
		})
			.then(async () => {
				return exportDataGrid({
					worksheet: worksheet,
					component: this.itemsInvoicesDataSourceRef.instance,
					keepColumnWidths: true,
					autoFilterEnabled: false,
					topLeftCell: { row: 5, column: 1 },
					customizeCell: (options) => {
						const { gridCell, excelCell } = options;

						if (gridCell.rowType === "data") {
							if (gridCell.column.dataField === 'tier_basis') {
								listOfMasterDetailsRows.push({
									rowIndex: excelCell.fullAddress.row + 1,
									data: gridCell.data
								});
								excelCell.value = '';
								const tierIndex = this.listOfTierBasis.findIndex(tier => tier.code === gridCell.data.tier_basis);
								if (tierIndex !== -1) {
									excelCell.value = this.listOfTierBasis[tierIndex].label;
								}
							}
							if (gridCell.column.dataField === 'rebate_basis') {
								excelCell.value = '';
								const rebateBasisIndex = this.listOfRebateBasis.findIndex(rb => rb.code === gridCell.data.rebate_basis);
								if (rebateBasisIndex !== -1) {
									excelCell.value = this.listOfRebateBasis[rebateBasisIndex].label;
								}
							}
							if (gridCell.column.dataField === 'commissionRate') {
								if (gridCell.data.commissionRate) {
									if (gridCell.data.commissionRateIn === '$') {
										excelCell.value = this._CustomCurrencyPipe.transform(gridCell.data.commissionRate, this.customCurrencyOption);
									} else if (gridCell.data.commissionRateIn === '%') {
										excelCell.value = this._CustomNumberNoRoundingPipe.transform(gridCell.data.commissionRate) + gridCell.data.commissionRateIn;
									} else {
										excelCell.value = gridCell.data.commissionRate;
									}
								}
							}
							if (gridCell.column.dataField === 'commissionAmount') {
								excelCell.value = '';
								if (gridCell.value) {
									excelCell.value = this._CustomCurrencyPipe.transform(gridCell.data.commissionAmount, this.customCurrencyOption);
								}
							}
							if (gridCell.column.dataField === 'split_percentage') {
								excelCell.value = '';
								if (gridCell.value) {
									excelCell.value = this._CustomNumberNoRoundingPipe.transform(gridCell.data.split_percentage) + '%';
								}
							}
							if (gridCell.column.dataField === 'commissionAmountBeforeSplit') {
								excelCell.value = '';
								if (gridCell.value) {
									excelCell.value = this._CustomCurrencyPipe.transform(gridCell.data.commissionAmountBeforeSplit, this.customCurrencyOption);
								}
							}
						}
					}
				}).then((cellRange) => {
					const headerRow = worksheet.getRow(2);
					headerRow.height = 30;
					worksheet.mergeCells(2, 2, 2, 9);
					let secondCellValue = this.invoiceDetails.name;
					if (this.invoiceDetails.rebate_for === 'individual' || this.invoiceDetails.rebate_for === 'growth_incentive') {
						secondCellValue = this.invoiceDetails.name;
						if (this.invoiceDetails.rebateType === 'sales') {
							headerRow.getCell(1).value = 'Customer Name';
							headerRow.keepColumnWidths = true;
						}
						if (this.invoiceDetails.rebateType === 'purchasing') {
							headerRow.getCell(1).value = 'Vendor Name';
						}
					}
					if (this.invoiceDetails.rebate_for === 'buyinggroup') {
						headerRow.getCell(1).value = 'Buyinggroup Name';
						headerRow.keepColumnWidths = true;
						secondCellValue = secondCellValue + ' (' + this.selectedBuyingGroupMember + ')';
					}
					headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 14 };
					headerRow.getCell(1).alignment = { horizontal: 'left' };
					headerRow.getCell(2).value = secondCellValue;
					headerRow.getCell(2).font = { name: 'Segoe UI Light', size: 14 };
					headerRow.getCell(2).alignment = { horizontal: 'left' };

					worksheet.mergeCells(4, 2, 4, 9);
					const headerRowForSettlementPeriod = worksheet.getRow(4);
					headerRowForSettlementPeriod.getCell(1).value = 'Settlement Period ';
					headerRowForSettlementPeriod.getCell(1).font = { name: 'Segoe UI Light', size: 12 };
					headerRowForSettlementPeriod.getCell(1).alignment = { horizontal: 'left' };
					headerRowForSettlementPeriod.getCell(2).value = this.invoiceDetails.settlementPeriodStart + ' To ' + this.invoiceDetails.settlementPeriodEnd;
					headerRowForSettlementPeriod.getCell(2).font = { name: 'Segoe UI Light', size: 12 };
					headerRowForSettlementPeriod.getCell(2).alignment = { horizontal: 'left' };

					groupIndexesWithSingleItem.sort((a, b) => b - a).forEach((rowIndex) => {
						worksheet.getRow(rowIndex + 1).outlineLevel = undefined;
						worksheet.spliceRows(rowIndex, 1);
					});


					let offset = 0;

					const insertRow = (index: number, rowOffset: any, outlineLevel: any) => {
						const currentIndex = index + rowOffset;
						const row = worksheet.insertRow(currentIndex, [], "n");
						for (let j = worksheet.rowCount + 1; j > currentIndex; j--) {
							worksheet.getRow(j).outlineLevel = worksheet.getRow(j - 1).outlineLevel;
						}
						row.outlineLevel = outlineLevel;
						return row;
					};

					let columnsField = [
						"document_type",
						"invoiceCode",
						"uomcode",
						"quantity",
						"linetotal"
					];
					let columns = [
						"Doc. Type",
						"Doc. No",
						"Doc. UOM",
						"Doc. Qty",
						"Line Total (" + this.globalCurrencySymbol + ")"
					];
					let isRebateBasisDollarPerUom = false;
					let isRebateBasisPercentage = false;
					for (let i = 0; i < listOfMasterDetailsRows.length; i++) {
						isRebateBasisDollarPerUom = false;
						isRebateBasisPercentage = false;
						if (listOfMasterDetailsRows[i].data.rebate_basis === 'dollarperuom') {
							isRebateBasisDollarPerUom = true;
						}
						if (listOfMasterDetailsRows[i].data.rebate_basis === 'percentage') {
							isRebateBasisPercentage = true;
						}
						columnsField = [
							"document_type",
							"invoiceCode",
							"uomcode",
							"quantity",
							"linetotal"
						];
						columns = [
							"Doc. Type",
							"Doc. No",
							"Doc. UOM",
							"Doc. Qty",
							"Line Total (" + this.globalCurrencySymbol + ")"
						];
						if (isRebateBasisDollarPerUom) {
							columnsField = [
								"document_type",
								"invoiceCode",
								"uomcode",
								"quantity",
								'contract_selectedUom',
								'contract_quantity',
								"linetotal"
							];
							columns = [
								"Doc. Type",
								"Doc. No",
								"DOc. UOM",
								"Doc. Qty",
								"Contract UOM",
								"Contract Qty",
								"Line Total (" + this.globalCurrencySymbol + ")"
							];
						}

						if (isRebateBasisPercentage) {
							columnsField = [
								"document_type",
								"invoiceCode",
								"uomcode",
								"quantity",
								"linetotal",
								"cashdiscount",
								"miscdeductioninventory",
								"miscDedAmount",
								"finalAmount"
							];
							columns = [
								"Doc. Type",
								"Doc. No",
								"Doc. UOM",
								"Doc. Qty",
								"Line Total (" + this.globalCurrencySymbol + ")",
								"Cash Discount (" + this.globalCurrencySymbol + ")",
								"Misc. Deduction (%)",
								"Misc. Deduction Amount (" + this.globalCurrencySymbol + ")",
								"Final Line Total (" + this.globalCurrencySymbol + ")"
							];
						}

						let row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
						const columnIndex = cellRange.from.column;
						const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
						// row.height = 24;
						// worksheet.mergeCells(row.number, columnIndex, row.number, 7);
						if (listOfMasterDetailsRows[i].data.invoices && listOfMasterDetailsRows[i].data.invoices.length > 0) {
							row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
							columns.forEach((columnName, currentColumnIndex) => {
								row.keepColumnWidths = true;
								Object.assign(row.getCell(columnIndex + currentColumnIndex), {
									value: columnName,
									fill: {
										type: "pattern",
										pattern: "solid",
										fgColor: { argb: "BEDFE6" }
									},
									font: { bold: true },
									border: {
										bottom: borderStyle,
										left: borderStyle,
										right: borderStyle,
										top: borderStyle
									}
								});
							});

							let subTotalLineTotal = 0;
							listOfMasterDetailsRows[i].data.invoices.forEach((invoiceDetails, invoiceIndex) => {
								row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
								row.keepColumnWidths = true;
								columnsField.forEach((columnName, currentColumnIndex) => {
									const cellObject = {
										value: invoiceDetails[columnName],
										fill: {
											type: "pattern",
											pattern: "solid",
											fgColor: { argb: "BEDFE6" }
										},
										border: {
											bottom: borderStyle,
											left: borderStyle,
											right: borderStyle,
											top: borderStyle
										},
										alignment: { horizontal: 'left' }
									};

									if (columnName === 'invoiceCode') {
										cellObject.alignment.horizontal = 'right';
									}
									if (columnName === 'quantity') {
										cellObject.alignment.horizontal = 'right';
									}
									if (columnName === 'contract_quantity') {
										cellObject.alignment.horizontal = 'right';
									}


									if (columnName === 'document_type') {
										const document_type = invoiceDetails[columnName] || '';
										const docTypeIndex = this.listOfDocTypeHeaderFilter.findIndex(dt => dt.value.toString().toLowerCase() === document_type.toString().toLowerCase())
										if (docTypeIndex !== -1) {
											cellObject.value = this.listOfDocTypeHeaderFilter[docTypeIndex].text;
										}
									}
									if (columnName === 'uomcode') {
										const uomcode = invoiceDetails[columnName] || '';
										const uomIndex = this.listOfUoms.findIndex(uom => uom.code.toString().toLowerCase() === uomcode.toString().toLowerCase());
										if (uomIndex !== -1) {
											cellObject.value = this.listOfUoms[uomIndex].name.toString().toUpperCase();
										}
									}

									if (columnName === 'contract_selectedUom') {
										const contract_selectedUom = invoiceDetails[columnName] || '';
										const uomContractIndex = this.listOfUoms.findIndex(uom => uom.code.toString().toLowerCase() === contract_selectedUom.toString().toLowerCase());
										if (uomContractIndex !== -1) {
											cellObject.value = this.listOfUoms[uomContractIndex].name.toString().toUpperCase();
										}
									}

									if (columnName === 'linetotal') {
										const linetotal = invoiceDetails[columnName] || 0;
										if (!isRebateBasisPercentage) {
											subTotalLineTotal = subTotalLineTotal + linetotal;
										}
										cellObject.value = this._CustomCurrencyPipe.transform(linetotal, this.customCurrencyOption);
										cellObject.alignment.horizontal = 'right';
									}

									if (columnName === 'cashdiscount') {
										const cashdiscount = invoiceDetails[columnName] || 0;
										cellObject.value = this._CustomCurrencyPipe.transform(cashdiscount, this.customCurrencyOption);
										cellObject.alignment.horizontal = 'right';
									}

									if (columnName === 'miscdeductioninventory') {
										const miscdeductioninventory = invoiceDetails[columnName] || 0;
										cellObject.value = this._CustomNumberNoRoundingPipe.transform(miscdeductioninventory);
										cellObject.alignment.horizontal = 'right';
									}

									if (columnName === 'miscDedAmount') {
										const miscDedAmount = invoiceDetails[columnName] || 0;
										cellObject.value = this._CustomCurrencyPipe.transform(miscDedAmount, this.customCurrencyOption);
										cellObject.alignment.horizontal = 'right';
									}

									if (columnName === 'finalAmount') {
										const finalAmount = invoiceDetails[columnName] || 0;
										if (isRebateBasisPercentage) {
											subTotalLineTotal = subTotalLineTotal + finalAmount;
										}
										cellObject.value = this._CustomCurrencyPipe.transform(finalAmount, this.customCurrencyOption);
										cellObject.alignment.horizontal = 'right';
									}

									Object.assign(row.getCell(columnIndex + currentColumnIndex), cellObject);
									row.keepColumnWidths = true;
								});
							});

							row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
							let rowLastCell: number;
							row.keepColumnWidths = true;
							if (isRebateBasisDollarPerUom) {
								worksheet.mergeCells(row.number, columnIndex, row.number, 6);
								rowLastCell = 7;
							} else if (isRebateBasisPercentage) {
								worksheet.mergeCells(row.number, columnIndex, row.number, 8);
								rowLastCell = 9;
							} else {
								worksheet.mergeCells(row.number, columnIndex, row.number, 4);
								rowLastCell = 5;
							}
							Object.assign(row.getCell(columnIndex), {
								value: 'Sub Total',
								fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
								font: { bold: true },
								alignment: { horizontal: 'right' }
							});

							Object.assign(row.getCell(rowLastCell), {
								value: this._CustomCurrencyPipe.transform(subTotalLineTotal, this.customCurrencyOption),
								fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
								font: { bold: true },
								border: {
									bottom: borderStyle,
									left: borderStyle,
									right: borderStyle,
									top: borderStyle
								},
								alignment: { horizontal: 'right' }
							});

							row = insertRow(listOfMasterDetailsRows[i].rowIndex + i, offset++, 2);
						}
						offset--;
					}
				})
			}).then(async () => {
				if (this.rebateDataSourceRef && this.rebateDataSourceRef.instance) {
					return exportDataGrid({
						worksheet: worksheetRebate,
						component: this.rebateDataSourceRef.instance,
						autoFilterEnabled: true,
						topLeftCell: { row: 5, column: 1 },
					}).then((cellRange) => {
						const rebateAverageRow = worksheetRebate.getRow(1);
						rebateAverageRow.getCell(1).value = 'Average Rebate';
						rebateAverageRow.getCell(1).font = { name: 'Segoe UI Light', size: 12 };
						rebateAverageRow.getCell(1).alignment = { horizontal: 'left' };
						rebateAverageRow.getCell(2).value = this._CustomCurrencyPipe.transform(this.rebateAverage || 0, this.customCurrencyOption);
						rebateAverageRow.getCell(2).font = { bold: true, name: 'Segoe UI Light', size: 12 };
						rebateAverageRow.getCell(2).alignment = { horizontal: 'left' };

						const rebateAccuredTillDateRow = worksheetRebate.getRow(2);
						rebateAccuredTillDateRow.getCell(1).value = 'Total Rebate to Date';
						rebateAccuredTillDateRow.getCell(1).font = { name: 'Segoe UI Light', size: 12 };
						rebateAccuredTillDateRow.getCell(1).alignment = { horizontal: 'left' };
						rebateAccuredTillDateRow.getCell(2).value = this._CustomCurrencyPipe.transform(this.rebateAccuredTillDate || 0, this.customCurrencyOption);
						rebateAccuredTillDateRow.getCell(2).font = { bold: true, name: 'Segoe UI Light', size: 12 };
						rebateAccuredTillDateRow.getCell(2).alignment = { horizontal: 'left' };

						const rebateEstimatedTotalRow = worksheetRebate.getRow(3);
						rebateEstimatedTotalRow.getCell(1).value = 'Estimated Total';
						rebateEstimatedTotalRow.getCell(1).font = { name: 'Segoe UI Light', size: 12 };
						rebateEstimatedTotalRow.getCell(1).alignment = { horizontal: 'left' };
						rebateEstimatedTotalRow.getCell(2).value = this._CustomCurrencyPipe.transform(this.rebateEstimatedTotal || 0, this.customCurrencyOption);
						rebateEstimatedTotalRow.getCell(2).font = { bold: true, name: 'Segoe UI Light', size: 12 };
						rebateEstimatedTotalRow.getCell(2).alignment = { horizontal: 'left' };
					});
				}
			}).then(() => {
				workbook.xlsx.writeBuffer().then((buffer) => {
					saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
				});
			});
		event.cancel = true;
	}

	// #region tof Fetch List of Uom(s)

	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.UOM);
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		// this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			// this._LoaderService.hide();
			if (response) {
				if (response.flag) {
					this.listOfUoms = response.data;
					this.listOfUomsHeaderFilter = this.getHeaderFilter(response.data, 'code', 'name');
				}
			}
		}, error => {
			// this._LoaderService.hide();

		});
	}

	private getHeaderFilter(listOfArray: any[], codeField: string, textField: string): any[] {
		const listOfHeaderFilters = [];
		listOfArray.forEach(ele => {
			listOfHeaderFilters.push({ value: ele[codeField], text: ele[textField].toString().toUpperCase() });
		});
		return listOfHeaderFilters;
	}
	// #endregion

	// #region for Revenue datagrid

	// Method used to Set dyanmic column for Revenue datagrid
	private setRevenueDataGridColumns() {
		this.revenueColumnArray = [];
		if (this.invoiceDetails.settlementPeriodStart && this.invoiceDetails.settlementPeriodEnd) {
			const periodStartDateSplit = this.invoiceDetails.settlementPeriodStart.split('-');
			const periodEndDateSplit = this.invoiceDetails.settlementPeriodEnd.split('-');
			const prevDate = moment(periodStartDateSplit[0] + '-' + periodStartDateSplit[1] + '-' + periodStartDateSplit[2]).set({ hour: 0, minute: 0, second: 0 });
			const endDate = moment(periodEndDateSplit[0] + '-' + periodEndDateSplit[1] + '-' + periodEndDateSplit[2]).set({ hour: 23, minute: 59, second: 59 });
			const monthsYears = [];
			const startDate = moment(prevDate.format('YYYY-MM-DD')); // clone the startDate
			let i = 0;
			const todayDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).set('date', 1);
			while (startDate < endDate) {
				i++;
				let tempStartDate = moment(startDate.format('YYYY-MM-DD')).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).set('date', 1);
				let newMonthObj = {
					caption: startDate.format('MMM YYYY'),
					dataField: startDate.format('MMMM_YYYY').toLowerCase(),
					cssClass: '',
					cellTemplate: 'cellTemplate' + i
				};
				if (todayDate <= tempStartDate) {
					newMonthObj.caption = startDate.format('MMM YYYY') + ' (Est)';
				}
				monthsYears.push(newMonthObj);
				startDate.add(1, "month");
			}
			this.revenueColumnArray = monthsYears;
			this.isShowDatagrid = true;
			this.fetchedRevenueCounter();
			this.fetchedRevenueDatagrid();
		}
	}

	// Method used to Get List of Revenue which are dynamic column for Revenue block
	private fetchedRevenueDatagrid() {
		const loadParam = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.REBATE_REVENUE_DATA,
			data_for: 'grid',
			contracttype: 'rebatescontracts',
			contractguid: this.invoiceDetails.guid
		};

		this.listOfRevenues = AspNetData.createStore({
			key: 'guid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParam,
			onBeforeSend: function (method, ajaxOptions) {
			},
			onLoaded(result) {
			}
		});
	}

	// Method used to Get Counter for Revenue block
	private fetchedRevenueCounter() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.REBATE_REVENUE_DATA);
		formData.append('data_for', 'counter');
		formData.append('contracttype', 'rebatescontracts');
		formData.append('contractguid', this.invoiceDetails.guid);

		if (this.getRevenueDetailSbsn) {
			this.getRevenueDetailSbsn.unsubscribe();
		}
		this.getRevenueDetailSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			if (response) {
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						this.revenueAverage = response.data[0].average || 0;
						this.revenueAccuredTillDate = response.data[0].revenue_accured_till_date || 0;
						this.revenueEstimatedTotal = response.data[0].estimated_revenue_total || 0;
					}
				}
			}
		}, error => {
		});
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doRevenueToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: 'before',
				template: 'templateRevenueCounters',
			},
			// {
			// 	location: 'after',
			// 	template: 'templateRevenueSettlementPeriod',
			// },
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'xlsxfile',
			// 		text: "Export",
			// 		onClick: (event: any) => {
			// 			this.doExportToExcel(event);
			// 		}
			// 	}
			// }
		);
	}
	//#endregion

	// #region for Rebate datagrid
	// Method used to Set dyanmic column for Revenue datagrid
	private setRebateDataGridColumns() {
		this.rebateColumnArray = [];
		if (this.invoiceDetails.settlementPeriodStart && this.invoiceDetails.settlementPeriodEnd) {
			const periodStartDateSplit = this.invoiceDetails.settlementPeriodStart.split('-');
			const periodEndDateSplit = this.invoiceDetails.settlementPeriodEnd.split('-');
			const prevDate = moment(periodStartDateSplit[0] + '-' + periodStartDateSplit[1] + '-' + periodStartDateSplit[2]).set({ hour: 0, minute: 0, second: 0 });
			const endDate = moment(periodEndDateSplit[0] + '-' + periodEndDateSplit[1] + '-' + periodEndDateSplit[2]).set({ hour: 23, minute: 59, second: 59 });
			const monthsYears = [];
			const startDate = moment(prevDate.format('YYYY-MM-DD')); // clone the startDate
			let i = 0;
			const todayDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).set('date', 1);
			while (startDate < endDate) {
				i++;
				let tempStartDate = moment(startDate.format('YYYY-MM-DD')).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).set('date', 1);
				let newMonthObj = {
					caption: startDate.format('MMM YYYY'),
					dataField: startDate.format('MMMM_YYYY').toLowerCase(),
					cssClass: '',
					cellTemplate: 'cellTemplate' + i,
					dataType: 'number',
					calculateDisplayValue: this.getCurrencyDisplayValue
				};
				if (todayDate <= tempStartDate) {
					newMonthObj.caption = startDate.format('MMM YYYY') + ' (Est)';
				}
				monthsYears.push(newMonthObj);
				startDate.add(1, "month");
			}
			this.rebateColumnArray = monthsYears;
			this.isShowRebateDatagrid = true;
			this.fetchedRebateCounter();
			this.fetchedRebateDatagrid();
		}
	}

	// Method used to Display Toolbar for Export Data, Filter Data
	public doRebateToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: 'before',
				template: 'templateRebateCounters',
			},
			// {
			// 	location: 'after',
			// 	template: 'templateRevenueSettlementPeriod',
			// },
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'xlsxfile',
			// 		text: "Export",
			// 		onClick: (event: any) => {
			// 			this.doExportToExcel(event);
			// 		}
			// 	}
			// }
		);
	}

	// Method used to Get List of Revenue which are dynamic column for Revenue block
	private fetchedRebateDatagrid() {
		const loadParam = {
			usr: this._LoginService.loginUser.user,
			token: this._LoginService.loginUser.token,
			method: ServerMethods.DX_GET_ENTITY_DATA,
			entity: ServerEntity.REBATE_CHART_DATA,
			data_for: 'grid',
			contracttype: 'rebatescontracts',
			contractguid: this.invoiceDetails.guid
		};

		this.listOfRebates = AspNetData.createStore({
			key: 'guid',
			loadUrl: environment.URL_DATAOUT,
			loadParams: loadParam,
			onBeforeSend: function (method, ajaxOptions) {
			},
			onLoaded(result) {
			}
		});
	}

	// Method used to Get Counter for Revenue block
	private fetchedRebateCounter() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.GET_ENTITY_DATA);
		formData.append('entity', ServerEntity.REBATE_CHART_DATA);
		formData.append('data_for', 'counter');
		formData.append('contracttype', 'rebatescontracts');
		formData.append('contractguid', this.invoiceDetails.guid);

		if (this.getRebateDetailSbsn) {
			this.getRebateDetailSbsn.unsubscribe();
		}
		this.getRebateDetailSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe(response => {
			if (response) {
				if (response.flag) {
					if (response.data && response.data.length > 0) {
						this.rebateAverage = response.data[0].average || 0;
						this.rebateAccuredTillDate = response.data[0].rebate_accured_till_date || 0;
						this.rebateEstimatedTotal = response.data[0].estimated_rebate_total || 0;
					}
				}
			}
		}, error => {
		});
	}
	// #endregion

	private doCheckUseCustomersFrom() {
		let use_customers_from: string;
		try {
			use_customers_from = this._LoginService.loginUser.account_detail.use_customers_from;
		} catch (e) {
			use_customers_from = '';
		}
		this.isShowCardcodeColumn = false;
		if (use_customers_from === 'itemlist') {
			this.isShowCardcodeColumn = true;
			// It is displays Customer Code column in document line items.
		}
	}

	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'REFRESH':
					this.doRefreshDataGrid();
					break;
				case 'COLUMN_CHOOSER':
					this.doShowColumnChooser();
					break;
				case 'EXPORT':
					this.doExportRebateReport();
					break;
			}
		}
	}

	public doActionsBtnClick(event: any) {
	}

	private doExportRebateReport() {
		let queryString = 'usr=' + this._LoginService.loginUser.user;
		queryString = queryString + '&token=' + this._LoginService.loginUser.token;
		queryString = queryString + '&method=' + ServerMethods.DX_GET_ENTITY_DATA;
		queryString = queryString + '&entity=' + ServerEntity.AP_INVOICE_REPORT;
		queryString = queryString + '&section=invoices';
		queryString = queryString + '&contracttype=rebatescontracts';
		queryString = queryString + '&is_dropdown=1';
		queryString = queryString + '&action=export';
		queryString = queryString + '&contractguid=' + this.invoiceDetails.guid;

		this._LoaderService.show();
		if (this.getDownloadExcelBlobFileSbsn) {
			this.getDownloadExcelBlobFileSbsn.unsubscribe();
		}
		this.getDownloadExcelBlobFileSbsn = this._RestApiService.doDataOutReqDownloadFile(queryString).subscribe({
			next: response => {
				this._LoaderService.hide();
				if (response && typeof (response) === 'object') {
					this._AppCommonSrvc.downloadAnyBlobFile(response, 'Rebate_Detail_Report', 'EXCEL');
				} else {
					this._ToastrService.error(response.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
				}
			}, error: error => {
				this._LoaderService.hide();
			}
		});
	}

	private doRefreshDataGrid() {
		this.loadItemInvoicesDataSource();
	}

	private doShowColumnChooser() {
		this.itemsInvoicesDataSourceRef.instance.showColumnChooser();
	}

	private getFeatureCustomFieldsForItems() {
		const listOfFeatureCustomFieldsName: string[] = [];
		const listOfFeatureCustomFields: any[] = [];
		const listOfFeaturesCustomFields = this.listOfFeaturesCustomFields.filter(field => field.external_entity_field === true && field.entity_type === ServerEntity.ITEMS && field.section === "HEADER" && field.is_contract_field_use_for_creation === true);
		listOfFeaturesCustomFields.forEach(field => {
			if (field.display) {
				listOfFeatureCustomFieldsName.push(field.ix_custom_field);
				listOfFeatureCustomFields.push(field);
			}
		});
		return {
			listOfFeatureCustomFieldsName: listOfFeatureCustomFieldsName,
			listOfFeatureCustomFields: listOfFeatureCustomFields
		};
	}

	private doAddDynamicFeatureCustomColumns(event) {
		if (this.invoiceDetails.rebate_for === 'distribution' || this.invoiceDetails.rebate_for === 'distribution_differential') {
			const featureCustomFieldsObj = this.getFeatureCustomFieldsForItems();
			const customFieldDataList = [];
			const listOfFeaturesCustomFields = featureCustomFieldsObj.listOfFeatureCustomFields;
			if (listOfFeaturesCustomFields && listOfFeaturesCustomFields.length > 0) {
				for (let i = 0; i < listOfFeaturesCustomFields.length; i++) {
					const customField = listOfFeaturesCustomFields[i];
					const customFieldDataObj = {
						dataField: customField.ix_custom_field,
						caption: customField.custom_field_title,
						dataType: customField.custom_field_output_format,
						visible: customField.display,
						showInColumnChooser: customField.display,
						allowHeaderFiltering: false,
						allowFiltering: false,
						allowEditing: true
						// visibleIndex: tableColLen + i,
					};

					if (customField.custom_field_output_format === 'text' || customField.custom_field_output_format === 'string') {
						customFieldDataObj.allowHeaderFiltering = false;
						customFieldDataObj.allowFiltering = true;
						customFieldDataObj.dataType = 'string';
						customFieldDataObj['encodeHtml'] = false;
						if (customField.custom_field_values && customField.custom_field_values.length > 0) {
							customFieldDataObj.allowHeaderFiltering = true;
							customFieldDataObj.allowFiltering = false;
							customFieldDataObj['lookup'] = { dataSource: customField.custom_field_values, displayExpr: "value", valueExpr: "key", allowClearing: true };
						}
					}

					if (customField.custom_field_type === 'date') {
						customFieldDataObj.allowHeaderFiltering = false;
						customFieldDataObj.allowFiltering = true;
						customFieldDataObj['format'] = 'yyyy-MM-dd';
					}
					//if (responseData[i].ix_custom_field === 'over_pricelist_amount' || responseData[i].ix_custom_field === 'custom_doctotal' || responseData[i].ix_custom_field === 'base_profit_amount') {
					if (customField.custom_field_output_format === 'amount') {
						customFieldDataObj.allowHeaderFiltering = false;
						customFieldDataObj.allowFiltering = false;
						// customFieldDataObj['format'] = { type: 'currency', precision: this.decimalPointForCurrency };
						customFieldDataObj['calculateDisplayValue'] = this.getCurrencyDisplayValue;
					}
					if (customField.custom_field_output_format === 'percentage') {
						customFieldDataObj.allowHeaderFiltering = false;
						customFieldDataObj.allowFiltering = false;
						customFieldDataObj['customizeText'] = this.doCustomizeTextToPercent;
					}
					//}
					if (customField.custom_field_type === 'object') {
						if (customField.custom_field_output_format === 'string') {
							customFieldDataObj.allowHeaderFiltering = customField.custom_field_values && customField.custom_field_values.length > 0 ? true : false;
							customFieldDataObj.allowFiltering = (customField.custom_field_values && customField.custom_field_values.length > 0) ? false : true;
							customFieldDataObj.dataType = 'string';
							customFieldDataObj['encodeHtml'] = false;
							customFieldDataObj['lookup'] = { dataSource: customField.custom_field_values, displayExpr: "value", valueExpr: "key", allowClearing: true };
						}
						if (customField.custom_field_output_format === 'object') {
							customFieldDataObj.allowHeaderFiltering = false;
							customFieldDataObj.allowFiltering = true;
							customFieldDataObj['lookup'] = { dataSource: customField.custom_field_values, displayExpr: "value", valueExpr: "key" };
							customFieldDataObj.dataField = customField.ix_custom_field + '.refName';
						}
						if (customField.custom_field_output_format === 'amount') {
							customFieldDataObj.dataType = 'number';
							customFieldDataObj.allowHeaderFiltering = false;
							customFieldDataObj.allowFiltering = false;
							// customFieldDataObj['format'] = { type: 'currency', precision: this.decimalPointForCurrency };
							customFieldDataObj['calculateDisplayValue'] = this.getCurrencyDisplayValue;
						}
						if (customField.custom_field_output_format === 'percentage') {
							customFieldDataObj.dataType = 'number';
							customFieldDataObj.allowHeaderFiltering = false;
							customFieldDataObj.allowFiltering = false;
							customFieldDataObj['customizeText'] = this.doCustomizeTextToPercent;
						}
					}
					customFieldDataList.push(customFieldDataObj);
				}
				const listOfColumns = this.itemsInvoicesDataSourceRef.instance.getVisibleColumns();
				customFieldDataList.forEach(element => {
					const columnIndex = listOfColumns.findIndex(col => col.dataField === element.dataField);
					if (columnIndex === -1) {
						this.itemsInvoicesDataSourceRef.instance.addColumn(element);
					}
				});
			}
		}
	}

	public doCustomizeTextToPercent(cellInfo) {
		return this._CustomNumberNoRoundingPipe.transform((+cellInfo.value || 0)) + '%';
	}

	public getCurrencyDisplayValue(rowData: any) {
		if (rowData && rowData.hasOwnProperty('total')) {
			return this._CustomCurrencyPipe.transform(rowData.total || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('linetotal')) {
			return this._CustomCurrencyPipe.transform(rowData.linetotal || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('commissionAmount')) {
			return this._CustomCurrencyPipe.transform(rowData.commissionAmount || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('finalAmount')) {
			return this._CustomCurrencyPipe.transform(rowData.finalAmount || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('price')) {
			return this._CustomCurrencyPipe.transform(rowData.price || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('baseCommissionAmount')) {
			return this._CustomCurrencyPipe.transform(rowData.baseCommissionAmount || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('differential_price')) {
			return this._CustomCurrencyPipe.transform(rowData.differential_price || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('commissionAmountBeforeSplit')) {
			return this._CustomCurrencyPipe.transform(rowData.commissionAmountBeforeSplit || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('cashdiscount')) {
			return this._CustomCurrencyPipe.transform(rowData.cashdiscount || 0, this.customCurrencyOption);
		}
		if (rowData && rowData.hasOwnProperty('miscDedAmount')) {
			return this._CustomCurrencyPipe.transform(rowData.miscDedAmount || 0, this.customCurrencyOption);
		}

		if (this.invoiceDetails.rebate_for === 'distribution' || this.invoiceDetails.rebate_for === 'distribution_differential') {
			const featureCustomFieldsObj = this.getFeatureCustomFieldsForItems();
			const listOfFeaturesCustomFields = featureCustomFieldsObj.listOfFeatureCustomFields;
			if (listOfFeaturesCustomFields && listOfFeaturesCustomFields.length > 0) {
				listOfFeaturesCustomFields.forEach(cf => {
					if (rowData.hasOwnProperty(cf.dataField)) {
						return this._CustomCurrencyPipe.transform(rowData[cf.dataField] || 0, this.customCurrencyOption);
					}
				});
			}
		}
	}

	public doCustomizeSubTotalSummary(e) {
		if (e.value < 0) {
			return ' Sub Total: ' + this._CustomCurrencyPipe.transform(0, this.customCurrencyOption);
		} else {
			return ' Sub Total: ' + this._CustomCurrencyPipe.transform(e.value, this.customCurrencyOption);
		}
	}

	public doCustomizeTextTotalSummary(e) {
		if (e.value < 0) {
			return 'Total: ' + this._CustomCurrencyPipe.transform(0, this.customCurrencyOption);
		} else {
			return 'Total: ' + this._CustomCurrencyPipe.transform(e.value, this.customCurrencyOption);
		}
	}

}
