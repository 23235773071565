<div class="row proSearch" *ngxPermissionsOnly="isCurrentTabPermission">
	<div class="cols6">
		<div class="dx-field feildCol">
			<div class="dx-field-label">Item</div>
			<div class="dx-field-value" style="width: 100%;">
				<dx-drop-down-box [dataSource]="itemDataSource" [(value)]="lengthSelectedItems" valueExpr="itemname"
					placeholder="Select Item..." [showClearButton]="true" (onValueChanged)="onItemValueChanged($event)">
					<div *dxTemplate="let data of 'content'">
						<dx-tag-box [(value)]="arrSelectedItemList" [grouped]="true">
						</dx-tag-box>
						<dx-data-grid #itemDataGrid [dataSource]="itemDataSource" [columns]="itemGridBoxColumns"
							[selection]="{ mode: 'multiple'}" [hoverStateEnabled]="true"
							[paging]="{ enabled: true, pageSize: 10 }" [filterRow]="{ visible: true }" [height]="345"
							[(selectedRowKeys)]="arrSelectedItemList" [remoteOperations]="true" [wordWrapEnabled]="true"
							[showBorders]="true" (onSelectionChanged)="onItemSelectionChanged($event)">
							<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
							<dxo-selection [allowSelectAll]="false"></dxo-selection>
						</dx-data-grid>
					</div>
				</dx-drop-down-box>
			</div>
		</div>
	</div>
	<div class="cols5 cols5addbtn">
		<div class="dx-field feildCol">
			<div class="dx-field-label">Customer</div>
			<div class="dx-field-value" style="width: 100%;">
				<dx-drop-down-box [dataSource]="customerDataSource" [(value)]="lengthSelectedCustomers" valueExpr="name"
					placeholder="Select Customer..." [showClearButton]="true"
					(onValueChanged)="onCustomerValueChanged($event)">
					<div *dxTemplate="let data of 'content'">
						<dx-check-box text="All Customers" (onValueChanged)="checkAllCustomer()" [(value)]="chkAllCustomer"
							class="mrgB10">
						</dx-check-box>
						<dx-tag-box [(value)]="arrSelectedCustomerList" [grouped]="true"></dx-tag-box>
						<dx-data-grid #customerDataGrid [dataSource]="customerDataSource" [columns]="customerGridBoxColumns"
							[selection]="{ mode: 'multiple' }" [hoverStateEnabled]="true"
							[paging]="{ enabled: true, pageSize: 10 }" [filterRow]="{ visible: true }" [height]="345"
							[(selectedRowKeys)]="arrSelectedCustomerList" [showBorders]="true" [remoteOperations]="true"
							(onSelectionChanged)="onCustomerSelectionChanged($event)">
							<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
							<dxo-selection [allowSelectAll]="false"></dxo-selection>
						</dx-data-grid>
					</div>
				</dx-drop-down-box>
			</div>
		</div>
	</div>
	<div class="cols1 cols1addbtn txtRight">
		<dx-button class="secondaryBtn button center" type="normal" stylingMode="contained" [useSubmitBehavior]="false"
			icon="add" (onClick)="addItemTabDetails()" text="Add">
		</dx-button>
	</div>
</div>
<div class="cf mrgT10"></div>
<div class="dx-field feildCol">
	<div class="dx-field-value" style="width: 100%;">
		<dx-data-grid #itemTabDataGridRef id="gridContainer" [dataSource]="itemTabDataSource" [remoteOperations]="false"
			[showBorders]="true" [columnAutoWidth]="true" [allowColumnResizing]="true"
			(onToolbarPreparing)="doToolbarPreparing($event)" (onEditorPreparing)="doOnEditorPreparingItemTab($event)"
			(onRowRemoved)="doOnRowRemovedItemTab($event)" (onSaved)="doOnSavedItemTab()"
			[masterDetail]="{ enabled: true, template: 'customersTemplate','autoExpandAll': false }"
			(onRowPrepared)="doOnonRowPreparedItemTab($event)" [height]="'40%'" keyExpr="guid" class="hideFilterPanelInx">
			<dxo-sorting mode="single"></dxo-sorting>
			<dxo-filter-row [visible]="true"></dxo-filter-row>
			<dxo-header-filter [visible]="true"></dxo-header-filter>
			<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
			<dxo-editing mode="row" [allowUpdating]="!isDisablePermission"
				[allowDeleting]="!isDisablePermission && allowDeletingItem" [useIcons]="true">
			</dxo-editing>

			<!-- ​<div *dxTemplate="let data of 'templateFilterText'">
				<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
					<span>{{filterText}}</span>
				</div>
			</div> -->

			<dxi-column caption="Erp Primary Key" dataField="erp_primary_key" [allowEditing]="false"
				[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true" [visible]="false">
			</dxi-column>
			<dxi-column caption="SKU" [visible]="isQBOnlineErpUser" dataField="itemsku" [allowEditing]="false"
				cellTemplate="cellTemplateItemSku" [allowHeaderFiltering]="false" [allowFiltering]="true"
				[allowSorting]="true">
				<div *dxTemplate="let element of 'cellTemplateItemSku'">
					{{element.data.itemsku || ''}}
				</div>
			</dxi-column>
			<dxi-column caption="Item" dataField="itemname" [allowEditing]="false" cellTemplate="cellTemplateItemName"
				[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
				<div *dxTemplate="let element of 'cellTemplateItemName'">
					{{element.data.itemname || ''}}
				</div>
			</dxi-column>
			<dxi-column caption="Description" dataField="description" [allowEditing]="false"
				cellTemplate="cellTemplateItemDesc" [allowHeaderFiltering]="false" [allowFiltering]="true"
				[allowSorting]="true">
				<div *dxTemplate="let element of 'cellTemplateItemDesc'">
					{{element.data.description || ''}}
				</div>
			</dxi-column>

			<dxi-column caption="UOM" dataField="selectedUom" [allowHeaderFiltering]="false" [allowFiltering]="false"
				[allowEditing]="true" cellTemplate="cellTemplateUomCode">
				<dxo-lookup [dataSource]="listOfUoms" valueExpr="code" [displayExpr]="getDisplayExprUomName">
				</dxo-lookup>
				<div *dxTemplate="let element of 'cellTemplateUomCode'">
					<span>
						{{element.data.selectedUom || '' | filterFromList:listOfUoms : 'code' : 'name' | uppercase}}
					</span>
				</div>
			</dxi-column>

			<!-- <dxi-column caption="Billback Amount" dataField="billbackAmount" [allowEditing]="true"
				[allowHeaderFiltering]="false" [allowFiltering]="false"
				[allowSorting]="true">
				<div *dxTemplate="let element of 'cellTemplateBillbackAmount'">
					{{element.data.billbackAmount | customCurrencyPipe || '0'}}
				</div>
			</dxi-column> -->

			<dxi-column caption="Bought" dataField="bought" [allowEditing]="false" [allowHeaderFiltering]="false"
				[allowFiltering]="false" [allowSorting]="true" cellTemplate="cellTemplateBought">
				<div *dxTemplate="let element of 'cellTemplateBought'">
					{{(element.data.bought % 1) === 0 ? element.data.bought :element.data.bought | number : '1.2-4'}}
				</div>
			</dxi-column>
			<dxi-column caption="Sold" dataField="sold" [allowEditing]="false" [allowHeaderFiltering]="false"
				[allowFiltering]="false" [allowSorting]="true" cellTemplate="cellTemplateSold">
				<div *dxTemplate="let element of 'cellTemplateSold'">
					{{(element.data.sold % 1) === 0 ? element.data.sold :element.data.sold | number : '1.2-4'}}
				</div>
			</dxi-column>
			<dxi-column caption="Billed" dataField="billed" [allowEditing]="false" [allowHeaderFiltering]="false"
				[allowFiltering]="false" [allowSorting]="true" cellTemplate="cellTemplateBilled">
				<div *dxTemplate="let element of 'cellTemplateBilled'">
					{{(element.data.billed % 1) === 0 ? element.data.billed :element.data.billed | number : '1.2-4'}}
				</div>
			</dxi-column>
			<dxi-column caption="Available" dataField="available" [allowEditing]="false" [allowHeaderFiltering]="false"
				[allowFiltering]="false" [allowSorting]="true" cellTemplate="cellTemplateAvailable">
				<div *dxTemplate="let element of 'cellTemplateAvailable'">
					{{(element.data.available % 1) === 0 ? element.data.available :element.data.available | number :
					'1.2-4'}}
				</div>
			</dxi-column>
			<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
				[allowSorting]="false" alignment="center" [allowResizing]="true" [minWidth]="150" [width]="150"
				[allowExporting]="false">
				<dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button>
				<dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button>
			</dxi-column>

			<div *dxTemplate="let c of 'customersTemplate'">
				<dx-data-grid #customersDetailssDataGridRef id="gridContainer" [dataSource]="getCustomersByItemDetails(c)"
					(onInitNewRow)="doOnInitNewRowCustomersTab($event)" (onRowInserted)="doOnRowInsertedCustomersTab($event)"
					(onEditorPreparing)="doOnEditorPreparingCustomersTab($event)"
					(onRowUpdated)="doOnRowUpdatedCustomersTab($event)" (onRowRemoved)="doOnRowRemovedCustomersTab($event)"
					(onSaved)="doOnSavedCustomersTab($event)" keyExpr="guid" [showBorders]="true" [columnAutoWidth]="true"
					[allowColumnResizing]="true">
					<dxo-filter-row [visible]="false"></dxo-filter-row>
					<dxo-filter-panel [visible]="false"></dxo-filter-panel>
					<dxo-header-filter [visible]="false"></dxo-header-filter>
					<dxo-editing mode="row" [allowUpdating]="!isDisablePermission"
						[allowDeleting]="!isDisablePermission && allowDeletingCustomer" [allowAdding]="false"
						[useIcons]="true">
					</dxo-editing>
					<dxi-column caption="Customer" dataField="customer_name" [allowEditing]="false"
						cellTemplate="cellTemplateCustomer" [allowHeaderFiltering]="false" [allowFiltering]="true"
						[allowSorting]="true">
						<div *dxTemplate="let element of 'cellTemplateCustomer'">
							{{element.data.customer_name || ''}}
						</div>
					</dxi-column>

					<dxi-column caption="Billback Amount" dataField="billbackAmount" [allowEditing]="true"
						[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true"
						[editorOptions]="{ format: 'decimal', showClearButton: true }">
						<dxo-format type="currency" [precision]="decimalPointForCurrency">
						</dxo-format>
						<div *dxTemplate="let element of 'cellTemplateBillbackAmount'">
							{{element.data.billbackAmount || '0' | customCurrencyPipe}}
						</div>
					</dxi-column>

					<!-- <dxi-column caption="Bought" dataField="bought" [allowEditing]="false" cellTemplate="cellTemplateBought"
						[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true">
						<div *dxTemplate="let element of 'cellTemplateBought'">
							{{element.data.bought || 0}}
						</div>
					</dxi-column> -->
					<dxi-column caption="Sold" dataField="sold" [allowEditing]="false" [allowHeaderFiltering]="false"
						[allowFiltering]="false" [allowSorting]="true" cellTemplate="cellTemplateSold">
						<div *dxTemplate="let element of 'cellTemplateSold'">
							{{(element.data.sold % 1) === 0 ? element.data.sold :element.data.sold | number : '1.2-4'}}
						</div>
					</dxi-column>
					<dxi-column caption="Billed" dataField="billed" [allowEditing]="false" [allowHeaderFiltering]="false"
						[allowFiltering]="false" [allowSorting]="true" cellTemplate="cellTemplateBilled">
						<div *dxTemplate="let element of 'cellTemplateBilled'">
							{{(element.data.billed % 1) === 0 ? element.data.billed :element.data.billed | number : '1.2-4'}}
						</div>
					</dxi-column>
					<dxi-column caption="Available" dataField="available" [allowEditing]="false"
						cellTemplate="cellTemplateAvailable" [allowHeaderFiltering]="false" [allowFiltering]="false"
						[allowSorting]="true">
						<div *dxTemplate="let element of 'cellTemplateAvailable'">
							{{(element.data.available % 1) === 0 ? element.data.available :element.data.available | number :
							'1.2-4'}}
						</div>
					</dxi-column>
					<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
						[allowSorting]="false" alignment="center" [allowResizing]="true" [minWidth]="150" [width]="150"
						[allowExporting]="false">
						<dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button>
						<dxi-button name="delete" cssClass="dx-grid-delete menuTip">
						</dxi-button>
					</dxi-column>
				</dx-data-grid>
			</div>
		</dx-data-grid>
		<div *ngIf="isUploadExcelCsvFileItmGrpTab" class="noteBx mrgT10 mrgB0">
			<span>
				<b>{{noOfUnmatchedItem || 0}}</b> Item(s) out of <b>{{noOfTotalItem || 0}}</b> Item(s)
				ignored
				<span class="noteSeprator"></span>
				<b>{{noOfUnmatchedItemUom || 0}}</b> UOM(s) out of <b>{{noOfTotalItem || 0}}</b> UOM(S)
				ignored
				<span>
					<span class="noteSeprator"></span>
					<b>{{noOfmatchedItemPrice || 0}}</b> Item(s) out of <b>{{noOfTotalItem || 0}}</b>
					item(S)
					Price / Chargeback ignored
				</span>
				<strong *ngIf="listOfCsvRowForItmGrpTab && listOfCsvRowForItmGrpTab.length > 0"
					(click)="doShowCsvItemsListItmGrpTab()" class="pointerMark">Click here to
					view</strong>
			</span>
		</div>
	</div>
</div>
<div class="cf"></div>

<app-bc-upload-items-and-ignored-items-dialog (viewUploadDialogDataEvent)="viewUploadDialogData($event)">
</app-bc-upload-items-and-ignored-items-dialog>