import { Company360DashboardArCreditmemoLblEnum, Company360DashboardArCreditmemoValEnum, Company360DashboardInvoiceLblEnum, Company360DashboardInvoiceValEnum, Company360DashboardOrderLblEnum, Company360DashboardOrderValEnum, Company360DashboardQuoteLblEnum, Company360DashboardQuoteValEnum, SalesChargebacksTabsValEnum, SalesDocumentStatusesLblEnum, SalesDocumentStatusesValEnum } from '@app/constants-enums/enums';
import { SalesTabsValEnum, SalesTabsLblEnum } from '@app/constants-enums/enums';
import { Injectable } from '@angular/core';
import { ClaimAttachedDocumentModel } from '@app/models/billback-chargeback-contract.model';
import { IMG_ADD_FILE_SVG, IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, IMG_ICON_VIEW_DETAILS, IMG_ICON_XLSXFILEPOPUPIX } from '@app/constants-enums/constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SalesDataSharingService {
	public selectedTabDetails: any;
	public quotationId: any;
	public orderId: number;
	public salesInvoiceId: number;
	public ARInvoiceId: number;
	public selectedQuotation: any;
	public isEditMode = false;
	public isPageFrom = '';
	public quotesType = '';
	public storedFilters: any;

	private salesFor: string;
	private salesBy: string;
	private salesGuid: string;
	private salesQuoOrdInvDetails: any;
	public editDistributionChargebackGuid: any;
	public distributionChargebackObject: any;
	public chargebackLineItemGuid: any;
	public claimAttachedDocumentModel = new ClaimAttachedDocumentModel();
	private incomingPaytmentDetails: any;
	private incomingPaytmentId: any;
	public arCreditMemoType = '';
	private salesTileFrom = '';
	private customerID: string;
	private arCreditMemoListOpenFrom = '';
	private arCreditMemoDetailsOpenFrom: string;
	private salesListingOpenFrom: string;
	private originSalesInvoiceId: string;
	public SalesChargebacksTabsValEnum = SalesChargebacksTabsValEnum;

	private chargebackTabSource = new BehaviorSubject(this.SalesChargebacksTabsValEnum.DISTRIBUTION_CHARGEBACKS);
	currentChargebackTab = this.chargebackTabSource.asObservable();
	private actionForOrder: string;

	constructor() {
	}
	public changeChargebackTab(charebackTab: any) {
		this.chargebackTabSource.next(charebackTab)
	}

	public setArCreditMemoListOpenFrom(val: any) {
		this.arCreditMemoListOpenFrom = val;
	}
	public getArCreditMemoListOpenFrom() {
		return this.arCreditMemoListOpenFrom;
	}
	public setArCreditMemoCustomerID(val: any) {
		this.customerID = val;
	}
	public getArCreditMemoCustomerID() {
		return this.customerID;
	}
	setTabSelection(val: any) {
		this.selectedTabDetails = val;
	}
	getTabSelection() {
		return this.selectedTabDetails;
	}

	setIsEditMode(val: any) {
		this.isEditMode = val;
	}
	getIsEditMode() {
		return this.isEditMode;
	}

	setQuotationId(val: any) {
		this.quotationId = val;
	}
	getQuotationId() {
		return this.quotationId;
	}

	setSelectedQuotation(val: any) {
		this.selectedQuotation = val;
	}
	getSelectedQuotation() {
		return this.selectedQuotation;
	}

	setOrderId(val: any) {
		this.orderId = val;
	}
	getOrderId() {
		return this.orderId;
	}

	setSalesInvoiceId(val: any) {
		this.salesInvoiceId = val;
	}
	getSalesInvoiceId() {
		return this.salesInvoiceId;
	}

	setARInvoiceId(val: any) {
		this.ARInvoiceId = val;
	}
	getARInvoiceId() {
		return this.ARInvoiceId;
	}

	setQuotesIntenalType(val: any) {
		this.quotesType = val;
	}
	getQuotesIntenalType() {
		return this.quotesType;
	}
	setSalesTileFrom(val: any) {
		this.salesTileFrom = val;
	}
	getSalesTileFrom() {
		return this.salesTileFrom || '';
	}


	doBindAddressToString(address: any): string {
		let addressText: string;
		let isSeperator: Boolean;
		addressText = '';
		if (address) {
			isSeperator = true;
			if (address.block) {
				addressText = addressText + address.block;
				if (isSeperator) {
					addressText = addressText + ', ';
				}
			}
			if (address.streetno) {
				addressText = address.streetno;
				if (isSeperator) {
					addressText = addressText + ', ';
				}
			}
			if (address.street) {
				addressText = addressText + address.street;
				if (isSeperator) {
					addressText = addressText + ', ';
				}
				addressText = addressText;
			}
			if (address.addressname2) {
				addressText = addressText + address.addressname2;
				if (isSeperator) {
					addressText = addressText + ', ';
				}
				addressText = addressText;
			}
			if (address.addressname3) {
				addressText = addressText + address.addressname3;
				if (isSeperator) {
					addressText = addressText + ', ';
				}
				addressText = addressText;
			}
			if (address.city) {
				addressText = addressText + address.city;
				if (isSeperator) {
					addressText = addressText + ', ';
				}
			}
			if (address.state) {
				addressText = addressText + address.state;
				if (isSeperator) {
					addressText = addressText + ', ';
				}
				addressText = addressText;
			}
			if (address.zipcode) {
				addressText = addressText + address.zipcode;
				if (isSeperator) {
					addressText = addressText + ', ';
				}
			}
			if (address.country) {
				addressText = addressText + address.country;
				isSeperator = false;
				if (isSeperator) {
					addressText = addressText + ', ';
				}
			}
		}
		return addressText;
	}

	getSalesTabList() {
		return [
			{
				tabId: 1,
				tabCode: SalesTabsValEnum.QUOTATIONS,
				tabName: SalesTabsLblEnum.QUOTATIONS,
				detailName: 'Quote',
				isActive: false
			}, {
				tabId: 2,
				tabCode: SalesTabsValEnum.ORDERS,
				tabName: SalesTabsLblEnum.ORDERS,
				detailName: 'Order',
				isActive: false
			}, {
				tabId: 3,
				tabCode: SalesTabsValEnum.SALESINVOICES,
				tabName: SalesTabsLblEnum.SALESINVOICES,
				detailName: 'Invoice',
				isActive: false
			}];
	}

	deactivateAllTabs(tabsList) {
		tabsList.forEach(itemTab => {
			itemTab.isActive = false;
		});
	}

	public setStoredFilters(val: any) {
		this.storedFilters = val;
	}

	public getStoredFilters() {
		return this.storedFilters || undefined;
	}


	public setSalesFor(val: any) {
		this.salesFor = val;
	}

	public getSalesFor() {
		return this.salesFor || undefined;
	}

	public setSalesBy(val: any) {
		this.salesBy = val;
	}

	public getSalesBy() {
		return this.salesBy || undefined;
	}

	public setSalesGuid(val: any) {
		this.salesGuid = val;
	}

	public getSalesGuid() {
		return this.salesGuid || undefined;
	}

	public setSalesQuoOrdInvDetails(val: any) {
		this.salesQuoOrdInvDetails = val;
	}

	public getSalesQuoOrdInvDetails() {
		return this.salesQuoOrdInvDetails || undefined;
	}

	setClaimAttachmentDocumentData(claimData: ClaimAttachedDocumentModel) {
		this.claimAttachedDocumentModel.currentClaimElement = claimData.currentClaimElement;
		this.claimAttachedDocumentModel.categoriesList = claimData.categoriesList;
	}
	getClaimAttachmentDocumentData() {
		return this.claimAttachedDocumentModel;
	}

	setEditDistributionChargebackGuid(editDistributionChargebackGuid: any) {
		this.editDistributionChargebackGuid = editDistributionChargebackGuid;
	}
	getEditDistributionChargebackGuid() {
		return this.editDistributionChargebackGuid;
	}

	setDistributionChargebackObject(distributionChargebackObject: any) {
		this.distributionChargebackObject = distributionChargebackObject;
	}
	getDistributionChargebackObject() {
		return this.distributionChargebackObject;
	}

	setChargebackLineItemGuid(chargebackLineItemGuid: any) {
		this.chargebackLineItemGuid = chargebackLineItemGuid;
	}
	getChargebackLineItemGuid() {
		return this.chargebackLineItemGuid;
	}

	setIncomingPaymentId(chargebackLineItemGuid: any) {
		this.incomingPaytmentId = chargebackLineItemGuid;
	}
	getIncomingPaymentId() {
		return this.incomingPaytmentId;
	}

	setIncomingPaymentDetails(chargebackLineItemGuid: any) {
		this.incomingPaytmentDetails = chargebackLineItemGuid;
	}
	getIncomingPaymentDetails() {
		return this.incomingPaytmentDetails;
	}

	public setArCreditMemoDetailsOpenFrom(val: any) {
		this.arCreditMemoDetailsOpenFrom = val;
	}

	public getArCreditMemoDetailsOpenFrom() {
		return this.arCreditMemoDetailsOpenFrom || '';
	}

	public setSalesListingOpenFrom(val: any) {
		this.salesListingOpenFrom = val;
	}

	public getSalesListingOpenFrom() {
		return this.salesListingOpenFrom || '';
	}

	public getListOfOrderShowDataFrom() {
		const listOfShowDataFrom = [
			{
				value: Company360DashboardOrderValEnum.CURRENT_MONTH,
				text: Company360DashboardOrderLblEnum.CURRENT_MONTH
			},
			{
				value: Company360DashboardOrderValEnum.LAST_MONTH,
				text: Company360DashboardOrderLblEnum.LAST_MONTH
			}
		];
		return listOfShowDataFrom;
	}

	public getListOfOrderStatusConsider() {
		const listOfStatusConsider = [
			{
				"value": Company360DashboardOrderValEnum.STATUS_ALL,
				"text": Company360DashboardOrderLblEnum.STATUS_ALL
			},
			{
				"value": Company360DashboardOrderValEnum.STATUS_OPEN,
				"text": Company360DashboardOrderLblEnum.STATUS_OPEN
			},
			{
				"value": Company360DashboardOrderValEnum.STATUS_CLOSED,
				"text": Company360DashboardOrderLblEnum.STATUS_CLOSED
			}
		];
		return listOfStatusConsider;
	}

	public getlistOfQuoteDateUses() {
		const listOfDateUses: any[] = [
			{
				"value": Company360DashboardQuoteValEnum.DATE_DOC_DATE,
				"text": Company360DashboardQuoteLblEnum.DATE_DOC_DATE
			},
			{
				"value": Company360DashboardQuoteValEnum.DATE_DUE_DATE,
				"text": Company360DashboardQuoteLblEnum.DATE_DUE_DATE
			}
		]
		return listOfDateUses;
	}

	public getlistOfOrderDateUses() {
		const listOfDateUses: any[] = [
			{
				"value": Company360DashboardOrderValEnum.DATE_DOC_DATE,
				"text": Company360DashboardOrderLblEnum.DATE_DOC_DATE
			},
			{
				"value": Company360DashboardOrderValEnum.DATE_DUE_DATE,
				"text": Company360DashboardOrderLblEnum.DATE_DUE_DATE
			}
		]
		return listOfDateUses;
	}

	public getListOfInvoiceShowDataFrom() {
		const listOfShowDataFrom = [
			{
				value: Company360DashboardInvoiceValEnum.CURRENT_MONTH,
				text: Company360DashboardInvoiceLblEnum.CURRENT_MONTH
			},
			{
				value: Company360DashboardInvoiceValEnum.LAST_MONTH,
				text: Company360DashboardInvoiceLblEnum.LAST_MONTH
			}
		];
		return listOfShowDataFrom;
	}

	public getListOfInvoiceStatusConsider() {
		const listOfStatusConsider = [
			{
				"value": Company360DashboardInvoiceValEnum.STATUS_ALL,
				"text": Company360DashboardInvoiceLblEnum.STATUS_ALL
			},
			{
				"value": Company360DashboardInvoiceValEnum.STATUS_OPEN,
				"text": Company360DashboardInvoiceLblEnum.STATUS_OPEN
			},
			{
				"value": Company360DashboardInvoiceValEnum.STATUS_CLOSED,
				"text": Company360DashboardInvoiceLblEnum.STATUS_CLOSED
			},
			{
				"value": Company360DashboardInvoiceValEnum.STATUS_CANCEL,
				"text": Company360DashboardInvoiceLblEnum.STATUS_CANCEL
			}
		];
		return listOfStatusConsider;
	}

	public getlistOfInvoiceDateUses() {
		const listOfDateUses: any[] = [
			{
				"value": Company360DashboardInvoiceValEnum.DATE_DOC_DATE,
				"text": Company360DashboardInvoiceLblEnum.DATE_DOC_DATE
			},
			{
				"value": Company360DashboardInvoiceValEnum.DATE_DUE_DATE,
				"text": Company360DashboardInvoiceLblEnum.DATE_DUE_DATE
			},
			{
				"value": Company360DashboardInvoiceValEnum.DATE_DATE_PAID,
				"text": Company360DashboardInvoiceLblEnum.DATE_DATE_PAID
			}
		]
		return listOfDateUses;
	}

	public getListOfArCreditmemoStatusConsider() {
		const listOfStatusConsider = [
			{
				"value": Company360DashboardArCreditmemoValEnum.STATUS_ALL,
				"text": Company360DashboardArCreditmemoLblEnum.STATUS_ALL
			},
			{
				"value": Company360DashboardArCreditmemoValEnum.STATUS_DRAFT,
				"text": Company360DashboardArCreditmemoLblEnum.STATUS_DRAFT
			},
			{
				"value": Company360DashboardArCreditmemoValEnum.STATUS_OPEN,
				"text": Company360DashboardArCreditmemoLblEnum.STATUS_OPEN
			},
			{
				"value": Company360DashboardArCreditmemoValEnum.STATUS_CLOSED,
				"text": Company360DashboardArCreditmemoLblEnum.STATUS_CLOSED
			},
			{
				"value": Company360DashboardArCreditmemoValEnum.STATUS_CANCEL,
				"text": Company360DashboardArCreditmemoLblEnum.STATUS_CANCEL
			},
			{
				"value": Company360DashboardArCreditmemoValEnum.STATUS_CANCELLATION,
				"text": Company360DashboardArCreditmemoLblEnum.STATUS_CANCELLATION
			},
		];
		return listOfStatusConsider;
	}

	public getlistOfArCreditmemoDateUses() {
		const listOfDateUses: any[] = [
			{
				"value": Company360DashboardArCreditmemoValEnum.DATE_DOC_DATE,
				"text": Company360DashboardArCreditmemoLblEnum.DATE_DOC_DATE
			},
			{
				"value": Company360DashboardArCreditmemoValEnum.DATE_DUE_DATE,
				"text": Company360DashboardArCreditmemoLblEnum.DATE_DUE_DATE
			}
		]
		return listOfDateUses;
	}

	public getlistOfArCreditmemoTypeOptions() {
		const listOfDateUses: any[] = [
			{
				"value": Company360DashboardArCreditmemoValEnum.TYPE_ALL,
				"text": Company360DashboardArCreditmemoLblEnum.TYPE_ALL
			},
			{
				"value": Company360DashboardArCreditmemoValEnum.TYPE_INVENTORY,
				"text": Company360DashboardArCreditmemoLblEnum.TYPE_INVENTORY
			},
			{
				"value": Company360DashboardArCreditmemoValEnum.TYPE_SERVICE,
				"text": Company360DashboardArCreditmemoLblEnum.TYPE_SERVICE
			}
		]
		return listOfDateUses;
	}

	public doClearAllSetterGetter() {
		this.setStoredFilters(undefined);
		this.setSalesListingOpenFrom('');
	}

	public getListOfDocTypesOfArCreditMemo() {
		const listOfDocTypeArCreditMemo = [
			{
				value: 'dDocument_Items',
				text: 'Inventory Type'
			},
			{
				value: 'dDocument_Service',
				text: 'Service Type'
			}
		];
		return listOfDocTypeArCreditMemo;
	}

	setOriginSalesInvoiceId(val: any) {
		this.originSalesInvoiceId = val;
	}
	getOriginSalesInvoiceId() {
		return this.originSalesInvoiceId;
	}

	public getListOfActionsForDatagrid() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'VIEW_DETAILS',
				btn_name: 'View Details',
				btn_image: 'viewDetails',
				icon: IMG_ICON_VIEW_DETAILS,
				visible: true,
				disabled: false,
			},
		];
	}

	public getListOfActionsForArCreditMemo() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'IMPORT',
				btn_name: 'Import',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	public getListOfActionsForArInvoices() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'IMPORT',
				btn_name: 'Import',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'IMPORT_VIRTUAL_INVOICES',
				btn_name: 'Import Virtual Invoices',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'VIEW_DETAILS',
				btn_name: 'View Details',
				btn_image: 'viewDetails',
				icon: IMG_ICON_VIEW_DETAILS,
				visible: true,
				disabled: false,
			},
		];
	}

	public getListOfActionsForDatagridDistributionChargeback() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'IMPORT',
				btn_name: 'Import',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	get getListOfDocumentStatusesForQuote() {
		return [
			{ value: SalesDocumentStatusesValEnum.OPEN, text: SalesDocumentStatusesLblEnum.OPEN },
			{ value: SalesDocumentStatusesValEnum.CLOSE, text: SalesDocumentStatusesLblEnum.CLOSE },
			{ value: SalesDocumentStatusesValEnum.CANCEL, text: SalesDocumentStatusesLblEnum.CANCEL }
		]
	}

	get getListOfDocumentStatusesForOrder() {
		return [
			{ value: SalesDocumentStatusesValEnum.OPEN, text: SalesDocumentStatusesLblEnum.OPEN },
			{ value: SalesDocumentStatusesValEnum.CLOSE, text: SalesDocumentStatusesLblEnum.CLOSE },
			{ value: SalesDocumentStatusesValEnum.DELETED, text: SalesDocumentStatusesLblEnum.DELETED },
			{ value: SalesDocumentStatusesValEnum.CANCEL, text: SalesDocumentStatusesLblEnum.CANCEL },
		]
	}

	get getListOfDocumentStatusesForArInvoice() {
		return [
			{ value: SalesDocumentStatusesValEnum.OPEN, text: SalesDocumentStatusesLblEnum.OPEN },
			{ value: SalesDocumentStatusesValEnum.CLOSE, text: SalesDocumentStatusesLblEnum.CLOSE },
			{ value: SalesDocumentStatusesValEnum.DELETED, text: SalesDocumentStatusesLblEnum.DELETED },
			{ value: SalesDocumentStatusesValEnum.CANCEL, text: SalesDocumentStatusesLblEnum.CANCEL },
		]
	}

	get getListOfDocumentStatusesForArCreditMemo() {
		return [
			{ value: SalesDocumentStatusesValEnum.OPEN, text: SalesDocumentStatusesLblEnum.OPEN },
			{ value: SalesDocumentStatusesValEnum.CLOSE, text: SalesDocumentStatusesLblEnum.CLOSE },
			{ value: SalesDocumentStatusesValEnum.DELETED, text: SalesDocumentStatusesLblEnum.DELETED },
			{ value: SalesDocumentStatusesValEnum.CANCEL, text: SalesDocumentStatusesLblEnum.CANCEL },
			{ value: SalesDocumentStatusesValEnum.DRAFT, text: SalesDocumentStatusesLblEnum.DRAFT },
			{ value: SalesDocumentStatusesValEnum.CANCELLATION, text: SalesDocumentStatusesLblEnum.CANCELLATION },
		]
	}

	get getListOfActionsForArInvoiceViewDetailReport() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	set setActionForOrder(val: string) {
		this.actionForOrder = val;
	}
	get getActionForOrder(): string {
		return this.actionForOrder;
	}

	get getListOfReportTypesForArInvoiceViewDetails() {
		return [
			{
				value: 'detailed_view',
				text: 'Detailed View',
				visible: true,
				disabled: false,
			},
			{
				value: 'summary_view',
				text: 'Summary View',
				visible: true,
				disabled: false,
			}
		];
	}

	get getListOfReportViewsForArInvoiceViewDetails() {
		return [
			{
				value: 'datagrid_view',
				text: 'Data Grid',
				visible: true,
				disabled: false,
			},
			{
				value: 'pivotgrid_view',
				text: 'Pivot Grid',
				visible: true,
				disabled: false,
			}
		];
	}

	get getListOfInPricelistForArInvoiceViewDetails() {
		return [
			{
				value: false,
				text: 'No',
				visible: true,
				disabled: false,
			},
			{
				value: true,
				text: 'Yes',
				visible: true,
				disabled: false,
			}
		];
	}
}
