<!-- <div class="popCnt" style="width: 1350px;">
	<div class="popBox cf">
		<div class="popHeader">
			<span (click)="dismiss()" class="closePopup icon-close-popup"></span>
			<h2 class="popTitle">Billed Items List</h2>
		</div>
		<div class="popContent"> -->
<dx-popup [width]="'1350px'" [showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="false"
	[title]="'Billed Items List'" [showCloseButton]="true" [(visible)]="isShowPopup"
	[wrapperAttr]="{ class: 'popupToolbarPadding' }" (onHidden)="doHiddenPopop($event)">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<dx-data-grid #itemTabDataGridRef id="gridContainer" [dataSource]="itemTabDataSource"
				[remoteOperations]="false" [showBorders]="true" [columnAutoWidth]="true" [allowColumnResizing]="true"
				[masterDetail]="{ enabled: true, template: 'customersTemplate','autoExpandAll': false }" [height]="350"
				keyExpr="guid" class="hideFilterPanelInx">
				<dxo-sorting mode="single"></dxo-sorting>
				<dxo-filter-row [visible]="true"></dxo-filter-row>
				<dxo-header-filter [visible]="true"></dxo-header-filter>
				<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
				<dxo-editing mode="row" [allowUpdating]="false" [allowDeleting]="false" [allowAdding]="false"
					[useIcons]="true">
				</dxo-editing>

				<!-- ​<div *dxTemplate="let data of 'templateFilterText'">
					<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
						<span>{{filterText}}</span>
					</div>
				</div> -->

				<!-- <dxi-column caption="Customer" dataField="customer_name" [allowEditing]="false"
				cellTemplate="cellTemplateCustomer" [allowHeaderFiltering]="false" [allowFiltering]="true"
				[allowSorting]="true">
				<div *dxTemplate="let element of 'cellTemplateCustomer'">
					{{element.data.customer_name || ''}}
				</div>
			</dxi-column> -->
				<dxi-column caption="SKU" [visible]="isQBOnlineErpUser" dataField="itemsku" [allowEditing]="false"
					cellTemplate="cellTemplateItemSku" [allowHeaderFiltering]="false" [allowFiltering]="true"
					[allowSorting]="true">
					<div *dxTemplate="let element of 'cellTemplateItemSku'">
						{{element.data.itemsku || ''}}
					</div>
				</dxi-column>
				<dxi-column caption="Item" dataField="itemname" [allowEditing]="false" cellTemplate="cellTemplateItemName"
					[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
					<div *dxTemplate="let element of 'cellTemplateItemName'">
						{{element.data.itemname || ''}}
					</div>
				</dxi-column>
				<!-- <dxi-column caption="Description" dataField="description" [allowEditing]="false"
					cellTemplate="cellTemplateItemDesc" [allowHeaderFiltering]="false" [allowFiltering]="true"
					[allowSorting]="true">
					<div *dxTemplate="let element of 'cellTemplateItemDesc'">
						{{element.data.description || ''}}
					</div>
				</dxi-column> -->

				<dxi-column caption="UOM" dataField="selectedUom" [allowHeaderFiltering]="false" [allowFiltering]="false"
					[allowEditing]="true" cellTemplate="cellTemplateUomCode">
					<dxo-lookup [dataSource]="listOfUoms" valueExpr="code" [displayExpr]="getDisplayExprUomName">
					</dxo-lookup>
					<div *dxTemplate="let element of 'cellTemplateUomCode'">
						<span>
							{{element.data.selectedUom || '' | filterFromList:listOfUoms : 'code' : 'name' | uppercase}}
						</span>
					</div>
				</dxi-column>
				<!-- 
				<dxi-column caption="Billback Amount" dataField="billbackAmount" [allowEditing]="false"
					[allowHeaderFiltering]="false" [allowFiltering]="false"
					[allowSorting]="true">
					<div *dxTemplate="let element of 'cellTemplateBillbackAmount'">
						{{element.data.billbackAmount | currency:customCurrencyPipe || '0'}}
					</div>
				</dxi-column> -->

				<dxi-column caption="Bought" dataField="bought" [allowEditing]="false" cellTemplate="cellTemplateBought"
					[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true">
					<div *dxTemplate="let element of 'cellTemplateBought'">
						{{(element.data.bought % 1) === 0 ? element.data.bought :element.data.bought | number : '1.2-4'}}
					</div>
				</dxi-column>
				<dxi-column caption="Sold" dataField="sold" [allowEditing]="false" cellTemplate="cellTemplateSold"
					[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true">
					<div *dxTemplate="let element of 'cellTemplateSold'">
						{{(element.data.sold % 1) === 0 ? element.data.sold :element.data.sold | number : '1.2-4'}}
					</div>
				</dxi-column>
				<dxi-column caption="Billed" dataField="billed" [allowEditing]="false" cellTemplate="cellTemplateBilled"
					[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true">
					<div *dxTemplate="let element of 'cellTemplateBilled'">
						{{(element.data.billed % 1) === 0 ? element.data.billed :element.data.billed | number : '1.2-4'}}
					</div>
				</dxi-column>
				<dxi-column caption="Available" dataField="available" [allowEditing]="false"
					cellTemplate="cellTemplateAvailable" [allowHeaderFiltering]="false" [allowFiltering]="false"
					[allowSorting]="true">
					<div *dxTemplate="let element of 'cellTemplateAvailable'">
						{{(element.data.available % 1) === 0 ? element.data.available :element.data.available | number :
						'1.2-4'}}
					</div>
				</dxi-column>
				<!-- <dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
					[allowSorting]="false" alignment="center" [allowResizing]="true" [minWidth]="150" [width]="150"
					[allowExporting]="false">
					<dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button>
					<dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button>
				</dxi-column> -->

				<div *dxTemplate="let c of 'customersTemplate'">
					<dx-data-grid #customersDetailssDataGridRef id="gridContainer"
						[dataSource]="getCustomersByItemDetails(c)" (onInitNewRow)="doOnInitNewRowCustomersTab($event)"
						(onRowInserted)="doOnRowInsertedCustomersTab($event)"
						(onEditorPreparing)="doOnEditorPreparingCustomersTab($event)"
						(onRowValidating)="onRowValidating($event,c)" (onRowUpdated)="doOnRowUpdatedCustomersTab($event,c)"
						(onRowRemoved)="doOnRowRemovedCustomersTab($event)" (onSaved)="doOnSavedCustomersTab($event,c)"
						(onEditCanceled)="onEditCanceledCustomersTab($event)" keyExpr="guid" [showBorders]="true"
						[columnAutoWidth]="true">
						<dxo-filter-row [visible]="false"></dxo-filter-row>
						<dxo-filter-panel [visible]="false"></dxo-filter-panel>
						<dxo-header-filter [visible]="false"></dxo-header-filter>
						<dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="false" [allowAdding]="false"
							[useIcons]="true">
						</dxo-editing>
						<dxi-column caption="Customer" dataField="customer_name" [allowEditing]="false"
							cellTemplate="cellTemplateCustomer" [allowHeaderFiltering]="false" [allowFiltering]="true"
							[allowSorting]="true">
							<div *dxTemplate="let element of 'cellTemplateCustomer'">
								{{element.data.customer_name || ''}}
							</div>
						</dxi-column>

						<dxi-column caption="Rate" dataField="billbackAmount" [allowEditing]="false"
							[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true" [minWidth]="100"
							[width]="100">
							<dxo-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-format>
							<div *dxTemplate="let element of 'cellTemplateBillbackAmount'">
								{{element.data.billbackAmount || '0' | customCurrencyPipe}}
							</div>
						</dxi-column>

						<!-- 		<dxi-column caption="Bought" dataField="bought" [allowEditing]="false"
							cellTemplate="cellTemplateBought" [allowHeaderFiltering]="false" [allowFiltering]="false"
							[allowSorting]="true" [minWidth]="100" [width]="100">
							<div *dxTemplate="let element of 'cellTemplateBought'">
								{{element.data.bought || 0}}
							</div>
						</dxi-column>
					
						<dxi-column caption="Sold" dataField="sold" [allowEditing]="false" cellTemplate="cellTemplateSold"
							[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true">
							<div *dxTemplate="let element of 'cellTemplateSold'">
								{{element.data.sold || 0}}
							</div>
						</dxi-column>

						<dxi-column caption="Billed" dataField="billed" [allowEditing]="false"
							cellTemplate="cellTemplateBilled" [allowHeaderFiltering]="false" [allowFiltering]="false"
							[allowSorting]="true">
							<div *dxTemplate="let element of 'cellTemplateBilled'">
								{{element.data.billed || 0}}
							</div>
						</dxi-column> -->

						<dxi-column caption="Available" dataField="available" [allowEditing]="false"
							cellTemplate="cellTemplateAvailable" [allowHeaderFiltering]="false" [allowFiltering]="false"
							[allowSorting]="true" [minWidth]="100" [width]="100">
							<div *dxTemplate="let element of 'cellTemplateAvailable'">
								{{(element.data.available % 1) === 0 ? element.data.available :element.data.available | number :
								'1.2-4'}}
							</div>
						</dxi-column>

						<dxi-column caption="To Bill" dataField="claimed_qty" [allowEditing]="true"
							[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true" [minWidth]="100"
							[width]="100" dataType="number" [calculateCellValue]='calculateToBillCellValue'
							[editorOptions]="{ format: 'decimal', showClearButton: true }">
							<!-- <div *dxTemplate="let element of 'cellTemplateClimedQty'">
								{{element.data.claimed_qty || 0}}
							</div> -->
						</dxi-column>
						<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
							[allowSorting]="false" alignment="center" [allowResizing]="true" [minWidth]="100" [width]="100"
							[allowExporting]="false">
							<dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button>
							<dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button>
						</dxi-column>
					</dx-data-grid>
				</div>
			</dx-data-grid>
		</dx-scroll-view>
	</div>
	<dxi-toolbar-item toolbar="bottom" location="after" cssClass="mrgT10">
		<div *dxTemplate>
			<span class="mrgL10 red">{{validationMsg || ''}}</span>
			<dx-button (onClick)="claimedItemSubmit()" [text]="'Submit'" class="button primaryBtn" icon="save"></dx-button>
		</div>
	</dxi-toolbar-item>
</dx-popup>