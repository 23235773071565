<div class="rightBar">
	<div class="rightBlock">
		<div class="contentRow cf">
			<div class="borderBox cf">
				<div class="row">
					<div class="cols9">
						<h4 class="graySubHead textWrap" *ngIf="approvalDetail?.ixcode">Approval Code:
							<span class="black">{{approvalDetail?.ixcode}}</span>
						</h4>
						<h4 class="graySubHead textWrap" *ngIf="approvalDetail?.approval_status">Claim Status :
							<span class="stData stBtn"
								[ngClass]="approvalDetail.approval_status || '' | filterFromList:listOfStatuses : 'value' : 'classnameBg'">
								{{approvalDetail.approval_status || '' | filterFromList:listOfStatuses:'value':'text'}}
							</span>
						</h4>
					</div>
					<div class="cols3 mrgB10">
						<div class="externalFieldBgix">
							<p class="externalFieldTitleix">Approval Amount :
								<span class="externalFieldLabelix"> {{approvalDetail.approvalAmount || 0
									|customCurrencyPipe}}</span>
							</p>
						</div>
					</div>
				</div>
				<hr>
				<div class="row">
					<div class="cols12">
						<div class="tabbing cf">
							<ul>
								<li (click)="doClickOnTab('approvals_chargebacks')"
									[ngClass]="{active: currentTab === 'approvals_chargebacks'}">
									<a>Min-Max Approval Claims</a>
								</li>
								<li (click)="doClickOnTab('approvals_trail')"
									[ngClass]="{active: currentTab === 'approvals_trail'}">
									<a>Approval Trail</a>
								</li>
							</ul>
						</div>
						<div class="tableOut" *ngIf="currentTab === 'approvals_chargebacks'">
							<dx-data-grid #dataGridContainer id="gridContainer"
								[dataSource]="minMaxChargebackApprovalDataSource" [showBorders]="true" [showColumnLines]="true"
								[showRowLines]="false" [allowColumnResizing]="true" [filterSyncEnabled]="true"
								[remoteOperations]="true" [columnAutoWidth]="true" [width]="'100%'"
								(onRowClick)="doOnRowClick($event.data);" [allowColumnReordering]="true"
								(onCellClick)="doCellClickDetails($event)" (onToolbarPreparing)="doToolbarPreparing($event)"
								class="cellColor hoverClr hideFilterPanelInx">
								<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
								<dxo-pager [visible]="true" [showPageSizeSelector]="true"
									[allowedPageSizes]="pageOptions.pageSizeOptions" [showInfo]="true">
								</dxo-pager>
								<dxo-filter-row [visible]="true"></dxo-filter-row>
								<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
								<dxo-state-storing [enabled]="true" type="custom"
									[customLoad]="doLoadMinMaxChargebackApprovalState"
									[customSave]="doSaveMinMaxChargebackApprovalState" [storageKey]="stateStorageKey">
								</dxo-state-storing>
								<dxo-header-filter [visible]="true"></dxo-header-filter>
								<dxo-sorting mode="single"></dxo-sorting>
								<div *dxTemplate="let data of 'templateFilterText'">
									<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
										<span>{{filterText}}</span>
									</div>
								</div>

								<div *dxTemplate="let data of 'templateInfoText'">
									<span class="icon-toolTip"></span><span> Click to view individual claim details</span>
								</div>

								<div *dxTemplate="let data of 'templateActionBtns'">
									<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false"
										[showArrowIcon]="false" text="Action"
										class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
										[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code"
										icon="spindown" (onButtonClick)="doActionsBtnClick($event)"
										(onItemClick)="doActionsBtnItemClick($event)"
										[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
								</div>
								<dxi-column dataField="ixcode" [allowHeaderFiltering]="false" caption="Claim No."
									cellTemplate="cellTemplateClaimId" alignment="left" dataType="string" sortOrder="desc">
									<div *dxTemplate="let element of 'cellTemplateClaimId'">
										<span class="highlight pointerMark"> {{element.data.ixcode || ''}}</span>
									</div>
								</dxi-column>
								<dxi-column dataField="contractName" caption="Contract Name" dataType="string"
									[allowHeaderFiltering]="true" cellTemplate="cellTemplateContractName"
									[allowFiltering]="false">
									<div *dxTemplate="let element of 'cellTemplateContractName'">
										{{element.data.contractName}}
									</div>
								</dxi-column>
								<dxi-column dataField="reason_code_label" dataType="string" caption="Reason Code"
									[allowHeaderFiltering]="false" cellTemplate="cellTemplateReasonCodeLabel">
									<div *dxTemplate="let element of 'cellTemplateReasonCodeLabel'">
										{{element.data.reason_code_label ? element.data.reason_code_label + ' ('+
										element.data.reason_code_erpcode+ ')'
										: ''}}
									</div>
								</dxi-column>
								<dxi-column caption="End-User Name" [allowHiding]="false" dataField="endusersList"
									dataType="string" [allowEditing]="false" [allowFiltering]="true"
									[visible]="approvalDetail.chargebackBasis == 'endusers'" [allowHeaderFiltering]="false"
									cellTemplate="cellTemplateEndUserName">
									<div *dxTemplate="let element of 'cellTemplateEndUserName'">
										<span>
											{{element.data.endusersList && element.data.endusersList.length > 0 ?
											element.data.endusersList[0].end_user_name : ''}}
											<span [attr.id]="'endUserToolTip_' + element.data.guid"
												*ngIf="element.data.endusersList[0] && (element.data.endusersList.length === 2 || element.data.endusersList.length >= 3)">
												+ {{element.data.endusersList.length-1}}
												{{ element.data.endusersList.length === 2 ? 'Other' :
												element.data.endusersList.length >= 3 ?
												'Others' : ''}}
											</span>
											<dx-tooltip target="#endUserToolTip_{{element.data.guid}}" showEvent="dxhoverstart"
												hideEvent="dxhoverend" position="right">
												<div *dxTemplate="let d = d; of: 'content'">
													<div class="tooltip-wrapper">
														<div class="greenTipout">
															<ol class="tipList">
																<li *ngFor="let enduser of element.data.endusersList; let p = index;">
																	<a>{{enduser.end_user_name || ''}}</a>
																</li>
															</ol>
														</div>
													</div>
												</div>
											</dx-tooltip>
										</span>
									</div>
								</dxi-column>
								<dxi-column dataField="selectCustomerId" caption="Customer Code" dataType="string"
									[allowHeaderFiltering]="true" cellTemplate="cellTemplateCustomerCode"
									[allowFiltering]="false">
									<div *dxTemplate="let element of 'cellTemplateCustomerCode'">
										{{element.data.selectCustomerId}}
									</div>
								</dxi-column>
								<dxi-column dataField="selectCustomer" caption="Customer Name" dataType="string"
									[allowHeaderFiltering]="true" cellTemplate="cellTemplateCustomerName"
									[allowFiltering]="false">
									<div *dxTemplate="let element of 'cellTemplateCustomerName'">
										{{element.data.selectCustomer}}
									</div>
								</dxi-column>
								<dxi-column caption="Chargeback Period" dataField="chargeback_period" [allowEditing]="false"
									cellTemplate="cellTemplateChargeback" [allowHeaderFiltering]="false" [allowFiltering]="true"
									[allowSorting]="true" dataType="string">
									<div *dxTemplate="let element of 'cellTemplateChargeback'">
										{{ (element.data.chargeback_period !== null && element.data.chargeback_period !== '' &&
										element.data.chargeback_period !==
										undefined && element.data.chargeback_period !== 'Invalid date') ?
										(element.data.chargeback_period |
										toDateObj | date:'MMMM,yyyy') : "" }}
									</div>
								</dxi-column>
								<dxi-column caption="Document Date" dataField="documentDate" [allowEditing]="false"
									cellTemplate="cellTemplatedocumentDocumentDate" [allowHeaderFiltering]="false"
									[allowFiltering]="true" [allowSorting]="true" dataType="string">
									<div *dxTemplate="let element of 'cellTemplatedocumentDocumentDate'">
										{{ (element.data.documentDate !== null && element.data.documentDate !== '' &&
										element.data.documentDate !==
										undefined && element.data.documentDate !== 'Invalid date') ? (element.data.documentDate |
										toDateObj
										| date:
										'yyyy-MM-dd') : "" }}
									</div>
								</dxi-column>
								<dxi-column caption="Total Claimed Amount" dataField="documentTotal" [allowEditing]="false"
									[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true"
									dataType="number" [showInColumnChooser]="!isOverrideClaimView"
									[visible]="!isOverrideClaimView">
									<dxo-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-format>
									<div *dxTemplate="let element of 'cellTemplateTotalClaimedAmount'">
										{{element.data.documentTotal|| 0 | customCurrencyPipe}}
									</div>
								</dxi-column>
								<dxi-column caption="Total Override Item Amount" dataField="total_override_item_amount"
									[allowEditing]="false" [allowHeaderFiltering]="false" [allowFiltering]="true"
									[showInColumnChooser]="isOverrideClaimView" [allowSorting]="true" dataType="number"
									[visible]="isOverrideClaimView">
									<dxo-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-format>
									<div *dxTemplate="let element of 'cellTemplateTotalOverrideItemAmount'">
										{{element.data.total_override_item_amount|| 0 | customCurrencyPipe}}
									</div>
								</dxi-column>
								<dxi-column dataField="created_by_name" caption="Created By" dataType="string"
									[allowHeaderFiltering]="true" cellTemplate="cellTemplateCreatedBy" [allowFiltering]="false">
									<div *dxTemplate="let element of 'cellTemplateCreatedBy'">
										{{element.data.created_by_name}}
									</div>
								</dxi-column>
								<dxi-column caption="Remark/Memo" dataField="remark" cellTemplate="cellTemplateRemark"
									dataType="string" [allowHeaderFiltering]="false" [allowFiltering]="false"
									[allowEditing]="false">
									<div *dxTemplate="let element of 'cellTemplateRemark'">
										{{element.data.remark || ''}}
									</div>
								</dxi-column>
								<dxo-summary>
									<dxi-total-item column="documentTotal" alignment="right" summaryType="sum"
										displayFormat="{0}">
										<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
										</dxo-value-format>
									</dxi-total-item>
									<dxi-total-item column="total_override_item_amount" alignment="right" summaryType="sum"
										displayFormat="{0}">
										<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
										</dxo-value-format>
									</dxi-total-item>
								</dxo-summary>
							</dx-data-grid>
							<div class="row" *ngIf="isShowDropDownButton">
								<div class="cols9">
									<div class="dx-field fieldCol mrgT20 mrgB20 remarkBx">
										<div class="dx-field-label">Reason</div>
										<div class="dx-field-value">
											<dx-text-area [(value)]="submitReason" [height]="60" [maxLength]="500"
												name="submitReason">
											</dx-text-area>
										</div>
									</div>
								</div>
								<div class="cols3 alignRight">
									<div class="right alignRight mrgT20">
										<dx-drop-down-button [dropDownOptions]="{ width: 250 }" [splitButton]="true"
											[showArrowIcon]="true" [useSelectMode]="true"
											[selectedItemKey]="selectedKeyDropDownBtn" [text]="getSubmitBtnText()"
											[items]="listOfForMinMaxChargebackBtns" [wrapItemText]="true" displayExpr="label"
											keyExpr="value" (onButtonClick)="doSaveUpdateClaimList($event)"
											(onItemClick)="doSaveUpdateClaimStatusChanged($event)"
											class="button dxSubmitBtn submitDDBtn" [width]="250">
										</dx-drop-down-button>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="currentTab === 'approvals_trail'">
							<app-mmeucc-claim-approval-trail [callFrom]="'approvals'"
								[approvalTrailDetails]="approvalTrailDetails"></app-mmeucc-claim-approval-trail>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <app-mmeucc-view-chargeback-documents-dx-popup (hideViewClaimPopUpDataEvent)="doHideViewClaimDxDialog($event)"
	[loadViewChargebackDocDxDialogSubject]="loadViewChargebackDocDxDialogSubject"></app-mmeucc-view-chargeback-documents-dx-popup> -->
<app-view-claim-preview-popup *ngIf="isShowMinMaxChargebackClaimPreviewPopup"
	[claimPreviewProps]="minMaxCharebackClaimPreviewProps" (closeClaimPopup)="doCloseMinMaxChargebackClaimPopup($event)">
</app-view-claim-preview-popup>