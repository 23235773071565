export class EndUsersChargebackContractModelUI {
	public ixcode: string;
	public guid: string;
	public percentOrDollar: string;
	public contractName: string;
	public selectCustomer: string;
	public selectCustomerId: string;
	public selectCustomerPriceListNum: string;
	public selectCustomerPriceListName: string;
	public startDate: any;
	public endDate: any;
	public lastUpdatedDate: string;
	public billBackType: string;
	public is_active: boolean;
	public pricelist_modified_date?: string;
	public pricelist_modified_user_action?: string;
	public look_back_period?: string;
	public is_enforce_quantity_sold?: boolean;
	// public distribution_channels?: any;
	public chargeBackTabData: EndUsersChargebackTabData = new EndUsersChargebackTabData();
}

export class EndUsersChargebackContractModelRequest {
	public ixcode: string;
	public guid: string;
	public contractName: string;
	public selectCustomer: string;
	public selectCustomerId: string;
	public selectCustomerPriceListNum: string;
	public selectCustomerPriceListName: string;
	public startDate: any;
	public endDate: any;
	public lastUpdatedDate: string;
	public billBackType: string;
	public is_active: boolean;
	public pricelist_modified_date?: any;
	public pricelist_modified_user_action?: string;
	public is_enforce_quantity_sold?: boolean;
	public distribution_channels?: any;
	public endusers: EndUsersChargebackItemTabModel[] = [];
	public chargeDocuments: ChargeDocumentsTabModel[] = [];
}

export class EndUsersChargebackTabData {
	public itemTabData: any[] = [];
	public chargeBackDocumentsTabData: any[] = [];
}

export class CustomerTabModel {
	public selectCustomer: string;
	public selectCustomerId: string;
}

export class EndUsersChargebackPriceListTabModel {
	public guid: string;
	public pricelistname: string;
	public pricelistno: string;
	public end_user_name: string;
	public end_user_code: string;
	public end_user_id: string;
	public items: EndUsersPriceListItemModel[] = [];
}
export class EndUsersChargebackItemListTabModel {
	public guid: string;
	public end_user_name: string;
	public end_user_code: string;
	public end_user_id: string;
	public items: EndUsersPriceListItemModel[] = [];
}

export class EndUsersPriceListItemModel {
	public guid: string;
	public erp_primary_key: string;
	public itemsku: string;
	public itemname: string;
	public item_guid: string;
	public description?: string;
	public uomList: any;
	public uomgroupentry: any;
	public selectedUom: any;
	public listPrice: any;
	public chargebackAmount: any;
	public discountedPrice: any;
	public final_amount: any;
	public is_disbled_input_field: boolean;
	public bought: any;
	public sold: any;
	public claimed: any;
	public available: any;
	public last_invoice_date: string;
}

export class EndUsersItemListItemModel {
	public guid: string;
	public erp_primary_key: string;
	public itemsku: string;
	public itemname: string;
	public item_guid: string;
	public description?: string;
	public uomList: any;
	public uomgroupentry: any;
	public selectedUom: any;
	public listPrice: any;
	public chargebackAmount: any;
	public discountedPrice: any;
	public final_amount: any;
	public is_disbled_input_field: boolean;
	public bought: any;
	public sold: any;
	public claimed: any;
	public available: any;
	public last_invoice_date: string;
}

export class EndUsersChargebackItemTabModel {
	public guid: string;
	public erp_primary_key: string;
	public itemsku: string;
	public itemname: string;
	public item_guid: string;
	public description?: string;
	public pricelistname: string;
	public pricelistno: string;
	public end_user_name: string;
	public end_user_code: string;
	public end_user_id: string;
	public uomList: any;
	public uomgroupentry: any;
	public selectedUom: any;
	public listPrice: any;
	public chargebackAmount: any;
	public discountedPrice: any;
	public final_amount: any;
	public is_disbled_input_field: boolean;
	public bought: any;
	public sold: any;
	public claimed: any;
	public available: any;
	public last_invoice_date: string;
}

export class ChargeDocumentsTabModel {
	public guid: string;
	public contractGuid: string;
	public documentURL: string;
	public documentOriginalName: string;
	public documentReferenceNumber: string;
	public documentDocumentDescription?: string;
	public documentDate: string;
	public claimedStatus: string;
	public claimedStatusReason: string;
	public claimedUserNote: string;
	public claimedItems: ClaimedItemsModel[] = [];
	constructor() {
		this.documentURL = '';
		this.documentReferenceNumber = '';
		this.documentDocumentDescription = '';
		this.claimedUserNote = '';
	}
}

export class ClaimedItemsModel {
	public itemCode: string;
	public itemName: string;
	public endUserCode: string;
	public endUserName: string;
	public claimed: number;
}

export class ClaimAttachedDocumentModel {
	public currentClaimElement: any;
	public categoriesList: any = [];
	constructor() {

	}
}

export class EndUserClaimItemsModel {
	public csvData: any;
	public ignored_items_list: any = [];
	public succeed_items_list: any = [];
	public contract_name: string;
	public calledFrom: string;
	public documentReferenceNumber: string;
	public claimedUserNote: string;
	public documentURL: string;
	public claimDate: string;
	public shortpayid: string;
	public claimDocumentUploadFile: any = [];
	public documentUploadFile: any = [];
	public contractguid: string;
	public chargeDocumentsTabModel?: any;
}