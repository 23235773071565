import { Injectable } from '@angular/core';
import { IMG_ADD_FILE_SVG, IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, IMG_ICON_XLSXFILEPOPUPIX } from '@app/constants-enums/constants';

@Injectable({
	providedIn: 'root'
})
export class PurchasesService {
	private selectedTabPurchases: any;
	private purchasesGuid: string;
	private vendorID: string;
	private purchasesDetails: any;
	private isEditMode = false;
	private storedFilters: any;
	public purchasInvoiceType = '';
	public objDocumentType: any;
	public account_guid: any;
	public class_guid: any;
	private apCreditMemoDetailsOpenFrom = '';
	private apCreditMemoGuid = '';
	private apCreditMemoListFor = '';

	constructor() {

	}
	public setTabPurchases(val: any) {
		this.selectedTabPurchases = val;
	}
	public getTabPurchases() {
		return this.selectedTabPurchases;
	}
	public setPurchasInvoiceType(val: any) {
		this.purchasInvoiceType = val;
	}
	public getPurchasInvoiceType() {
		return this.purchasInvoiceType;
	}
	public setObjDocumentType(val: any) {
		this.objDocumentType = val;
	}
	public getObjDocumentType() {
		return this.objDocumentType;
	}

	public setIsEditMode(val: any) {
		this.isEditMode = val;
	}
	public getIsEditMode() {
		return this.isEditMode;
	}

	public setPurchasesGuid(val: any) {
		this.purchasesGuid = val;
	}

	public getPurchasesGuid() {
		return this.purchasesGuid;
	}

	public setPurchasesVendorID(val: any) {
		this.vendorID = val;
	}

	public getPurchasesVendorID() {
		return this.vendorID;
	}
	public setPurchasesDetails(val: any) {
		this.purchasesDetails = val;
	}

	public getPurchasesDetails() {
		return this.purchasesDetails;
	}

	public setStoredFilters(val: any) {
		this.storedFilters = val;
	}

	public getStoredFilters() {
		return this.storedFilters || undefined;
	}
	public setAccountGuid(val: any) {
		this.account_guid = val;
	}
	public getAccountGuid() {
		return this.account_guid;
	}
	public setClassGuid(val: any) {
		this.class_guid = val;
	}
	public getClassGuid() {
		return this.class_guid;
	}

	public setApCreditMemoGuid(val: any) {
		this.apCreditMemoGuid = val;
	}

	public getApCreditMemoGuid() {
		return this.apCreditMemoGuid || '';
	}

	public setApCreditMemoDetailsOpenFrom(val: any) {
		this.apCreditMemoDetailsOpenFrom = val;
	}

	public getApCreditMemoDetailsOpenFrom() {
		return this.apCreditMemoDetailsOpenFrom || '';
	}

	public setApCreditMemoListFor(val: any) {
		this.apCreditMemoListFor = val;
	}

	public getApCreditMemoListFor() {
		return this.apCreditMemoListFor || '';
	}

	public getListOfActionsForAccountingLitst() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			}
		];
	}

	public getListOfActionsForAPInvoiceLitst() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	public getListOfActionsForAPCreditMemoLitst() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}
}
