import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GlobalPopoupHeight60Ratio, GlobalPopoupHeight80Ratio, GlobalPopoupHeight90Ratio, GlobalPopoupWidth60Ratio, GlobalPopoupWidth80Ratio, GlobalPopoupWidth90Ratio, MSG_ERROR_MESSAGE, ServerMethods } from '@app/constants-enums/constants';
import { EndUserClaimItemsModel } from '@app/models/end-users-chargeback-contract.model';
import { EndUsersChargebackContractService } from '@app/services/contract-service/billback/end-users-chargeback-contract.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { DxDataGridComponent } from 'devextreme-angular';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;
import saveAs from 'file-saver';

@Component({
	selector: 'app-unmatched-claim-item-dx-popup',
	templateUrl: './unmatched-claim-item-dx-popup.component.html',
	styleUrls: ['./unmatched-claim-item-dx-popup.component.css']
})
export class UnmatchedClaimItemDxPopupComponent implements OnInit {
	@ViewChild("mismatchClaimItemDataGridContainer", {}) mismatchClaimItemDataGridContainer: DxDataGridComponent;
	public dxDataSource: any;
	public isVisiblePopup: boolean = false;
	public titleText: string = 'Confirm Claim'
	public popupWidth: number;
	public popupHeight: number;
	public currentTab: string;
	public isShowDatagrid: boolean = false;
	@Input('loadUnMatchedClaimItemDxDialogSubject') loadUnMatchedClaimItemDxDialogSubject: Subject<any>;
	@Output() hideUnMatachedPopUpDataEvent = new EventEmitter<string>();
	public isViewSubmitClaimBtn: boolean = false;
	public contractName: string = '';
	public dataOutReqSubscription: Subscription;
	public enable_chargeback_look_back_period: boolean = false;
	public endUserClaimItemsModel = new EndUserClaimItemsModel();
	public decimalNumberFormat: string = '1.0-0';
	public globalCurrency: string;
	@Input() modifyClaimDetails?: any;
	public decimalPointForCurrency: number;
	constructor(
		private _ToastrService: ToastrService,
		private _LoaderService: LoaderService,
		private _RestApiService: RestApiService,
		private _LoginService: LoginService,
		private _CurrencyPipe: CurrencyPipe,
		private _EndUsersChargebackContractService: EndUsersChargebackContractService
	) { }
	@HostListener('window:resize', ['$event'])
	public onResize(event) {
		this.setDxPopupWidth();
	}

	ngOnInit(): void {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		if (this._LoginService.loginUser && this._LoginService.loginUser.account_detail && this._LoginService.loginUser.account_detail.app_settings) {
			this.enable_chargeback_look_back_period = this._LoginService.loginUser.account_detail.enable_chargeback_look_back_period || false;
		}
		this.loadUnMatchedClaimItemDxDialogSubject.subscribe(dialogData => {
			this.globalCurrency = this._LoginService.globalCurrency;
			this.endUserClaimItemsModel = dialogData;
			this.doChangeTab('ignored_items');
			if (dialogData['calledFrom'] && dialogData['calledFrom'] === 'item_claimed') {
				this.isViewSubmitClaimBtn = true;
				this.contractName = dialogData['contract_name'] || '';
			} else {
				this.isViewSubmitClaimBtn = false;
			}
			this.isVisiblePopup = true;
		});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.setDxPopupWidth();
		}, 500);
	}

	ngOnDestroy() {
		if (this.loadUnMatchedClaimItemDxDialogSubject) {
			this.loadUnMatchedClaimItemDxDialogSubject.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
	}

	public doToolbarPreparing(e) {
		const toolbarItems = e.toolbarOptions.items;
		toolbarItems.unshift(
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					text: "Export",
					onClick: (event: any) => {
						this.doExportToExcel();
					}
				}
			}
		)
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel() {
		const context = this;
		if (context.currentTab === 'succeed_items') {
			if (context.mismatchClaimItemDataGridContainer.instance.totalCount() <= 0) {
				this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
				return;
			}
			const workbook = new ExcelJS.Workbook();
			let claimedItemWorksheet;
			let fileName = context.contractName + '_' + '_EndUserChargeback_successful.xlsx';
			claimedItemWorksheet = workbook.addWorksheet('Claimed Item(s)');
			let ignoredItemsWorkBook: any;
			if (this.currentTab === 'ignored_items') {
				ignoredItemsWorkBook = workbook.addWorksheet('Ignored Item(s)');
			}

			exportDataGrid({
				worksheet: claimedItemWorksheet,
				component: context.mismatchClaimItemDataGridContainer.instance,
				autoFilterEnabled: true,
				keepColumnWidths: true,
				topLeftCell: { row: 5, column: 1 },
				customizeCell: (options) => {
					const { gridCell, excelCell } = options;
					if (gridCell.rowType === "data") {
						if (gridCell.column.dataField === 'selectedClaimedUom' || gridCell.column.dataField === 'contract_uom') {
							excelCell.value = '';
							if (gridCell.value) {
								excelCell.value = gridCell.value.toString().toUpperCase();
							}
						}
						if (gridCell.column.dataField === 'is_active') {
							excelCell.value = '';
							let colorName = '';
							excelCell.value = gridCell.value ? 'Active' : 'Inactive';
							colorName = gridCell.value ? '05c1a0' : 'f99300';
							// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
							excelCell.font = { color: { argb: colorName } };
						}
					}
				}
			}).then((cellRange) => {
				// header
				this.doSetHeaderBlockOnExportExcel(claimedItemWorksheet, context);
			}).then(() => {
				workbook.xlsx.writeBuffer().then((buffer) => {
					saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
				});
			});
		}

		if (context.currentTab === 'ignored_items') {
			if (context.mismatchClaimItemDataGridContainer.instance.totalCount() <= 0) {
				this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
				return;
			}
			const workbook = new ExcelJS.Workbook();
			let ignoredItemWorksheet;
			let fileName = context.contractName + '_' + '_EndUserChargeback_rejected.xlsx';
			ignoredItemWorksheet = workbook.addWorksheet('Ignored Item(s)');
			// let ignoredItemsWorkBook: any;
			// if (this.currentTab === 'ignored_items') {
			// 	ignoredItemsWorkBook = workbook.addWorksheet('Ignored Item(s)');
			// }

			exportDataGrid({
				worksheet: ignoredItemWorksheet,
				component: context.mismatchClaimItemDataGridContainer.instance,
				autoFilterEnabled: true,
				keepColumnWidths: true,
				topLeftCell: { row: 5, column: 1 },
				customizeCell: (options) => {
					const { gridCell, excelCell } = options;
					if (gridCell.rowType === "data") {
						if (gridCell.column.dataField === 'selectedClaimedUom' || gridCell.column.dataField === 'contract_uom') {
							excelCell.value = '';
							if (gridCell.value) {
								excelCell.value = gridCell.value.toString().toUpperCase();
							}
						}
						if (gridCell.column.dataField === 'is_active') {
							excelCell.value = '';
							let colorName = '';
							excelCell.value = gridCell.value ? 'Active' : 'Inactive';
							colorName = gridCell.value ? '05c1a0' : 'f99300';
							// excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: bgColor.toString().substring(1) } };
							excelCell.font = { color: { argb: colorName } };
						}
					}
				}
			}).then((cellRange) => {
				this.doSetHeaderBlockOnExportExcel(ignoredItemWorksheet, context);
			}).then(() => {
				workbook.xlsx.writeBuffer().then((buffer) => {
					saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
				});
			});
		}

	}

	private doSetHeaderBlockOnExportExcel(worksheet, context) {
		const headerRow = worksheet.getRow(2);
		headerRow.height = 30;
		if (context.contractName) {
			worksheet.mergeCells(2, 1, 2, 12);
		}
		headerRow.getCell(1).value = context.contractName;
		headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 14, 'bold': true };
		headerRow.getCell(1).alignment = { horizontal: 'center', vertical: 'center' };

		const claimIdRow = worksheet.getRow(3);
		claimIdRow.height = 30;
		claimIdRow.getCell(1).value = 'Document Date';
		claimIdRow.getCell(1).font = { name: 'Segoe UI Light', size: 12, bold: false };
		claimIdRow.getCell(2).value = this._LoginService.loginUser.server_date;
		claimIdRow.getCell(2).alignment = { horizontal: 'left' };
		claimIdRow.getCell(2).font = { name: 'Segoe UI Light', size: 12, bold: true };

		if (this.currentTab === 'ignored_items') {
			claimIdRow.getCell(4).value = 'Status';
			claimIdRow.getCell(4).font = { name: 'Segoe UI Light', size: 12, bold: false };
			claimIdRow.getCell(5).value = 'Rejected Item(s)';
			claimIdRow.getCell(5).font = { name: 'Segoe UI Light', size: 12, bold: true };
		}
	}

	public doHiddenPopop(e) {
		this.isVisiblePopup = false;
		// this.hideUnMatachedPopUpDataEvent.emit('closed');
	}

	public doChangeTab(newTab: string) {
		this.currentTab = newTab;
		this.isShowDatagrid = true;
		switch (newTab) {
			case 'ignored_items':
				this.dxDataSource = this.endUserClaimItemsModel.ignored_items_list;
				break;
			case 'succeed_items':
				this.dxDataSource = this.endUserClaimItemsModel.succeed_items_list;
				break;
			default:
				break;
		}
	}

	private setDxPopupWidth() {
		const innerWidth = window.innerWidth;
		const innerHeight = window.innerHeight;
		this.popupWidth = (innerWidth * GlobalPopoupWidth90Ratio) / 100;
		this.popupHeight = (innerHeight * GlobalPopoupHeight90Ratio) / 100;
	}

	public doCancelClaim() {
		this.isVisiblePopup = false;
		// this.hideUnMatachedPopUpDataEvent.emit('cancel_claim');
	}

	public doSubmitClaim() {
		this.callSubmitClaim();
	}

	public customizeGridRowCountText(data) {
		return 'Total Rows : ' + data.value;
	}

	public callSubmitClaim() {
		if (this.endUserClaimItemsModel.succeed_items_list.length == 0) {
			this._ToastrService.info('There are no successful items to submit..!', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}

		const formData = new FormData();
		this._LoaderService.show();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', ServerMethods.CLAIM_CHARGEBACK_WITH_CSV);
		// formData.append('csvData', JSON.stringify(this.endUserClaimItemsModel.csvData));
		if (this.endUserClaimItemsModel.contractguid) {
			formData.append('contractguid', this.endUserClaimItemsModel.contractguid);
		}
		formData.append('csvData', JSON.stringify(this.endUserClaimItemsModel.succeed_items_list));
		if (this.endUserClaimItemsModel.ignored_items_list.length > 0) {
			formData.append('rejectedItems', JSON.stringify(this.endUserClaimItemsModel.ignored_items_list));
		}
		formData.append('documentReferenceNumber', this.endUserClaimItemsModel.documentReferenceNumber);
		formData.append('claimedUserNote', this.endUserClaimItemsModel.claimedUserNote);
		formData.append('claimedUserNote', this.endUserClaimItemsModel.claimedUserNote);
		formData.append('documentURL', this.endUserClaimItemsModel.documentURL);
		formData.append('claimDocumentUploadFile', this.endUserClaimItemsModel.claimDocumentUploadFile);
		// formData.append('shortpayid', this.endUserClaimItemsModel.shortpayid);
		formData.append('contracttype', 'enduserschargebackcontracts');
		formData.append('documentUploadFile', this.endUserClaimItemsModel.documentUploadFile);
		// enable chargeback look back period usersetting for IPG customer
		if (this.enable_chargeback_look_back_period) {
			const claimDate = moment(this.endUserClaimItemsModel.claimDate).format('YYYY-MM-DD') || '';
			formData.append('claim_date', claimDate);
		}
		if (this.modifyClaimDetails && this.modifyClaimDetails['guid']) {
			formData.append('source_claim_id', this.modifyClaimDetails['guid']);
		}
		this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
			next: (response) => {
				if (response.flag) {
					response.data
					this._EndUsersChargebackContractService.setItemTabDataList(response.data['itemSold']);
					this._EndUsersChargebackContractService.setChargebackDocumentTabDataList(response.data['chargebackDocuments']);
					this.isVisiblePopup = false;
					this.hideUnMatachedPopUpDataEvent.emit('claim_submited');
					this._ToastrService.success('Document Added Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error: (error) => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			}
		});
	}

	public convertToNumber(amountVal) {
		if (this.isNumber(amountVal)) {
			return this._CurrencyPipe.transform((+amountVal || 0), this.globalCurrency);
		} else {
			return amountVal;
		}

	}

	public isNumber(value?: string | number): boolean {
		return ((value != null) &&
			(value !== '') &&
			!isNaN(Number(value.toString())));
	}
}
