<div class="rightBar">
	<div class="rightBlock">
		<!-- <div class="titleRow">
			<h1>{{editChargebackGuid ? 'Update' : 'Add New'}} Billback & Chargeback Agreement</h1>
		</div> -->
		<div class="contentRow cf">
			<div class="borderBox cf padB0">
				<div class="formControls editAgreeDetail">

					<div class="row ">
						<div class="cols12 ">
							<div class="feildCol radioGroup feildColAuto">
								<label>BillBack Type:</label>
								<dx-radio-group [items]="billbackTypeRadio" [value]="chargebackContractModelUI.billBackType"
									(onValueChanged)="doChangeBillBackType($event)"
									[disabled]="isDisablePermission || isDisabledBillbackTypeField" layout="horizontal"
									displayExpr="text" valueExpr="value">
								</dx-radio-group>
							</div>
						</div>
						<div class="cols12">
							<div class="feildCol">
								<dx-text-box mode="text" [(value)]="chargebackContractModelUI.contractName" maxLength="500">
								</dx-text-box>
								<div class="inputField">
									<label for="contractName">Contract Name</label>
								</div>
							</div>
						</div>
						<div class="cols8">
							<div class="dx-field feildCol" [ngClass]="{inputDisable: isDisablePermission || isDisabledCustomerField}">
								<div class="dx-field-label">Customer</div>
								<div class="dx-field-value" style="width: 100%;">
									<dx-drop-down-box [(value)]="customerGridBoxValue" valueExpr="code"
										[displayExpr]="displayExprCustomerName" placeholder="Select Customer..."
										[(opened)]="isCustomerDropDownBoxOpened" [showClearButton]="true"
										[dataSource]="customerDataSource" (onOptionChanged)="onOptionChangedCustomer($event)"
										[disabled]="isDisablePermission || isDisabledCustomerField">
										<div *dxTemplate="let data of 'content'">
											<dx-data-grid #customerDataGrid [dataSource]="customerDataSource"
												[columns]="customerGridBoxColumns" [hoverStateEnabled]="true"
												[remoteOperations]="true" [wordWrapEnabled]="true" [showBorders]="true"
												[(selectedRowKeys)]="customerGridBoxValue" [filterRow]="{ visible: true }"
												[height]="345">
												<dxo-selection mode="single"></dxo-selection>
												<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
												<dxo-paging [enabled]="true" [pageSize]="pageOptions.pageSize"></dxo-paging>
											</dx-data-grid>
										</div>
									</dx-drop-down-box>
								</div>
							</div>
						</div>
						<div class="cols2">
							<div class="datePicker">
								<dx-date-box [(value)]="chargebackContractModelUI.startDate" [disabled]="isDisablePermission"
									type="date" displayFormat="yyyy-MM-dd">
								</dx-date-box>
								<div class="inputField inputFieldFilterLbl">
									<label for="inputFieldFilterLbl">Start Date</label>
								</div>
							</div>
						</div>
						<div class="cols2">
							<div class="datePicker">
								<dx-date-box [(value)]="chargebackContractModelUI.endDate" type="date"
									displayFormat="yyyy-MM-dd">
								</dx-date-box>
								<div class="inputField inputFieldFilterLbl">
									<label for="inputFieldFilterLbl">End Date</label>
								</div>
							</div>
						</div>
						<!-- <div class="cols12 colsP0 padT10 BillBackType">
							<div class="cols6 mrgT10 " *ngIf="editChargebackGuid">
								<dx-button class="secondaryBtn button" text="Download Chargeback Price List" icon="download"
									type="normal" stylingMode="contained" (onClick)="downloadPriceList()"
									[useSubmitBehavior]="false">
								</dx-button>
							</div>
							<div class="right mrgT10" [ngClass]="editChargebackGuid ? 'cols6' : 'cols12'"
								*ngxPermissionsOnly="isCurrentTabPermission">
								<dx-button [disabled]="isAddMode" class="secondaryBtn" [ngClass]="{'grayButton': isAddMode}"
									text="Update Quantities Sold" type="normal" stylingMode="contained"
									(onClick)="updateQuantitiesSoldClick()" [useSubmitBehavior]="false">
								</dx-button>
							</div>
						</div> -->
					</div>
				</div>
			</div>

			<div class="borderBox cf">

				<div class="tabbing cf">
					<ul>
						<li *ngFor="let item of tabItemsList" (click)="doClickTab(item.key)"
							[ngClass]="{active: selectedTabVal == item.key}">
							<a>{{item.displayVal}}</a>
						</li>
					</ul>
				</div>
				<app-cc-items-tab [chargebackContractModelUI]="chargebackContractModelUI"
					[itemTabDataList]="itemTabDataList" [itemDataSource]="itemDataSource"
					(saveChargebackItemTabDataEvent)="doSaveChargebackItemTabData($event)"
					[itemTabDataSource]="itemTabDataSource" *ngIf="selectedTabVal === tabItemsList[0].key && productSearchItemType ===
					'item'">
				</app-cc-items-tab>

				<app-cc-pricelist-tab [chargebackContractModelUI]="chargebackContractModelUI"
					[itemTabDataList]="itemTabDataList"
					(saveChargebackItemTabDataEvent)="doSaveChargebackItemTabData($event)"
					[customerListOfPrices]="listOfPrices" [itemTabDataSource]="itemTabDataSource"
					*ngIf="selectedTabVal === tabItemsList[0].key && productSearchItemType === 'price'">
				</app-cc-pricelist-tab>

				<app-cc-chargeback-documents-tab *ngIf="selectedTabVal === tabItemsList[1].key"
					[itemTabDataList]="itemTabDataList" [chargeBackDocumentsTabDatalist]="chargeBackDocumentsTabDatalist"
					(saveChargeDocumentsTabDataEvent)="doSaveChargebackItemTabData($event)">
				</app-cc-chargeback-documents-tab>
				<div *ngIf="editChargebackGuid" class="alignLeft mrgR20 mrgT10">
					<dx-button (onClick)="doExportToExcel()" text="Export To Excel" icon="exportxlsx" id="buttonDownload"
						class="secondaryBtn" type="normal" stylingMode="contained" [useSubmitBehavior]="false">
					</dx-button>
				</div>
				<div class="alignRight mrgT10" *ngxPermissionsOnly="isCurrentTabPermission">
					<dx-button class="button primaryBtn" text="Submit" icon="save" type="normal" stylingMode="contained"
						(onClick)="doSaveChargebackContract()" [useSubmitBehavior]="false">
					</dx-button>
				</div>
			</div>
		</div>
	</div>
</div>
