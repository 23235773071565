<dx-data-grid #dataGridContainer id="gridContainer" [dataSource]="approvalsDataSource" [showBorders]="true"
	[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true" [columnResizingMode]="'nextColumn'"
	[allowColumnReordering]="true" [columnAutoWidth]="true" [filterSyncEnabled]="true"
	[remoteOperations]="{ groupPaging: true }" [dateSerializationFormat]="'yyyy-MM-dd'" [columnAutoWidth]="true"
	[filterValue]="filterValue" (onRowClick)="doMinMaxChargebackClaimApproverDetails($event);" [width]="'100%'"
	(onToolbarPreparing)="doToolbarPreparing($event)" class="cellColor hoverClr hideFilterPanelInx">
	<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
	<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
	<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
		[showInfo]="true"> </dxo-pager>
	<dxo-filter-row [visible]="true"></dxo-filter-row>
	<dxo-filter-panel [visible]="true"></dxo-filter-panel>
	<dxo-header-filter [visible]="true"></dxo-header-filter>
	<dxo-sorting mode="single"></dxo-sorting>
	<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
	<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadApproveNotificationState"
		[customSave]="doSaveApproveNotificationState" [storageKey]="stateStorageKey">
	</dxo-state-storing>
	<div *dxTemplate="let data of 'templateFilterText'">
		<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
			<span>{{filterText}}</span>
		</div>
	</div>

	<div *dxTemplate="let data of 'templateActionBtns'">
		<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
			text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
			[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
			(onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
			[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
	</div>

	<dxi-column dataField="ixcode" caption="Approval Code" dataType="string" [allowHeaderFiltering]="false"
		cellTemplate="cellTemplateApprovalCode">
		<div *dxTemplate="let element of 'cellTemplateApprovalCode'">
			<span class="highlight pointerMark"> {{element.data.ixcode || ''}}</span>
		</div>
	</dxi-column>
	<dxi-column caption="Claim No." [allowHiding]="false" dataField="claimsList" dataType="string" [allowEditing]="false"
		[allowFiltering]="true" [allowHeaderFiltering]="false" cellTemplate="cellTemplateClaimNo">
		<div *dxTemplate="let element of 'cellTemplateClaimNo'">
			<span>
				{{element.data.claimsList && element.data.claimsList.length > 0 ?
				element.data.claimsList[0] : ''}}
				<span [attr.id]="'claimNoToolTip_' + element.data.guid"
					*ngIf="element.data.claimsList[0] && (element.data.claimsList.length === 2 || element.data.claimsList.length >= 3)">
					+ {{element.data.claimsList.length-1}}
					{{ element.data.claimsList.length === 2 ? 'Other' : element.data.claimsList.length >= 3 ?
					'Others' : ''}}
				</span>
				<dx-tooltip target="#claimNoToolTip_{{element.data.guid}}" showEvent="dxhoverstart" hideEvent="dxhoverend"
					position="right">
					<div *dxTemplate="let d = d; of: 'content'">
						<div class="tooltip-wrapper">
							<div class="greenTipout">
								<ol class="tipList">
									<li *ngFor="let claim of element.data.claimsList; let p = index;">
										<a>{{claim || ''}}</a>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</dx-tooltip>
			</span>
		</div>
	</dxi-column>
	<dxi-column caption="End-User Name" [allowHiding]="false" dataField="endusersList" dataType="string"
		[allowEditing]="false" [allowFiltering]="true" [allowHeaderFiltering]="false"
		cellTemplate="cellTemplateEndUserName">
		<div *dxTemplate="let element of 'cellTemplateEndUserName'">
			<span>
				{{element.data.endusersList && element.data.endusersList.length > 0 ?
				element.data.endusersList[0].end_user_name : ''}}
				<span [attr.id]="'endUserToolTip_' + element.data.guid"
					*ngIf="element.data.endusersList[0] && (element.data.endusersList.length === 2 || element.data.endusersList.length >= 3)">
					+ {{element.data.endusersList.length-1}}
					{{ element.data.endusersList.length === 2 ? 'Other' :
					element.data.endusersList.length >= 3 ?
					'Others' : ''}}
				</span>
				<dx-tooltip target="#endUserToolTip_{{element.data.guid}}" showEvent="dxhoverstart" hideEvent="dxhoverend"
					position="right">
					<div *dxTemplate="let d = d; of: 'content'">
						<div class="tooltip-wrapper">
							<div class="greenTipout">
								<ol class="tipList">
									<li *ngFor="let enduser of element.data.endusersList; let p = index;">
										<a>{{enduser.end_user_name || ''}}</a>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</dx-tooltip>
			</span>
		</div>
	</dxi-column>
	<dxi-column dataField="title" caption="Workflow" dataType="string" [allowHeaderFiltering]="false"></dxi-column>
	<dxi-column dataField="subtype" caption="Workflow Type" dataType="string" [allowFiltering]="false"
		[allowHeaderFiltering]="true" cellTemplate="cellTemplateSubType">
		<dxo-header-filter [dataSource]="listOfWorkflowTypesHeaderFilters"></dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateSubType'">
			{{ element.data.subtype || '' | filterFromList: listOfWorkflowTypes: 'value' : 'name'}}
		</div>
	</dxi-column>
	<dxi-column dataField="approval_status" caption="Approval Status" dataType="string" [allowFiltering]="false"
		[allowHeaderFiltering]="true" cellTemplate="cellTemplateApprovalStatus">
		<dxo-header-filter [dataSource]="listOfStatuses"></dxo-header-filter>
		<div *dxTemplate="let element of 'cellTemplateApprovalStatus'">
			<div [ngClass]="element.data.approval_status || '' | filterFromList:listOfStatuses : 'value' : 'classname'">
				{{ element.data.approval_status || '' | filterFromList:listOfStatuses:'value':'text'}}
			</div>
		</div>
	</dxi-column>
	<dxi-column caption="Approval Amount" dataField="approvalAmount" alignment="right" dataType="number"
		[allowHeaderFiltering]="false" [allowFiltering]="false">
		<dxo-format type="currency" [precision]="decimalPointForCurrency">
		</dxo-format>
		<div *dxTemplate="let element of 'cellTemplateCustomerPrice'">
			{{element.data.approvalAmount|| 0 | customCurrencyPipe}}
		</div>
	</dxi-column>

</dx-data-grid>