import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DxDataGridModule, DxFormModule, DxSelectBoxModule, DxTabPanelModule, DxButtonModule, DxDateBoxModule, DxTextBoxModule, DxValidationSummaryModule, DxValidatorModule, DxNumberBoxModule, DxDropDownButtonModule, DxListModule, DxDropDownBoxModule, DxTagBoxModule, DxHtmlEditorModule, DxSwitchModule, DxCheckBoxModule, DxTooltipModule, DxPopoverModule, DxScrollViewModule, DxLoadPanelModule, DxRadioGroupModule, DxTextAreaModule, DxLookupModule, DxChartModule, DxFilterBuilderModule, DxTemplateModule, DxPopupModule, DxTreeListModule, DxTreeViewModule, DxSortableModule, DxBoxModule, DxDrawerModule, DxDateRangeBoxModule, DxPivotGridModule, DxProgressBarModule, DxToolbarModule } from 'devextreme-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ListDeliveryComponent } from './list-deliveries/list-deliveries.component';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
// tslint:disable-next-line: max-line-length
import { ViewPreviousSettlementPeriodDialogComponent } from './view-previous-settlement-period-dialog/view-previous-settlement-period-dialog.component';
import { ResharedModule } from './../reshared/reshared.module';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ApinvoiceViewDialogComponent } from './apinvoice-view-dialog/apinvoice-view-dialog.component';
import { TaskDialogComponent } from './task-dialog/task-dialog.component';
import { BarcodeListDialogComponent } from './barcode-list-dialog/barcode-list-dialog.component';
import { ExtendTrailDialogComponent } from './extend-trail-dialog/extend-trail-dialog.component';
import { SubscriptionUpgradeDialogComponent } from './subscription-upgrade-dialog/subscription-upgrade-dialog.component';
import { PaymentSuccessfulDialogComponent } from './payment-successful-dialog/payment-successful-dialog.component';
import { AddEndUsersDialogComponent } from './add-end-users-dialog/add-end-users-dialog.component';
import { ClaimedItemsListDialogComponent } from './claimed-items-list-dialog/claimed-items-list-dialog.component';
import { ViewChargeDocumentsDialogComponent } from './view-charge-documents-dialog/view-charge-documents-dialog.component';
import { BillingInvoiceDetailDialogComponent } from './billing-invoice-detail-dialog/billing-invoice-detail-dialog.component';
import { AddContactEmployeeDialogComponent } from './add-contactemployee-dialog/add-contact-employee-dialog.component';
import { TermsAndConditionsDialogComponent } from './terms-and-conditions/terms-and-conditions-dialog.component';
import { MainPipesModule } from '@app/pipes/main-pipes.module';
import { ApEditCustomerDialogComponent } from './ap-edit-customer-dialog/ap-edit-customer-dialog.component';
import { AddClientToContactsDialogComponent } from './add-client-to-contracts-dialog/add-client-to-contracts-dialog.component';
import { ShowLicenseFeaturesDialogComponent } from './show-license-features/show-license-features-dialog.component';
import { ApAddEditCrmDialogComponent } from './ap-add-edit-crm-dialog/ap-add-edit-crm-dialog.component';
import { ApAddEditContactEmployeeDialogComponent } from './ap-add-edit-contactemployee-dialog/ap-add-edit-contact-employee-dialog.component';
import { ApAddEditTaskDialogComponent } from './ap-add-edit-task-dialog/ap-add-edit-task-dialog.component';
import { ApAddEditInxcustomerDialogComponent } from './ap-add-edit-inxcustomer-dialog/ap-add-edit-inxcustomer-dialog.component';
import { AddIncentxSupportUserDialogComponent } from './add-incentx-support-user-dialog/add-incentx-support-user-dialog.component';
import { ListConfigurationComponentDialogComponent } from './list-configuration-component-dialog/list-configuration-component-dialog.component';
import { AddEditUserProfileDialogComponent } from './add-edit-userprofile-dialog/add-edit-userprofile-dialog.component';
import { EditCompanyInfoDialogComponent } from './companyinfo-dialog/edit-companyinfo-dialog/edit-companyinfo-dialog.component';
import { EditFinancialDialogComponent } from './companyinfo-dialog/edit-financial-contact-dialog/edit-financial-contact-dialog.component';
import { EditTechnicalDialogComponent } from './companyinfo-dialog/edit-technical-contact-dialog/edit-technical-contact-dialog.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UploadNewPricelistDialogComponent } from './upload-new-pricelist-dialog/upload-new-pricelist-dialog.component';
import { OverduePaymentDialogComponent } from './overdue-payment-dialog/overdue-payment-dialog.component';
import { ClaimAttachedDocumentsComponent } from './claim-attached-documents/claim-attached-documents.component';
import { UploadRebateExclusionItemsDialogComponent } from './upload-rebate-exclusion-items-dialog/upload-rebate-exclusion-items-dialog.component';
import { ViewRebateMembersListDialogComponent } from './view-rebate-members-list-dialog/view-rebate-members-list-dialog.component';
import { EditPaymentApiDialogComponent } from './companyinfo-dialog/edit-payment-api-dialog/edit-payment-api-dialog.component';
import { AddCreditCardToCardNoxDialogComponent } from './payment-api-dialogs/add-credit-card-to-card-nox/add-credit-card-to-card-nox-dialog.component';
import { IncomingPaymentsDetailsViewComponent } from './incoming-payments-details-view/incoming-payments-details-view.component';
import { IncomingAddEditPaymentComponent } from './incoming-add-edit-payment/incoming-add-edit-payment.component';
import { ListPrchseApInvoicesComponent } from './list-prchse-ap-invoices/list-prchse-ap-invoices.component';
import { AddEditQuoteComponent } from './add-edit-quote/add-edit-quote.component';
import { AddEditOrderComponent } from './add-edit-order/add-edit-order.component';
import { AddCreditCardToUsaepayDialogComponent } from './payment-api-dialogs/add-credit-card-to-usaepay/add-credit-card-to-usaepay-dialog.component';
import { ListArCreditMemoComponent } from './list-ar-credit-memo/list-ar-credit-memo.component';
import { AddEditQuotationOrderComponent } from './add-edit-quotation-order/add-edit-quotation-order.component';
import { IxLoadPanelComponent } from './ix-load-panel/ix-load-panel.component';
import { ViewRebateCreditMemoForPastInvoicesListDialogComponent } from './view-rebate-credit-memo-for-past-invoices-list-dialog/view-rebate-credit-memo-for-past-invoices-list-dialog.component';
import { TileOrderSettingsComponent } from './tile-order-settings/tile-order-settings.component';
import { TileInvoiceSettingsComponent } from './tile-invoice-settings/tile-invoice-settings.component';
import { DeliveryDetailsComponent } from './delivery-details/delivery-details.component';

import { CrmSalesTileDetailsComponent } from '@app/pages/crms/crm-sales-tile-details/crm-sales-tile-details.component';
import { CcChargebackDocumentsTabComponent } from '@app/pages/contracts/billback/chargeback-contract/cc-chargeback-documents-tab/cc-chargeback-documents-tab.component';
import { CcItemsTabComponent } from '@app/pages/contracts/billback/chargeback-contract/cc-items-tab/cc-items-tab.component';
import { ChargebackContractComponent } from '@app/pages/contracts/billback/chargeback-contract/chargeback-contract.component';
import { BillbackChargebackContractListingComponent } from '@app/pages/contracts/billback-chargeback-contract-listing/billback-chargeback-contract-listing.component';
import { PrchseInvoiceDetailsComponent } from '@app/pages/purchases/prchse-ap-invoice-details/prchse-ap-invoice-details.component';
import { PrchseApCreditMemoDetailsComponent } from '@app/pages/purchases/prchse-ap-credit-memo-details/prchse-ap-credit-memo-details.component';
import { RebatesTierbaseCreditMemoListTabComponent } from '@app/pages/contracts/rebates-tierbase-contract/rebates-tierbase-credit-memo-list-tab/rebates-tierbase-credit-memo-list-tab.component';
import { RebatesTierbaseCloseSettlementListTabComponent } from '@app/pages/contracts/rebates-tierbase-contract/rebates-tierbase-close-settlement-list-tab/rebates-tierbase-close-settlement-list-tab.component';
import { RebatesTierbaseCalculationViewComponent } from '@app/pages/contracts/rebates-tierbase-contract/rebates-tierbase-calculation-view/rebates-tierbase-calculation-view.component';
import { RebatesTierbaseReportviewBuyinggroupComponent } from '@app/pages/contracts/rebates-tierbase-contract/rebates-tierbase-reportview-buyinggroup/rebates-tierbase-reportview-buyinggroup.component';
import { RebatesTierbaseContractComponent } from '@app/pages/contracts/rebates-tierbase-contract/rebates-tierbase-contract.component';
import { ArCreditMemoDetailsComponent } from '@app/pages/sales/ar-credit-memo-details/ar-credit-memo-details.component';
import { SalesDetailsComponent } from '@app/pages/sales/sales-details/sales-details.component';
import { RebatesContractListingComponent } from '@app/pages/contracts/rebates-contract-listing/rebates-contract-listing.component';
import { CrmContractChargebackTileComponent } from '@app/pages/crms/crm-contract-chargeback-tile/crm-contract-chargeback-tile.component';
import { CrmContractRebateTileComponent } from '@app/pages/crms/crm-contract-rebate-tile/crm-contract-rebate-tile.component';
import { CrmCardUsaepayListComponent } from '@app/pages/crms/crm-card-usaepay-list/crm-card-usaepay-list.component';
import { CrmAddEditQuoteComponent } from '@app/pages/crms/crm-add-edit-quote/crm-add-edit-quote.component';
import { CrmSalesRevenueTileComponent } from '@app/pages/crms/crm-sales-revenue-tile/crm-sales-revenue-tile.component';
import { CrmAddEditPaymentsComponent } from '@app/pages/crms/crm-add-edit-payments/crm-add-edit-payments.component';
import { CrmPaymentsDetailsViewComponent } from '@app/pages/crms/crm-payments-details-view/crm-payments-details-view.component';
import { CrmPaymentsListComponent } from '@app/pages/crms/crm-payments-list/crm-payments-list.component';
import { CrmCardKnoxListComponent } from '@app/pages/crms/crm-card-knox-list/crm-card-knox-list.component';
import { CrmCustomerDetailComponent } from '@app/pages/crms/crm-customer-detail/crm-customer-detail.component';
import { CrmAddEditOrderComponent } from '@app/pages/crms/crm-add-edit-order/crm-add-edit-order.component';
import { CrmSalesMarginTileComponent } from '@app/pages/crms/crm-sales-margin-tile/crm-sales-margin-tile.component';
import { CrmSalesVolumeTileComponent } from '@app/pages/crms/crm-sales-volume-tile/crm-sales-volume-tile.component';
import { CrmSalesVarietyTileComponent } from '@app/pages/crms/crm-sales-variety-tile/crm-sales-variety-tile.component';
import { CrmSalesProfitTileComponent } from '@app/pages/crms/crm-sales-profit-tile/crm-sales-profit-tile.component';
import { SalesRepListDialogComponent } from './sales-rep-list-dialog/sales-rep-list-dialog.component';
import { CrmSalesOrderTileComponent } from '@app/pages/crms/crm-sales-order-tile/crm-sales-order-tile.component';
import { CrmSalesInvoiceTileComponent } from '@app/pages/crms/crm-sales-invoice-tile/crm-sales-invoice-tile.component';
import { Customer360TileSettingsComponent } from '@app/pages/crms/customer-360-tile-settings/customer-360-tile-settings.component';
import { UploadChargebackEnduserClaimsDialogComponent } from './upload-chargeback-enduser-claims-dialog/upload-chargeback-enduser-claims-dialog.component';
import { UploadRebateContractDialogComponent } from './upload-rebate-contract-dialog/upload-rebate-contract-dialog.component';
import { DateRangeGlobalSettingsComponent } from './date-range-global-settings/date-range-global-settings.component';
import { TileQuoteSettingsComponent } from './tile-quote-settings/tile-quote-settings.component';
import { ApAddEditModulePermissionsDialogComponent } from './ap-add-edit-module-permissions-dialog/ap-add-edit-module-permissions-dialog.component';
import { AddEditEndUsersAddressesDialogComponent } from './add-edit-end-users-addresses-dialog/add-edit-end-users-addresses-dialog.component';
import { CompanyEndUserInvoicesListComponent } from '@app/pages/businessplans/company-end-user-invoices-list/company-end-user-invoices-list.component';
import { CompanyEndUserInvoiceDetailsComponent } from '@app/pages/businessplans/company-end-user-invoice-details/company-end-user-invoice-details.component';
import { CcPricelistTabComponent } from '@app/pages/contracts/billback/chargeback-contract/cc-pricelist-tab/cc-pricelist-tab.component';
import { TileArCreditmemoSettingsComponent } from './tile-ar-creditmemo-settings/tile-ar-creditmemo-settings.component';
import { CrmSalesArCreditmemoTileComponent } from '@app/pages/crms/crm-sales-ar-creditmemo-tile/crm-sales-ar-creditmemo-tile.component';
import { UploadArInvoicesDialogComponent } from './upload-ar-invoices-dialog/upload-ar-invoices-dialog.component';
import { SalesInvoiceDetailsOriginalComponent } from '@app/pages/sales/sales-invoice-details-original/sales-invoice-details-original.component';
import { ListIncentivesComponent } from './list-incentives/list-incentives.component';
import { CommissionTierbaseCommissionCalculationViewComponent } from '@app/pages/contracts/commission-tierbase-contract/commission-tierbase-commission-calculation-view/commission-tierbase-commission-calculation-view.component';
import { AddEditVirtualInvoiceComponent } from './add-edit-virtual-invoice/add-edit-virtual-invoice.component';
import { LogoutComponent } from 'app/auth/logout/logout.component';
import { ListVendorsComponent } from '@app/pages/businessplans/list-vendors/list-vendors.component';
import { ListBuyingGroupsComponent } from '@app/pages/businessplans/list-buying-groups/list-buying-groups.component';
import { ListEndUsersComponent } from '@app/pages/businessplans/list-end-users/list-end-users.component';
import { ListTasksComponent } from '@app/pages/crms/list-tasks/list-tasks.component';
import { IncentivesExportPdfComponent } from './incentives-export-pdf/incentives-export-pdf.component';
import { UnmatchedClaimItemDxPopupComponent } from './unmatched-claim-item-dx-popup/unmatched-claim-item-dx-popup.component';
import { CloseMultipleSettlementDxDialogComponent } from './close-multiple-settlement-dx-dialog/close-multiple-settlement-dx-dialog.component';
import { AddEditCrmDialogComponent } from './add-edit-crm-dialog/add-edit-crm-dialog.component';
import { AddEditCrmAddressDialogComponent } from './add-edit-crm-address-dialog/add-edit-crm-address-dialog.component';
import { ApAddEditCrmAddressDialogComponent } from './ap-add-edit-crm-address-dialog/ap-add-edit-crm-address-dialog.component';
import { MessageTrallComponent } from './message-trall/message-trall.component';
import { EuccViewChargebackDocumentsDxPopupComponent } from '@app/pages/contracts/billback/end-users-chargeback-contract/eucc-view-chargeback-documents-dx-popup/eucc-view-chargeback-documents-dx-popup.component';
import { UploadChargebackEnduserClaimsDxDialogComponent } from './upload-chargeback-enduser-claims-dx-dialog/upload-chargeback-enduser-claims-dx-dialog.component';
import { CardViewCloseMultipleSettlementComponent } from './card-view-close-multiple-settlement/card-view-close-multiple-settlement.component';
import { UploadItemsForPricelistDialogComponent } from './upload-items-for-pricelist-dialog/upload-items-for-pricelist-dialog.component';
import { ListValidatedItemsForPricelistDialogComponent } from './list-validated-items-for-pricelist-dialog/list-validated-items-for-pricelist-dialog.component';
import { ListValidatedArInvoicesDialogComponent } from './list-validated-ar-invoices-dialog/list-validated-ar-invoices-dialog.component';
import { ListCreatedVirtualInvoicesDialogComponent } from './list-created-virtual-invoices-dialog/list-created-virtual-invoices-dialog.component';
import { ViewInvoiceDetailPopupComponent } from './view-invoice-detail-popup/view-invoice-detail-popup.component';
import { ViewOrderChangeorderDetailPopupComponent } from './view-order-changeorder-detail-popup/view-order-changeorder-detail-popup.component';
import { ViewCraditmemoDetailPopupComponent } from './view-craditmemo-detail-popup/view-craditmemo-detail-popup.component';
import { DxiItemModule, DxoLabelModule } from 'devextreme-angular/ui/nested';
import { CustomCurrencyPipe, CustomCurrencySymbolPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
import { CalculateAmountFromListPipe, FilterFromListPipe, FilterListFromListPipe } from '@app/pipes/filter-from-list.pipe';
import { AddEditCurrencyDialogComponent } from './add-edit-currency-dialog/add-edit-currency-dialog.component';
import { CustomNumberNoRoundingPipe } from '@app/pipes/custom-number-format/custom-number-format.pipe';
import { ViewClaimPreviewPopupComponent } from './view-claim-preview-popup/view-claim-preview-popup.component';
import { ViewEndUsersInvoiceDetailPopupComponent } from './view-end-users-invoice-detail-popup/view-end-users-invoice-detail-popup.component';
import { PricelistDetailsComponent } from '@app/pages/products/pricelist-details/pricelist-details.component';
import { PricelistApprovalTrailsComponent } from '@app/pages/products/pricelist-approval-trails/pricelist-approval-trails.component';
import { PricelistEndUsersListingComponent } from '@app/pages/products/pricelist-end-users-listing/pricelist-end-users-listing.component';
import { ListValidatedExternalSystemsDataDialogComponent } from './list-validated-external-systems-data-dialog/list-validated-external-systems-data-dialog.component';
import { RebatesSettlementApprovalTrailsDialogComponent } from './rebates-settlement-approval-trails-dialog/rebates-settlement-approval-trails-dialog.component';
import { CompanyEndUserRebatePayoutListComponent } from '@app/pages/businessplans/company-end-user-rebate-payout-list/company-end-user-rebate-payout-list.component';
import { PricelistTermsConditionsComponent } from '@app/pages/products/pricelist-terms-conditions/pricelist-terms-conditions.component';
import { ExportPricelistTermsConditionsDialogComponent } from './export-pricelist-terms-conditions-dialog/export-pricelist-terms-conditions-dialog.component';
import { MmeuccClaimApprovalTrailComponent } from './mmeucc-claim-approval-trail/mmeucc-claim-approval-trail.component';
import { MmeuccMultiClaimApprovalPopupComponent } from './mmeucc-multi-claim-approval-popup/mmeucc-multi-claim-approval-popup.component';
import { CompanyEndUserInvoiceReportComponent } from '@app/pages/businessplans/company-end-user-invoice-report/company-end-user-invoice-report.component';
import { CompanyEndUserInvoiceReportHeaderComponent } from '@app/pages/businessplans/company-end-user-invoice-report-header/company-end-user-invoice-report-header.component';
import { CompanyEndUserInvoiceReportListingComponent } from '@app/pages/businessplans/company-end-user-invoice-report-listing/company-end-user-invoice-report-listing.component';
import { SalesArInvoiceReportComponent } from '@app/pages/sales/sales-ar-invoice-report/sales-ar-invoice-report.component';
import { SalesArInvoiceReportHeaderComponent } from '@app/pages/sales/sales-ar-invoice-report-header/sales-ar-invoice-report-header.component';
import { SalesArInvoiceReportListingComponent } from '@app/pages/sales/sales-ar-invoice-report-listing/sales-ar-invoice-report-listing.component';
import { CrmCustomFieldsComponent } from '@app/pages/crms/crm-custom-fields/crm-custom-fields.component';
import { CompanyCustomFieldsComponent } from '@app/pages/businessplans/company-custom-fields/company-custom-fields.component';
import { FileUploadDialogComponent } from './file-upload-dialog/file-upload-dialog.component';
import { CustomFileSizeFormatPipe } from '@app/pipes/custom-file-size-format/custom-file-size-format.pipe';
import { ApSalesInvoiceDetailsComponent } from 'app/adminportal/ap-sales/ap-sales-invoice-details/ap-sales-invoice-details.component';
import { ApSalesBillingInvoicesListComponent } from 'app/adminportal/ap-sales/ap-sales-billing-invoices-list/ap-sales-billing-invoices-list.component';
import { SendEmailFromZohoDialogComponent } from './send-email-from-zoho-dialog/send-email-from-zoho-dialog.component';
import { MmeuccViewClaimQtyDetailPopupComponent } from './mmeucc-view-claim-qty-detail-popup/mmeucc-view-claim-qty-detail-popup.component';
import { ApZohoCardPayemntListComponent } from 'app/adminportal/ap-customers/ap-zoho-card-payemnt-list/ap-zoho-card-payemnt-list.component';
import { ShowUnderscoreToSpacePipe } from '@app/pipes/show-addresses/show-addresses.pipe';
import { ApZohoCardPaymentDetailsDialogComponent } from 'app/adminportal/ap-customers/ap-zoho-card-payment-details-dialog/ap-zoho-card-payment-details-dialog.component';
import { InputComponentsModule } from 'app/input-components/input-components.module';
import { ApCustomerZohoSubscriptionsDetailsComponent } from 'app/adminportal/ap-customers/ap-customer-zoho-subscriptions-details/ap-customer-zoho-subscriptions-details.component';
import { CloseCurrentSettlementPeriodConfirmDialogComponent } from './close-current-settlement-period-confirm-dialog/close-current-settlement-period-confirm-dialog.component';
import { UploadItemsForItemTabTpmDetailsDialogComponent } from './upload-items-for-item-tab-tpm-details-dialog/upload-items-for-item-tab-tpm-details-dialog.component';
import { ListValidatedItemsForItemTabTPMtDialogComponent } from './list-validated-items-for-item-tab-tpm-dialog/list-validated-items-for-item-tab-tpm-dialog.component';
import { UploadClaimsForTpmDialogComponent } from './upload-claims-for-tpm-dialog/upload-claims-for-tpm-dialog.component';
import { ListValidatedClaimsForTpmDialogComponent } from './list-validated-claims-for-tpm-dialog/list-validated-claims-for-tpm-dialog.component';
import { TradePromotionClaimsListComponent } from '@app/pages/contracts/trade-promotion/trade-promotion-details/trade-promotion-claims-list/trade-promotion-claims-list.component';

@NgModule({
	declarations: [
		ViewPreviousSettlementPeriodDialogComponent,
		ConfirmationDialogComponent,
		ApinvoiceViewDialogComponent,
		TaskDialogComponent,
		BarcodeListDialogComponent,
		ExtendTrailDialogComponent,
		SubscriptionUpgradeDialogComponent,
		PaymentSuccessfulDialogComponent,
		AddEndUsersDialogComponent,
		AddEditUserProfileDialogComponent,
		EditFinancialDialogComponent,
		EditTechnicalDialogComponent,
		EditCompanyInfoDialogComponent,
		ChangePasswordDialogComponent,
		ClaimedItemsListDialogComponent,
		ViewChargeDocumentsDialogComponent,
		BillingInvoiceDetailDialogComponent,
		AddContactEmployeeDialogComponent,
		// ActiveUserDialogComponent,
		TermsAndConditionsDialogComponent,
		ApEditCustomerDialogComponent,
		ShowLicenseFeaturesDialogComponent,
		AddClientToContactsDialogComponent,
		ApAddEditCrmDialogComponent,
		ApAddEditContactEmployeeDialogComponent,
		ApAddEditTaskDialogComponent,
		ApAddEditInxcustomerDialogComponent,
		AddIncentxSupportUserDialogComponent,
		ListConfigurationComponentDialogComponent,
		UploadNewPricelistDialogComponent,
		OverduePaymentDialogComponent,
		ClaimAttachedDocumentsComponent,
		UploadRebateExclusionItemsDialogComponent,
		ViewRebateMembersListDialogComponent,
		EditPaymentApiDialogComponent,
		AddCreditCardToCardNoxDialogComponent,
		IncomingPaymentsDetailsViewComponent,
		IncomingAddEditPaymentComponent,
		ListDeliveryComponent,
		ListPrchseApInvoicesComponent,
		ListArCreditMemoComponent,
		AddEditQuoteComponent,
		AddEditOrderComponent,
		AddEditQuotationOrderComponent,
		AddCreditCardToUsaepayDialogComponent,
		IxLoadPanelComponent,
		ViewRebateCreditMemoForPastInvoicesListDialogComponent,
		TileOrderSettingsComponent,
		TileInvoiceSettingsComponent,
		TileQuoteSettingsComponent,
		CrmCustomerDetailComponent,
		CrmCardKnoxListComponent,
		CrmPaymentsListComponent,
		CrmPaymentsDetailsViewComponent,
		CrmAddEditPaymentsComponent,
		CrmSalesRevenueTileComponent,
		CrmSalesInvoiceTileComponent,
		CrmSalesOrderTileComponent,
		Customer360TileSettingsComponent,
		CrmSalesVolumeTileComponent,
		CrmSalesVarietyTileComponent,
		CrmSalesProfitTileComponent,
		CrmSalesMarginTileComponent,
		CrmSalesTileDetailsComponent,
		CrmAddEditQuoteComponent,
		CrmAddEditOrderComponent,
		CrmCardUsaepayListComponent,
		CrmContractRebateTileComponent,
		CrmContractChargebackTileComponent,
		RebatesContractListingComponent,
		SalesDetailsComponent,
		ArCreditMemoDetailsComponent,
		RebatesTierbaseContractComponent,
		RebatesTierbaseReportviewBuyinggroupComponent,
		RebatesTierbaseCalculationViewComponent,
		RebatesTierbaseCloseSettlementListTabComponent,
		RebatesTierbaseCreditMemoListTabComponent,
		PrchseApCreditMemoDetailsComponent,
		PrchseInvoiceDetailsComponent,
		BillbackChargebackContractListingComponent,
		ChargebackContractComponent,
		CcItemsTabComponent,
		CcPricelistTabComponent,
		CcChargebackDocumentsTabComponent,
		DeliveryDetailsComponent,
		SalesRepListDialogComponent,
		UploadChargebackEnduserClaimsDialogComponent,
		UploadRebateContractDialogComponent,
		DateRangeGlobalSettingsComponent,
		ApAddEditModulePermissionsDialogComponent,
		AddEditEndUsersAddressesDialogComponent,
		CompanyEndUserInvoicesListComponent,
		CompanyEndUserInvoiceDetailsComponent,
		TileArCreditmemoSettingsComponent,
		CrmSalesArCreditmemoTileComponent,
		SalesInvoiceDetailsOriginalComponent,
		UploadArInvoicesDialogComponent,
		ListIncentivesComponent,
		CommissionTierbaseCommissionCalculationViewComponent,
		AddEditVirtualInvoiceComponent,
		LogoutComponent,
		ListVendorsComponent,
		ListBuyingGroupsComponent,
		ListEndUsersComponent,
		ListTasksComponent,
		IncentivesExportPdfComponent,
		UnmatchedClaimItemDxPopupComponent,
		CloseMultipleSettlementDxDialogComponent,
		AddEditCrmDialogComponent,
		AddEditCrmAddressDialogComponent,
		ApAddEditCrmAddressDialogComponent,
		MessageTrallComponent,
		EuccViewChargebackDocumentsDxPopupComponent,
		UploadChargebackEnduserClaimsDxDialogComponent,
		CardViewCloseMultipleSettlementComponent,
		UploadItemsForPricelistDialogComponent,
		ListValidatedItemsForPricelistDialogComponent,
		ListValidatedArInvoicesDialogComponent,
		ListCreatedVirtualInvoicesDialogComponent,
		ViewInvoiceDetailPopupComponent,
		ViewOrderChangeorderDetailPopupComponent,
		ViewCraditmemoDetailPopupComponent,
		AddEditCurrencyDialogComponent,
		ViewClaimPreviewPopupComponent,
		ViewEndUsersInvoiceDetailPopupComponent,
		PricelistDetailsComponent,
		PricelistApprovalTrailsComponent,
		PricelistEndUsersListingComponent,
		ListValidatedExternalSystemsDataDialogComponent,
		RebatesSettlementApprovalTrailsDialogComponent,
		CompanyEndUserRebatePayoutListComponent,
		PricelistTermsConditionsComponent,
		ExportPricelistTermsConditionsDialogComponent,
		MmeuccClaimApprovalTrailComponent,
		MmeuccMultiClaimApprovalPopupComponent,
		CompanyEndUserInvoiceReportComponent,
		CompanyEndUserInvoiceReportHeaderComponent,
		CompanyEndUserInvoiceReportListingComponent,
		SalesArInvoiceReportComponent,
		SalesArInvoiceReportHeaderComponent,
		SalesArInvoiceReportListingComponent,
		CrmCustomFieldsComponent,
		CompanyCustomFieldsComponent,
		FileUploadDialogComponent,
		ApSalesBillingInvoicesListComponent,
		ApSalesInvoiceDetailsComponent,
		SendEmailFromZohoDialogComponent,
		MmeuccViewClaimQtyDetailPopupComponent,
		ApZohoCardPayemntListComponent,
		ApZohoCardPaymentDetailsDialogComponent,
		ApCustomerZohoSubscriptionsDetailsComponent,
		CloseCurrentSettlementPeriodConfirmDialogComponent,
		UploadItemsForItemTabTpmDetailsDialogComponent,
		ListValidatedItemsForItemTabTPMtDialogComponent,
		UploadClaimsForTpmDialogComponent,
		ListValidatedClaimsForTpmDialogComponent,
		TradePromotionClaimsListComponent
	],
	imports: [
		CommonModule,
		MatDialogModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatDatepickerModule,
		Ng2SearchPipeModule,
		ResharedModule,
		MatMenuModule,
		MatTableModule,
		MatPaginatorModule,
		MatIconModule,
		NgxPermissionsModule,
		MatTooltipModule,
		MainPipesModule,
		MatSelectModule,
		NgxFileDropModule,
		DxButtonModule,
		DxDropDownButtonModule,
		DxHtmlEditorModule,
		DxDataGridModule,
		DxFormModule,
		DxSelectBoxModule,
		DxDateBoxModule,
		DxTabPanelModule,
		DxListModule,
		DxDropDownBoxModule,
		DxTagBoxModule,
		DxTextBoxModule,
		DxNumberBoxModule,
		DxValidatorModule,
		DxValidationSummaryModule,
		DxSwitchModule,
		DxCheckBoxModule,
		DxTooltipModule,
		DxPopoverModule,
		DxScrollViewModule,
		DxLoadPanelModule,
		DxRadioGroupModule,
		DxTextAreaModule,
		DxLookupModule,
		MatFormFieldModule,
		MatSnackBarModule,
		MatProgressSpinnerModule,
		MatSortModule,
		DxTemplateModule,
		DxFilterBuilderModule,
		DxChartModule,
		DxPopupModule,
		DxTreeListModule,
		DxTreeViewModule,
		DxSortableModule,
		DxBoxModule,
		DxDrawerModule,
		DxDateRangeBoxModule,
		DxPivotGridModule,
		DxProgressBarModule,
		DxToolbarModule,
		InputComponentsModule
	],
	entryComponents: [
		BarcodeListDialogComponent,
		ViewPreviousSettlementPeriodDialogComponent,
		ApinvoiceViewDialogComponent,
		ConfirmationDialogComponent,
		TaskDialogComponent,
		ExtendTrailDialogComponent,
		SubscriptionUpgradeDialogComponent,
		PaymentSuccessfulDialogComponent,
		AddEndUsersDialogComponent,
		AddEditUserProfileDialogComponent,
		EditFinancialDialogComponent,
		EditTechnicalDialogComponent,
		EditCompanyInfoDialogComponent,
		ChangePasswordDialogComponent,
		ClaimedItemsListDialogComponent,
		ViewChargeDocumentsDialogComponent,
		BillingInvoiceDetailDialogComponent,
		AddContactEmployeeDialogComponent,
		// ActiveUserDialogComponent,
		TermsAndConditionsDialogComponent,
		ApEditCustomerDialogComponent,
		ShowLicenseFeaturesDialogComponent,
		AddClientToContactsDialogComponent,
		ApAddEditCrmDialogComponent,
		ApAddEditContactEmployeeDialogComponent,
		ApAddEditTaskDialogComponent,
		ApAddEditInxcustomerDialogComponent,
		AddIncentxSupportUserDialogComponent,
		ListConfigurationComponentDialogComponent,
		UploadNewPricelistDialogComponent,
		OverduePaymentDialogComponent,
		ClaimAttachedDocumentsComponent,
		UploadRebateExclusionItemsDialogComponent,
		ViewRebateMembersListDialogComponent,
		EditPaymentApiDialogComponent,
		AddCreditCardToCardNoxDialogComponent,
		IncomingPaymentsDetailsViewComponent,
		IncomingAddEditPaymentComponent,
		AddEditQuotationOrderComponent,
		AddCreditCardToUsaepayDialogComponent,
		IxLoadPanelComponent,
		ViewRebateCreditMemoForPastInvoicesListDialogComponent,
		TileOrderSettingsComponent,
		TileInvoiceSettingsComponent,
		TileQuoteSettingsComponent,
		SalesRepListDialogComponent,
		UploadChargebackEnduserClaimsDialogComponent,
		UploadRebateContractDialogComponent,
		DateRangeGlobalSettingsComponent,
		ApAddEditModulePermissionsDialogComponent,
		AddEditEndUsersAddressesDialogComponent,
		TileArCreditmemoSettingsComponent,
		UploadArInvoicesDialogComponent,
		ListIncentivesComponent,
		CommissionTierbaseCommissionCalculationViewComponent,
		AddEditVirtualInvoiceComponent,
		LogoutComponent,
		ListVendorsComponent,
		ListBuyingGroupsComponent,
		ListEndUsersComponent,
		ListTasksComponent,
		IncentivesExportPdfComponent,
		AddEditCrmDialogComponent,
		AddEditCrmAddressDialogComponent,
		ApAddEditCrmAddressDialogComponent,
		EuccViewChargebackDocumentsDxPopupComponent,
		UploadChargebackEnduserClaimsDxDialogComponent,
		UploadItemsForPricelistDialogComponent,
		ListValidatedItemsForPricelistDialogComponent,
		ListValidatedArInvoicesDialogComponent,
		ListCreatedVirtualInvoicesDialogComponent,
		ListValidatedExternalSystemsDataDialogComponent,
		CompanyEndUserRebatePayoutListComponent,
		MmeuccClaimApprovalTrailComponent,
		CompanyEndUserInvoicesListComponent
	],
	providers: [
		ConfirmationDialogService,
		DatePipe,
		DecimalPipe,
		CurrencyPipe,
		CustomCurrencyPipe,
		CalculateAmountFromListPipe,
		TitleCasePipe,
		CustomNumberNoRoundingPipe,
		FilterListFromListPipe,
		FilterFromListPipe,
		CustomFileSizeFormatPipe,
		ShowUnderscoreToSpacePipe,
		CustomCurrencySymbolPipe,
	],
	exports: [
		TaskDialogComponent,
		ConfirmationDialogComponent,
		TermsAndConditionsDialogComponent,
		IncomingPaymentsDetailsViewComponent,
		IncomingAddEditPaymentComponent,
		ListDeliveryComponent,
		ListPrchseApInvoicesComponent,
		ListArCreditMemoComponent,
		AddEditQuoteComponent,
		AddEditOrderComponent,
		AddEditQuotationOrderComponent,
		IxLoadPanelComponent,
		ViewRebateCreditMemoForPastInvoicesListDialogComponent,
		TileOrderSettingsComponent,
		TileInvoiceSettingsComponent,
		TileQuoteSettingsComponent,
		CrmCustomerDetailComponent,
		CrmCardKnoxListComponent,
		CrmPaymentsListComponent,
		CrmPaymentsDetailsViewComponent,
		CrmAddEditPaymentsComponent,
		CrmSalesRevenueTileComponent,
		CrmSalesVolumeTileComponent,
		CrmSalesVarietyTileComponent,
		CrmSalesProfitTileComponent,
		CrmSalesMarginTileComponent,
		CrmSalesTileDetailsComponent,
		CrmSalesInvoiceTileComponent,
		CrmSalesOrderTileComponent,
		Customer360TileSettingsComponent,
		CrmAddEditQuoteComponent,
		CrmAddEditOrderComponent,
		CrmCardUsaepayListComponent,
		CrmContractRebateTileComponent,
		CrmContractChargebackTileComponent,
		RebatesContractListingComponent,
		SalesDetailsComponent,
		ArCreditMemoDetailsComponent,
		RebatesTierbaseContractComponent,
		RebatesTierbaseReportviewBuyinggroupComponent,
		RebatesTierbaseCalculationViewComponent,
		RebatesTierbaseCloseSettlementListTabComponent,
		RebatesTierbaseCreditMemoListTabComponent,
		PrchseApCreditMemoDetailsComponent,
		PrchseInvoiceDetailsComponent,
		BillbackChargebackContractListingComponent,
		ChargebackContractComponent,
		CcItemsTabComponent,
		CcPricelistTabComponent,
		CcChargebackDocumentsTabComponent,
		DeliveryDetailsComponent,
		SalesRepListDialogComponent,
		DateRangeGlobalSettingsComponent,
		ApAddEditModulePermissionsDialogComponent,
		CompanyEndUserInvoicesListComponent,
		CompanyEndUserInvoiceDetailsComponent,
		TileArCreditmemoSettingsComponent,
		CrmSalesArCreditmemoTileComponent,
		SalesInvoiceDetailsOriginalComponent,
		UploadArInvoicesDialogComponent,
		ListIncentivesComponent,
		CommissionTierbaseCommissionCalculationViewComponent,
		AddEditVirtualInvoiceComponent,
		AddContactEmployeeDialogComponent,
		LogoutComponent,
		AddEditUserProfileDialogComponent,
		ChangePasswordDialogComponent,
		EditCompanyInfoDialogComponent,
		EditFinancialDialogComponent,
		EditTechnicalDialogComponent,
		ListVendorsComponent,
		ListBuyingGroupsComponent,
		ListEndUsersComponent,
		AddEndUsersDialogComponent,
		ListTasksComponent,
		IncentivesExportPdfComponent,
		UnmatchedClaimItemDxPopupComponent,
		CloseMultipleSettlementDxDialogComponent,
		AddEditCrmDialogComponent,
		AddEditCrmAddressDialogComponent,
		AddClientToContactsDialogComponent,
		ApAddEditCrmDialogComponent,
		ApAddEditInxcustomerDialogComponent,
		ApAddEditCrmAddressDialogComponent,
		ApAddEditContactEmployeeDialogComponent,
		ApAddEditTaskDialogComponent,
		MessageTrallComponent,
		EuccViewChargebackDocumentsDxPopupComponent,
		UploadChargebackEnduserClaimsDxDialogComponent,
		CardViewCloseMultipleSettlementComponent,
		UploadNewPricelistDialogComponent,
		ViewRebateMembersListDialogComponent,
		UploadRebateContractDialogComponent,
		ViewPreviousSettlementPeriodDialogComponent,
		ApinvoiceViewDialogComponent,
		AddIncentxSupportUserDialogComponent,
		UploadItemsForPricelistDialogComponent,
		ListValidatedItemsForPricelistDialogComponent,
		ExtendTrailDialogComponent,
		ApEditCustomerDialogComponent,
		BarcodeListDialogComponent,
		AddEditEndUsersAddressesDialogComponent,
		EditPaymentApiDialogComponent,
		ListConfigurationComponentDialogComponent,
		AddCreditCardToUsaepayDialogComponent,
		AddCreditCardToCardNoxDialogComponent,
		PaymentSuccessfulDialogComponent,
		BillingInvoiceDetailDialogComponent,
		ShowLicenseFeaturesDialogComponent,
		SubscriptionUpgradeDialogComponent,
		OverduePaymentDialogComponent,
		ListValidatedArInvoicesDialogComponent,
		ListCreatedVirtualInvoicesDialogComponent,
		ViewInvoiceDetailPopupComponent,
		ViewOrderChangeorderDetailPopupComponent,
		ViewCraditmemoDetailPopupComponent,
		AddEditCurrencyDialogComponent,
		ViewClaimPreviewPopupComponent,
		ViewEndUsersInvoiceDetailPopupComponent,
		PricelistDetailsComponent,
		PricelistApprovalTrailsComponent,
		PricelistEndUsersListingComponent,
		ListValidatedExternalSystemsDataDialogComponent,
		RebatesSettlementApprovalTrailsDialogComponent,
		CompanyEndUserRebatePayoutListComponent,
		PricelistTermsConditionsComponent,
		ExportPricelistTermsConditionsDialogComponent,
		MmeuccClaimApprovalTrailComponent,
		CompanyEndUserInvoiceReportComponent,
		CompanyEndUserInvoiceReportHeaderComponent,
		CompanyEndUserInvoiceReportListingComponent,
		SalesArInvoiceReportComponent,
		SalesArInvoiceReportHeaderComponent,
		SalesArInvoiceReportListingComponent,
		CrmCustomFieldsComponent,
		CompanyCustomFieldsComponent,
		FileUploadDialogComponent,
		ApSalesBillingInvoicesListComponent,
		ApSalesInvoiceDetailsComponent,
		SendEmailFromZohoDialogComponent,
		MmeuccViewClaimQtyDetailPopupComponent,
		ApZohoCardPayemntListComponent,
		ApZohoCardPaymentDetailsDialogComponent,
		ApCustomerZohoSubscriptionsDetailsComponent,
		CloseCurrentSettlementPeriodConfirmDialogComponent,
		UploadItemsForItemTabTpmDetailsDialogComponent,
		ListValidatedItemsForItemTabTPMtDialogComponent,
		UploadClaimsForTpmDialogComponent,
		ListValidatedClaimsForTpmDialogComponent,
		TradePromotionClaimsListComponent
	]
})
export class CustomComponentsModule { }
