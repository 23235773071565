<dx-popup [showTitle]="true" [width]="popupWidth" [height]="popupHeight" [title]="titleText" [dragEnabled]="true"
	[hideOnOutsideClick]="false" [showCloseButton]="true" [(visible)]="isVisiblePopup" (onHidden)="doHiddenPopop($event)"
	[wrapperAttr]="{ class: 'popupToolbarPadding toolbarbtnbgchangePopupWrapper' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			width="100%" height="100%">
			<div class="orderDetail orderPdetails" *ngIf="isViewSubmitClaimBtn && contractName">
				<div class="custDetail mrgB10">
					<h4 class="graySubHead textWrap">Contract Name :
						<strong>{{contractName || ''}} </strong>
					</h4>
				</div>
			</div>
			<div class="tabbing cf">
				<ul>
					<li (click)="doChangeTab('ignored_items')" [ngClass]="{active: currentTab === 'ignored_items'}">
						<a>Rejected Item(s)</a>
					</li>
					<li (click)="doChangeTab('succeed_items')" [ngClass]="{active: currentTab === 'succeed_items'}">
						<a>Successful Uploaded Item(s)</a>
					</li>
				</ul>
			</div>
			<div *ngIf="isShowDatagrid">
				<dx-data-grid #mismatchClaimItemDataGridContainer id="gridContainer" [dataSource]="dxDataSource"
					[remoteOperations]="false" [hoverStateEnabled]="true" [showBorders]="true" [columnAutoWidth]="true"
					[allowColumnResizing]="true" [wordWrapEnabled]="true" [height]="590"
					(onToolbarPreparing)="doToolbarPreparing($event)">
					<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
					<dxo-filter-row [visible]="true"></dxo-filter-row>
					<dxo-filter-panel [visible]="false"></dxo-filter-panel>
					<dxo-header-filter [visible]="false"></dxo-header-filter>
					<dxi-column caption="Row No." dataField="rowNum" cellTemplate="cellTemplateRowNum"
						[visible]="isViewSubmitClaimBtn" dataType="string">
						<div *dxTemplate="let element of 'cellTemplateRowNum'">
							{{element.data.rowNum || ''}}
						</div>
					</dxi-column>
					<dxi-column caption="Item" dataField="itemname" cellTemplate="cellTemplateItemName"
						[visible]="!isViewSubmitClaimBtn" dataType="string">
						<div *dxTemplate="let element of 'cellTemplateItemName'">
							{{element.data.itemname || ''}}
						</div>
					</dxi-column>
					<dxi-column caption="Note" dataField="message" cellTemplate="cellTemplateMessage"
						[visible]="!isViewSubmitClaimBtn" dataType="string">
						<div *dxTemplate="let element of 'cellTemplateMessage'">
							{{element.data.message || ''}}
						</div>
					</dxi-column>
					<dxi-column caption="Customer Code" dataField="customer_code" cellTemplate="cellTemplateCustomerCode"
						[visible]="isViewSubmitClaimBtn" dataType="string">
						<div *dxTemplate="let element of 'cellTemplateCustomerCode'">
							{{element.data.customer_code || ''}}
						</div>
					</dxi-column>
					<dxi-column caption="Shortpay Code" dataField="shortpay_code" cellTemplate="cellTemplateShortPayCode"
						[visible]="isViewSubmitClaimBtn" dataType="string">
						<div *dxTemplate="let element of 'cellTemplateShortPayCode'">
							{{element.data.shortpay_code || ''}}
						</div>
					</dxi-column>
					<dxi-column caption="PriceList No." dataField="pricelist_no" cellTemplate="cellTemplatePriceListNo"
						[visible]="isViewSubmitClaimBtn" dataType="string">
						<div *dxTemplate="let element of 'cellTemplatePriceListNo'">
							{{element.data.pricelist_no || ''}}
						</div>
					</dxi-column>
					<dxi-column caption="End-User" dataField="end_user" cellTemplate="cellTemplateEndUser"
						[visible]="isViewSubmitClaimBtn" dataType="string">
						<div *dxTemplate="let element of 'cellTemplateEndUser'">
							{{element.data.end_user || ''}}
						</div>
					</dxi-column>
					<dxi-column caption="Item" dataField="item" cellTemplate="cellTemplateItem"
						[visible]="isViewSubmitClaimBtn" dataType="string">
						<div *dxTemplate="let element of 'cellTemplateItem'">
							{{element.data.item || ''}}
						</div>
					</dxi-column>
					<dxi-column caption="UOM" dataField="uom" cellTemplate="cellTemplateUOM"
						[visible]="isViewSubmitClaimBtn">
						<div *dxTemplate="let element of 'cellTemplateUOM'" dataType="string">
							{{element.data.uom | uppercase}}
						</div>
					</dxi-column>
					<dxi-column caption="Qty." dataField="quantity" cellTemplate="cellTemplateQty"
						[visible]="isViewSubmitClaimBtn" dataType="number">
						<div *dxTemplate="let element of 'cellTemplateQty'">
							{{element.data.quantity || '0'}}
						</div>
					</dxi-column>
					<dxi-column caption="Amount" dataField="amount" [visible]="isViewSubmitClaimBtn" dataType="number">
						<dxo-format type="currency" [precision]="decimalPointForCurrency">
						</dxo-format>
						<div *dxTemplate="let element of 'cellTemplateAmount'">
							{{element.data.amount || 0 | customCurrencyPipe}}

						</div>
					</dxi-column>
					<dxi-column caption="Remark" dataField="remark" cellTemplate="cellTemplateRemark"
						[visible]="isViewSubmitClaimBtn && currentTab === 'ignored_items'" dataType="string">
						<div *dxTemplate="let element of 'cellTemplateRemark'">
							{{element.data.remark || ''}}
						</div>
					</dxi-column>
					<dxo-summary>
						<dxi-total-item column="rowNum" [customizeText]="customizeGridRowCountText" summaryType="count">
						</dxi-total-item>
					</dxo-summary>
				</dx-data-grid>
				<!-- <div class="cols12 feildCol mrgT20" *ngIf="isViewSubmitClaimBtn">
					<dx-button class="primaryBtn alignRight" id="button" text="Submit Claim" type="normal"
						stylingMode="contained" (click)="doSubmitClaim()">
					</dx-button>
					<dx-button class="secondaryBtn alignRight mrgR10" id="button" text="Cancel Claim" type="normal"
						stylingMode="contained" (click)="doCancelClaim()">
					</dx-button>
				</div> -->
			</div>
		</dx-scroll-view>
	</div>
	<dxi-toolbar-item toolbar="bottom" location="after" cssClass="mrgT10" [visible]="isViewSubmitClaimBtn">
		<div *dxTemplate>
			<dx-button class="primaryBtn alignRight" id="button" text="Submit Claim" type="normal" stylingMode="contained"
				(click)="doSubmitClaim()">
			</dx-button>
			<dx-button class="secondaryBtn alignRight mrgR10" id="button" text="Cancel Claim" type="normal"
				stylingMode="contained" (click)="doCancelClaim()">
			</dx-button>
		</div>
	</dxi-toolbar-item>
</dx-popup>