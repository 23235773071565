import { IMG_ADD_FILE_SVG, MSG_ERROR_MESSAGE, ServerEntity, ServerMethods } from '@app/constants-enums/constants';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { Subscription, Observable, Subject } from 'rxjs';
import saveAs from 'file-saver';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ContractTypeValEnum, ErpTypeValEnum } from '@app/constants-enums/enums';
import { ConfirmationDialogService } from '@app/custom-components/confirmation-dialog/confirmation-dialog.service';
import { PageOptions } from '@app/models/common.model';
import { Guid } from '@app/models/guid';
import { AppCommonSrvc } from '@app/services/app-common-srvc/app-common-srvc.service';
import { BillbackService } from '@app/services/contract-service/billback/billback.service';
import { MinMaxEndUsersChargebackContractService } from '@app/services/contract-service/billback/min-max-end-users-chargeback-contract.service';
import { DataService } from '@app/services/data.service';
import { FileUploadService } from '@app/services/file-upload.service';
import { GlobalStateService } from '@app/services/global-state/global-state.service';
import { LoaderService } from '@app/services/loaderservices/loader.service';
import { LoginService } from '@app/services/login.service';
import { RestApiService } from '@app/services/rest-service/rest-api.service';
import { environment } from '@environments/environment';
import { DxDataGridComponent } from 'devextreme-angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { exportDataGrid } from "devextreme/excel_exporter";
import { MinMaxEndUsersChargebackItemTabModel, ChargeDocumentsTabModel, MinMaxEndUsersChargebackContractModelUI } from "@app/models/min-max-end-users-chargeback-contract.model";
import { UploadChargebackEnduserClaimsDialogComponent } from '@app/custom-components/upload-chargeback-enduser-claims-dialog/upload-chargeback-enduser-claims-dialog.component';
import { CustomCurrencyPipe } from '@app/pipes/custom-currency/custom-currency.pipe';
declare const ExcelJS: ExcelJS;
import * as _ from 'underscore';
import * as moment from 'moment';
import { BillbackContractService } from '@app/services/contract-service/billback/billback-contract.service';
@Component({
	selector: 'app-mmeucc-chargeback-documents-tab',
	templateUrl: './mmeucc-chargeback-documents-tab.component.html',
	styleUrls: ['./mmeucc-chargeback-documents-tab.component.css']
})
export class MmeuccChargebackDocumentsTabComponent implements OnInit, OnDestroy, OnChanges {
	@Input() itemTabDataList: any = [];
	@Input() chargeBackDocumentsTabDatalist: any[] = [];
	@ViewChild("chargeDocumentsTabDataGridContainer", {}) chargeDocumentsTabDataGridContainer: DxDataGridComponent;
	@Output() saveChargeDocumentsTabDataEvent = new EventEmitter<string>();

	private getUomLstSbsn: Subscription;
	private dataInReqSubscription: Subscription;
	private dataOutReqSubscription: Subscription;
	public erp_type: string;
	public isQBOnlineErpUser: boolean = false;
	public isQBErpUser: boolean = false;
	public permsn: any = [];
	public chargeDocumentsTabModel = new ChargeDocumentsTabModel();
	public editChargebackGuid: string;
	public isDisablePermission: boolean = false;
	public isCurrentTabPermission: string;
	public documentFileData: any;
	public claimDocumentUploadFile: any;
	public productSearchItemType: string;
	public listOfUoms: any[] = [];
	public listOfUomGroups: any[] = [];
	public getUomGroupLstSbsn: Subscription;
	public filterText: string = '';
	private filterValueChangeSbsn: Observable<any>;
	public isDisabledClearBtn: boolean = true;
	public instanceClearBtn: any;
	public chargeDocumentsTabDataSource: any;
	public viewDocumentDataGrid: boolean = false;
	public isUploadExcelCsvFileItmGrpTab: boolean = false;
	public successedListOfCsvRows: any = [];
	public ignoredListOfCsvRows: any = [];
	public noOfUnmatchedClaim: any;
	public noOfMatchedClaim: any;
	public noOfTotaClaim: any;
	public listOfStatuses: any[] = this._MinMaxEndUsersChargebackContractService.doGetListofClaimApprovalStatus();
	public listOfHeaderFilterStatuses: any[] = this._MinMaxEndUsersChargebackContractService.doGetListofClaimApprovalStatus();
	// Matrial design object
	public pageOptions: PageOptions = new PageOptions();
	public guid = new Guid();
	private dataOutShortPayReqSubscription: Subscription;
	@Input() minMaxEndUsersChargebackContractModelUI = new MinMaxEndUsersChargebackContractModelUI();
	loadUploadEndUserDxDialogSubject: Subject<any> = new Subject();
	loadViewChargebackDocDxDialogSubject: Subject<any> = new Subject();
	public decimalPointForCurrency: number;
	public isShowMinMaxChargebackClaimPreviewPopup: boolean = false;
	public isShowManualAdjustmentPopup: boolean = false;
	public minMaxCharebackClaimPreviewProps: any;
	public manualAdjustmentProps: any;
	public chargeback_period: any;
	// public chargeback_period_start_date_end_date: [Date, Date] = [null, null];
	// public chargeback_period_start_date: Date = null;
	// public chargeback_period_end_date: Date = null;
	public hide_quantity: boolean = true;
	public cut_off_date: Date = null;
	public endOfMonthDate: any = null;
	public minDateRang: any = null;
	public maxDateRang: any = null;
	public transaction_date: Date = new Date();

	@ViewChild("enduserDataGrid", {}) enduserDataGrid: DxDataGridComponent;
	@ViewChild("resonCodeDataGrid", {}) resonCodeDataGrid: DxDataGridComponent;
	public enduserDataSource: any[] = [];
	public enduserGridBoxValue: any[] = [];
	public arrSelectedEnduserList: any = [];
	public isEnduserDropDownBoxOpened: boolean = false;
	public enduserGridBoxColumns = [
		{ dataField: 'code', caption: 'Code' },
		{ dataField: 'name', caption: 'Name' },
		{ dataField: 'guid', caption: 'Guid', visible: false },
		{ dataField: 'pricelistname', caption: 'Pricelist', visible: false },
		{ dataField: 'pricelistno', caption: 'Pricelist No', visible: false },
	];
	public reasonCodeGridBoxColumns = [
		{ dataField: 'guid', caption: 'Guid', visible: false },
		{ dataField: 'value', caption: 'Value', visible: false },
		{ dataField: 'type', caption: 'Type', visible: false },
		{ dataField: 'erpcode', caption: 'ERP Code' },
		{ dataField: 'label', caption: 'Content' },
	];
	public lengthSelectedEndusers: any;
	public isCustomerChargebackbasis: boolean = false;
	public listOfCustomReason: any = [];
	public listOfHeaderClaimType: any = [
		{ value: undefined || '' || 'null' || null, text: 'Regular' },
		{ value: 'manual_adjustment', text: 'Manual Adjustment' },
	];
	public listOfHeaderCustomReason: any = [];
	public reasonCodeGridBoxValue: any;
	private getListOfReasonSubscription: Subscription;
	public isReasonCodeDropDownBoxOpened: boolean = false;
	public listOfActions: any[] = [
		{
			btn_code: 'ADD_CHARGEBACK',
			btn_name: 'Add Chargeback',
			btn_image: IMG_ADD_FILE_SVG,
			icon: ' ' + IMG_ADD_FILE_SVG,
			visible: true,
			disabled: false,
		},
		{
			btn_code: 'ADD_MANUAL_ADJUSTMENT',
			btn_name: 'Add Manual Adjustment',
			btn_image: IMG_ADD_FILE_SVG,
			icon: ' ' + IMG_ADD_FILE_SVG,
			visible: true,
			disabled: false,
		}];
	public claimedItemslstProps: any;
	public isShowClaimedItemlstPopup: boolean = false;
	public isShowUploadChargebackEnduserClaimsPopup: boolean = false;
	public uploadChargebackEndUserClaimsProps: any;
	constructor(
		private ref: ChangeDetectorRef,
		private _Router?: Router,
		private _ToastrService?: ToastrService,
		private _PermissionsService?: NgxPermissionsService,
		private _LoginService?: LoginService,
		private _LoaderService?: LoaderService,
		private _RestApiService?: RestApiService,
		private _ConfirmationDialogService?: ConfirmationDialogService,
		private _DataService?: DataService,
		private _MinMaxEndUsersChargebackContractService?: MinMaxEndUsersChargebackContractService,
		private _FileUploadService?: FileUploadService,
		private _GlobalStateService?: GlobalStateService,
		public _AppCommonSrvc?: AppCommonSrvc,
		private _CustomCurrencyPipe?: CustomCurrencyPipe,
		public _BillbackService?: BillbackService,
		public _BillbackContractService?: BillbackContractService,
	) {
		this.displayExprEnduserName = this.displayExprEnduserName.bind(this);
	}

	ngOnInit() {
		this.decimalPointForCurrency = this._LoginService.decimalPointForCurrency;
		this.editChargebackGuid = this._MinMaxEndUsersChargebackContractService.getEditMinMaxEndUsersChargebackGuid();
		this.isQBOnlineErpUser = this._BillbackService.getQbOnlineErpUser();
		this.isQBErpUser = this._BillbackService.getQbErpUser();
		// Get current permissions
		const permissions = this._PermissionsService.getPermissions();
		for (const per in permissions) {
			this.permsn.push(per);
		}

		// product_search logic type (item/price)
		this.productSearchItemType = this._BillbackService.getProductSearchType();

		if (this.permsn.indexOf('INCENTIVE_BILLBACKCHARGEBACK_MODIFY') === -1) {
			this.isDisablePermission = true;
			this.isCurrentTabPermission = 'INCENTIVE_BILLBACKCHARGEBACK_MODIFY';
		}
		this.preInit();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.chargeBackDocumentsTabDatalist) {
			const currentValue = changes.chargeBackDocumentsTabDatalist.currentValue;
			if (currentValue) {
			}
		}
	}

	ngOnDestroy() {
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		if (this.dataOutReqSubscription) {
			this.dataOutReqSubscription.unsubscribe();
		}
		if (this.dataOutShortPayReqSubscription) {
			this.dataOutShortPayReqSubscription.unsubscribe();
		}
		if (this.getListOfReasonSubscription) {
			this.getListOfReasonSubscription.unsubscribe();
		}
	}

	preInit() {
		this.editChargebackGuid = this._MinMaxEndUsersChargebackContractService.getEditMinMaxEndUsersChargebackGuid();
		// if (this.chargeBackDocumentsTabDatalist && this.chargeBackDocumentsTabDatalist.length > 0) {
		// 	this.chargeDocumentsTabDataSource = this.chargeBackDocumentsTabDatalist;
		// }
		// if (this.minMaxEndUsersChargebackContractModelUI.hasOwnProperty('month_filter')) {
		// 	this.chargeback_period = this.minMaxEndUsersChargebackContractModelUI?.month_filter;
		// }
		this.isCustomerChargebackbasis = false;
		if (this.minMaxEndUsersChargebackContractModelUI.chargebackBasis == 'customers') {
			this.isCustomerChargebackbasis = true;
		}
		this.fetchListOfUoms();
		this.fetchListOfUomGroups();
		this.fetchListOfCustomReason();
		this.getChargeBackDocumentTabData();
		this.loadEnduserDataList();
		setTimeout(() => {
			this.filterValueChangeSbsn = this.chargeDocumentsTabDataGridContainer.filterValueChange.asObservable();
			this.filterValueChangeSbsn.subscribe((result) => {
				this.getFilterText();
			});
		}, 60);
	}

	getChargeBackDocumentTabData() {
		if (this.editChargebackGuid) {
			this._LoaderService.show();
			const formData = new FormData();
			const viewFields = ['guid', 'ixcode', 'chargeback_period', 'documentDate', 'documentTotal', 'created_by_name', 'claimedStatus', 'claimtype', 'cut_off_date', 'transaction_date', 'endusersList', 'remark', 'reason_code_guid']
			formData.append('usr', this._LoginService.loginUser.user);
			formData.append('token', this._LoginService.loginUser.token);
			formData.append('contractguid', this.editChargebackGuid);
			formData.append('entity', ServerEntity.CHARGEBACKDOC);
			formData.append('method', ServerMethods.DX_GET_ENTITY_DATA);
			formData.append('contracttype', ContractTypeValEnum.MINMAXENDUSERSCHARGEBACKCONTRACTS);
			formData.append('view_fields', JSON.stringify(viewFields));
			this.dataOutReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
				next: (response) => {
					this.viewDocumentDataGrid = true;
					if (response.flag && response.data) {
						this.chargeBackDocumentsTabDatalist = JSON.parse(JSON.stringify(response.data)) || [];
						this.chargeDocumentsTabDataSource = JSON.parse(JSON.stringify(this.chargeBackDocumentsTabDatalist));
						if (this.chargeBackDocumentsTabDatalist.length > 0) {
							this._GlobalStateService.notifyDataChangedDuplicate('DISABLED_DISTRIBUTION_CHANNEL_' + ContractTypeValEnum.MINMAXENDUSERSCHARGEBACKCONTRACTS, { isDisabled: true });
						} else {
							this._GlobalStateService.notifyDataChangedDuplicate('DISABLED_DISTRIBUTION_CHANNEL_' + ContractTypeValEnum.MINMAXENDUSERSCHARGEBACKCONTRACTS, { isDisabled: false });
						}
					} else {
						this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
					}
					this._LoaderService.hide();
				}, error: (error) => {
					console.error('error', error);
					this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
					this._LoaderService.hide();
				}
			});
		} else {
			this._GlobalStateService.notifyDataChangedDuplicate('DISABLED_DISTRIBUTION_CHANNEL_' + ContractTypeValEnum.MINMAXENDUSERSCHARGEBACKCONTRACTS, { isDisabled: false });
			this.chargeBackDocumentsTabDatalist = [];
			this.chargeDocumentsTabDataSource = [];
		}
	}

	addChargebackDocumentsAgreementTabDetails() {
		if (this.checkValidation()) {
			// this.getAgreementTabListData();
			if (this.itemTabDataList.length > 0) {
				this.claimedItemslstProps = {
					productList: JSON.parse(JSON.stringify(this.itemTabDataList)),
				}
				this.isShowClaimedItemlstPopup = false;
				setTimeout(() => {
					this.isShowClaimedItemlstPopup = true;
				}, 20);
			}
		}

	}

	// Clear input field changeback document tab data
	clearChargebackDocumentsAgreementTabDetails() {
		this.chargeDocumentsTabModel = new ChargeDocumentsTabModel();
		this.documentFileData = '';
	}

	// View chargeback document dialog
	viewChargeBackDocumentDetails(chargeBackDocumentDetails) {
		// const dialogData = {
		// 	claimId: chargeBackDocumentDetails.ixcode,
		// 	callFrom: 'en-users',
		// 	itemTabDataList: this.itemTabDataList,
		// 	minMaxEndUsersChargebackContractModelUI: this.minMaxEndUsersChargebackContractModelUI,
		// 	chargeBackDocumentDetails: JSON.parse(JSON.stringify(chargeBackDocumentDetails)),
		// 	billBaBackDocumentDetails: JSON.parse(JSON.stringify(chargeBackDocumentDetails)),
		// }
		//this.loadViewChargebackDocDxDialogSubject.next(dialogData);
		const dialogData = {
			callFrom: 'view',
			chargeback_period: chargeBackDocumentDetails.chargeback_period,
			claimguid: chargeBackDocumentDetails.guid,
			//	selectedEndUserList: selectedEndUserList,
			calledFrom: 'min-max-chargeback-document',
			minMaxEndUsersChargebackContractModelUI: this.minMaxEndUsersChargebackContractModelUI,
		}
		this.minMaxCharebackClaimPreviewProps = dialogData;
		this.isShowMinMaxChargebackClaimPreviewPopup = false;
		setTimeout(() => {
			this.isShowMinMaxChargebackClaimPreviewPopup = true;
		}, 300);

	}

	downloadChargeBackDocument(chargeBackDocumentDetails) {
		const documentURL = chargeBackDocumentDetails.documentURL;
		const documentNewURLArr = documentURL.split('-');
		if (documentNewURLArr && documentNewURLArr.length > 0) {
			const docId = documentNewURLArr[0];
			const docDate = documentNewURLArr[1];
			const docCode = documentNewURLArr[2];
			const finalUrl = environment.URL_SERVER + '/getDocument.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&docdate=' + docDate + '&doccode=' + docCode + '&doctype=chargebackDoc';
			window.location.href = finalUrl;
		}
	}

	downloadBillBackDocument(billBackDocumentDetails) {
		const documentURL = billBackDocumentDetails.documentURL;
		const documentNewURLArr = documentURL.split('-');
		if (documentNewURLArr && documentNewURLArr.length > 0) {
			const docId = documentNewURLArr[0];
			const docDate = documentNewURLArr[1];
			const docCode = documentNewURLArr[2];
			const finalUrl = environment.URL_SERVER + '/getDocument.php?usr=' + this._LoginService.loginUser.user + '&docid=' + docId + '&docdate=' + docDate + '&doccode=' + docCode + '&doctype=billbackDoc';
			window.location.href = finalUrl;
		}

	}

	// Method used to Get Same Text from Filter Panel
	private getFilterText() {
		this.filterText = '';
		this.isDisabledClearBtn = true;
		this.chargeDocumentsTabDataGridContainer.filterPanel.customizeText = (e: any) => {
			this.filterText = e.text;
			if (this.filterText) {
				this.isDisabledClearBtn = false;
			} else {
				this.isDisabledClearBtn = true;
			}
		};
		setTimeout(() => {
			this.updateStatesClearBtn();
		}, 600);
	}

	// Method used to Enabled/Disabled Clear Button
	private updateStatesClearBtn() {
		if (this.instanceClearBtn !== null) {
			setTimeout(() => {
				this.instanceClearBtn.option({ disabled: this.isDisabledClearBtn });
			}, 10);
		}
	}

	public doOpenFilterBuilder() {
		this.chargeDocumentsTabDataGridContainer.instance.option("filterBuilderPopup.visible", true);
	}
	// Method used to Display Toolbar for Export Data, Filter Data
	public doToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: 'after',
				template: 'templateFilterText',
			},
			{
				location: 'after',
				widget: 'dxButton',
				options: {
					disabled: this.isDisabledClearBtn,
					text: "Clear",
					icon: "cleariconix",
					onInitialized: (args: any) => {
						this.instanceClearBtn = args.component;
					},
					onClick: () => {
						this.chargeDocumentsTabDataGridContainer.instance.clearFilter();
					}
				}
			},
			// {
			// 	location: 'after',
			// 	widget: 'dxButton',
			// 	options: {
			// 		icon: 'xlsxfile',
			// 		text: "Export",
			// 		onClick: (event: any) => {
			// 			this.doExportToExcel(event);
			// 		}
			// 	}
			// }
		);
	}

	// Method used to Export Excelsheet for Lead,Prospect,Customers,Tasks
	private doExportToExcel(e) {
		if (this.chargeDocumentsTabDataGridContainer.instance.totalCount() <= 0) {
			this._ToastrService.info('No record(s) available', 'Info', { closeButton: true, tapToDismiss: true });
			return;
		}
		const workbook = new ExcelJS.Workbook();
		let worksheet;
		let fileName = '';
		fileName = 'EndUsers_Chargeback_Documents.xlsx';
		worksheet = workbook.addWorksheet('Documents');

		exportDataGrid({
			worksheet: worksheet,
			component: this.chargeDocumentsTabDataGridContainer.instance,
			autoFilterEnabled: true,
			keepColumnWidths: true,
			customizeCell: (options) => {
				const { gridCell, excelCell } = options;
				if (gridCell.rowType === "data") {
					if (gridCell.column.dataField === 'documentTotal') {
						excelCell.value = '';
						if (gridCell.value) {
							excelCell.value = this._CustomCurrencyPipe.transform(gridCell.value || 0);
						}
					}
				}
			}
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName);
			});
		});
		e.cancel = true;
	}

	public doOnRowUpdatedChargeDocumentsTab(event) {
		if (this.editChargebackGuid) {
			this.saveChargeDocumentsTabDataEvent.emit('save');
		}
	}

	public doDeleteChargeDocumentsTab(rowData) {
		this._ConfirmationDialogService.confirmDialog().afterClosed().subscribe(isDelete => {
			if (isDelete) {
				// const rowIndex = this.chargeDocumentsTabDataSource.findIndex(ce => ce.guid === rowData.guid);
				// if (rowIndex !== -1) {
				// 	this.chargeDocumentsTabDataSource.splice(rowIndex, 1);
				// 	this.chargeBackDocumentsTabDatalist.splice(rowIndex, 1);
				// 	if (this.editChargebackGuid) {
				this.doCallDeleteChargebackDocument(rowData);
				// 		}
				// 	}
			}
		});
	}

	public doCallDeleteChargebackDocument(objItem) {
		const formdata = new FormData();
		formdata.append('usr', this._LoginService.loginUser.user);
		formdata.append('token', this._LoginService.loginUser.token);
		formdata.append('action', 'delete');
		formdata.append(ServerEntity.CHARGEBACKDOC, JSON.stringify([objItem.guid]));
		if (this.dataInReqSubscription) {
			this.dataInReqSubscription.unsubscribe();
		}
		this._LoaderService.show();
		this.dataInReqSubscription = this._RestApiService.doDataInFormDataReq(formdata).subscribe({
			next: (response) => {
				this._LoaderService.hide();
				if (response.flag) {
					let msg = 'Recorded Removed successfully';
					this._ToastrService.success(response.message || msg, 'Success', { closeButton: true, tapToDismiss: true });
					// if (response && response.data && response.data['minmaxenduserschargebackcontracts'] && response.data['minmaxenduserschargebackcontracts'].length == 1) {
					// 	const itemTabDataList = response.data['minmaxenduserschargebackcontracts'][0]['chargebackItems'] || [];
					// 	this.itemTabDataList = itemTabDataList;
					// 	this._MinMaxEndUsersChargebackContractService.setItemTabDataList(this.itemTabDataList);
					// 	this.saveChargeDocumentsTabDataEvent.emit('claimItemChargeback');
					// }
					this.getChargeBackDocumentTabData();
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Info!', { closeButton: true, tapToDismiss: true });
					this._LoaderService.hide();
				}
			}, error: (error) => {
				this._LoaderService.hide();
				this._ToastrService.error(error.message || MSG_ERROR_MESSAGE, 'Error', { closeButton: true, tapToDismiss: true });
			}
		});
	}

	public doOnSavedChargeDocumentsTab() {
		if (this.editChargebackGuid) {
			this.saveChargeDocumentsTabDataEvent.emit('save');
		}
	}

	// Method used to Display Uom Name in uppercase
	public getDisplayExprUomName(item) {
		if (!item) {
			return "";
		}
		return item.name.toString().toUpperCase();
	}

	// Upload document
	uploadDocument(files: File[]) {
		if (files.length === 1) {
			// file type verify
			const fileType = files[0].type;
			if (fileType !== '.doc' && fileType !== '.docx' && fileType !== 'application/msword' && fileType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
				&& fileType !== '.csv' && fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && fileType !== 'application/vnd.ms-excel' && fileType !== 'application/pdf') {
				this._ToastrService.info('File extension not valid', 'Info!', { closeButton: true, tapToDismiss: true });
				this.chargeDocumentsTabModel.documentURL = '';
				return false;
			}
			// file size convert KB
			const fileSize = Math.round(files[0].size / 5120);
			if (fileSize > 5120) {
				this._ToastrService.info('File size should not greater then 5 MB', 'Info!', { closeButton: true, tapToDismiss: true });
				return false;
			}
			this.documentFileData = files[0];
			this.chargeDocumentsTabModel.documentURL = files[0].name;
			// this._FileUploadService.upload(files);

		}
	}
	// Method used to get Uom List
	private fetchListOfUoms() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uom');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name']));
		formData.append('is_dropdown', 'true');

		// this._LoaderService.show();
		if (this.getUomLstSbsn) {
			this.getUomLstSbsn.unsubscribe();
		}
		this.getUomLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				// this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUoms = response.data;
					}
				}
			}, error: (error) => {
				// this._LoaderService.hide();
			}
		});
	}

	// Method used to get Uo Group List
	private fetchListOfUomGroups() {
		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('method', 'getEntityData');
		formData.append('entity', 'uomgroups');
		formData.append('view_fields', JSON.stringify(['absentry', 'code', 'name', 'baseuom', 'uomgroupdefinitioncollection']));
		formData.append('is_dropdown', 'true');

		// this._LoaderService.show();
		if (this.getUomGroupLstSbsn) {
			this.getUomGroupLstSbsn.unsubscribe();
		}
		this.getUomGroupLstSbsn = this._RestApiService.doDataOutReqFormData(formData).subscribe({
			next: (response) => {
				// this._LoaderService.hide();
				if (response) {
					if (response.flag) {
						this.listOfUomGroups = response.data;
					}
				}
			}, error: (error) => {
				// this._LoaderService.hide();
			}
		});
	}

	saveClaimedItems(claimedItems) {
		this._LoaderService.show();

		const item = {
			guid: this.guid.newGuid(),
			documentURL: this.chargeDocumentsTabModel.documentURL,
			documentReferenceNumber: this.chargeDocumentsTabModel.documentReferenceNumber,
			documentDate: '',
			claimedUserNote: this.chargeDocumentsTabModel.claimedUserNote || '',
			claimedStatus: '',
			claimedItems: claimedItems,
		}
		const arrChargebackDocumentsTabList = [];
		arrChargebackDocumentsTabList.push(item);
		// const reqFormData = 'usr=' + this._LoginService.loginUser.user + '&token=' + this._LoginService.loginUser.token + '&contractguid=' + this.billBackChargeBackRequest.guid + '&method=claimChargeback' + '&chargebackDocs=' + encodeURIComponent(JSON.stringify(arrChargebackDocumentsTabList))
		// this.dataInReqSubscription = this._RestApiService.doDataOutReq((reqFormData)).subscribe(

		const formData = new FormData();
		formData.append('usr', this._LoginService.loginUser.user);
		formData.append('token', this._LoginService.loginUser.token);
		formData.append('contractguid', this.editChargebackGuid);
		formData.append('documentUploadFile', this.documentFileData);
		formData.append('claimDocumentUploadFile', this.claimDocumentUploadFile);
		formData.append('chargebackDocs', (JSON.stringify(arrChargebackDocumentsTabList)));
		formData.append('method', 'claimChargeback');
		formData.append('contracttype', 'minmaxenduserschargebackcontracts');

		this.dataInReqSubscription = this._RestApiService.doDataOutReqFormData((formData)).subscribe({
			next: (response) => {
				if (response.flag) {
					this.itemTabDataList = response.data.itemSold;
					this.chargeBackDocumentsTabDatalist = response.data.chargebackDocuments;
					this.chargeDocumentsTabDataSource = JSON.parse(JSON.stringify(this.chargeBackDocumentsTabDatalist));
					if (this.editChargebackGuid) {
						this._MinMaxEndUsersChargebackContractService.setItemTabDataList(this.itemTabDataList);
						this.saveChargeDocumentsTabDataEvent.emit('claimItemChargeback');
					}
					this.clearChargebackDocumentsAgreementTabDetails();
					this._ToastrService.success('Document Added Successfully', 'Saved', { closeButton: true, tapToDismiss: true });
				} else {
					this._ToastrService.info(response.message || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				}
				this._LoaderService.hide();
			}, error: (error) => {
				console.error('error', error);
				this._ToastrService.error(error.text || MSG_ERROR_MESSAGE, 'Error!', { closeButton: true, tapToDismiss: true });
				this._LoaderService.hide();
			}
		});
	}

	public doAddNewClaim() {
		const dialogConfig = {
			itemTabDataList: this.itemTabDataList,
			minMaxEndUsersChargebackContractModelUI: this.minMaxEndUsersChargebackContractModelUI,
			callFrom: 'end-users',
		};
		this.loadUploadEndUserDxDialogSubject.next(dialogConfig);
	}

	public doModifyClaim(result) {
		const dialogConfig = {
			itemTabDataList: this.itemTabDataList,
			minMaxEndUsersChargebackContractModelUI: this.minMaxEndUsersChargebackContractModelUI,
			callFrom: 'end-users',

		};
		this.loadUploadEndUserDxDialogSubject.next(dialogConfig);
	}

	public doHideUploadEndUserDxDialog(result) {
		if (result) {
			this.chargeBackDocumentsTabDatalist = this._MinMaxEndUsersChargebackContractService.getChargebackDocumentTabDataList();
			this.chargeDocumentsTabDataSource = JSON.parse(JSON.stringify(this.chargeBackDocumentsTabDatalist));
			this.saveChargeDocumentsTabDataEvent.emit('claimItemChargeback');
		}
	}

	public doHideViewClaimDxDialog(result) {
		if (result) {
			this.chargeBackDocumentsTabDatalist = this._MinMaxEndUsersChargebackContractService.getChargebackDocumentTabDataList();
			this.chargeDocumentsTabDataSource = JSON.parse(JSON.stringify(this.chargeBackDocumentsTabDatalist));
			this.saveChargeDocumentsTabDataEvent.emit('claimItemChargeback');
		}
	}

	doViewImportClaimItemsExcel() {
		this.uploadChargebackEndUserClaimsProps = {
			billbackType: 'enduserschargeback',
			actionFor: 'showIgnoreItem',
			successedListOfCsvRows: this.successedListOfCsvRows,
			ignoredListOfCsvRows: this.ignoredListOfCsvRows
		};
		this.isShowUploadChargebackEnduserClaimsPopup = false;
		setTimeout(() => {
			this.isShowUploadChargebackEnduserClaimsPopup = true;
		}, 20);
	}
	checkValidation() {
		let valDoc: boolean = true;
		if (!this.editChargebackGuid) {
			this._ToastrService.info('Please save contract first.', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		} else if (this.itemTabDataList.length < 0) {
			this._ToastrService.info('Please add at least one product.', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		} else if (!this.documentFileData) {
			this._ToastrService.info('File is Required/Invalid.', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		} else if (!this.chargeDocumentsTabModel.documentURL || !this.chargeDocumentsTabModel.documentURL.trim()) {
			this._ToastrService.info('File is Required/Invalid.', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		} else if (!this.chargeDocumentsTabModel.documentReferenceNumber || !this.chargeDocumentsTabModel.documentReferenceNumber.trim()) {
			this._ToastrService.info('Document Reference No. Required .', 'Info', { closeButton: true, tapToDismiss: true });
			valDoc = false;
			return false;
		}
		return valDoc;
	}

	public doViewClaimPreview() {

		if (!this.checkClaimValidation()) {
			return;
		}
		let selectedEndUserList: any[] = [];
		if (this.enduserDataGrid) {
			selectedEndUserList = this.enduserDataGrid.instance.getSelectedRowsData() || [];
		}
		// let monthEndDate = new Date(moment(this.cut_off_date).endOf('month').format('YYYY-MM-DD hh:mm'));
		// if (monthEndDate < this.cut_off_date) {
		// 	this._ToastrService.info('Period Start Date and Period End Date should be in same month..', 'Info', { closeButton: true, tapToDismiss: true });
		// 	return
		// }


		const dialogData = {
			callFrom: 'preview',
			cut_off_date: this.cut_off_date ? moment(this.cut_off_date).format('YYYY-MM-DD') || null : null,
			transaction_date: this.transaction_date ? moment(this.transaction_date).format('YYYY-MM-DD') || null : null,
			// chargeback_period_start_date: moment(this.chargeback_period_start_date).format('YYYY-MM-DD') || null,
			// chargeback_period_end_date: moment(this.chargeback_period_end_date).format('YYYY-MM-DD') || null,
			// chargeback_period: this.chargeback_period,
			selectedEndUserList: selectedEndUserList,
			hide_quantity: this.hide_quantity,
			reasonCodeGuid: this.reasonCodeGridBoxValue && this.reasonCodeGridBoxValue ? this.reasonCodeGridBoxValue[0] : '',
			calledFrom: 'min-max-chargeback-document',
			minMaxEndUsersChargebackContractModelUI: this.minMaxEndUsersChargebackContractModelUI,
		}
		this.minMaxCharebackClaimPreviewProps = dialogData;
		this.isShowMinMaxChargebackClaimPreviewPopup = false;
		setTimeout(() => {
			this.isShowMinMaxChargebackClaimPreviewPopup = true;
		}, 300);
		this.resetAddClaimGrid();
	}

	resetAddClaimGrid() {
		if (this.enduserDataGrid) {
			this.enduserGridBoxValue = [];
			this.enduserDataGrid.instance.refresh();
			this.arrSelectedEnduserList = [];
		}
		if (this.resonCodeDataGrid) {
			this.resonCodeDataGrid.instance.refresh();
			this.reasonCodeGridBoxValue = [];
		}
		this.cut_off_date = null;
		this.transaction_date = new Date();
		// this.chargeback_period_start_date_end_date = [null, null];
	}

	public checkClaimValidation(): boolean {
		let selectedEndUserList: any[] = [];
		if (this.enduserDataGrid) {
			selectedEndUserList = this.enduserDataGrid.instance.getSelectedRowsData() || [];
		}
		if (!this.minMaxEndUsersChargebackContractModelUI.guid) {
			this._ToastrService.info('Please save contract first.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (selectedEndUserList.length === 0 && !this.isCustomerChargebackbasis) {
			this._ToastrService.info('Select at least one end-user.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (!this.reasonCodeGridBoxValue) {
			this._ToastrService.info('Select at least one Reason Code.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		if (!this.cut_off_date) {
			this._ToastrService.info('Cut off Date required.', 'Info', { closeButton: true, tapToDismiss: true });
			return
		}
		if (!this.transaction_date) {
			this._ToastrService.info('Transaction Date required.', 'Info', { closeButton: true, tapToDismiss: true });
			return
		}

		// if (!this.chargeback_period) {
		// 	this._ToastrService.info('Chargeback period required.', 'Info', { closeButton: true, tapToDismiss: true });
		// 	return false;
		// }
		return true;
	}
	public doFilterDateOnValueChanged(e) {
		// const previousValue = e.previousValue;
		// const newValue = e.value;
		if (e && e.value) {
			this.chargeback_period = e.value;
		}
	}

	public doCloseMinMaxChargebackClaimPopup(e) {
		this.getChargeBackDocumentTabData();
	}

	public doCloseClaimManualAdjustmentPopup() {
		this.isShowManualAdjustmentPopup = false;
		this.getChargeBackDocumentTabData();
	}

	//#region for Enduser select box methods
	public loadEnduserDataList() {
		if (this.itemTabDataList && this.itemTabDataList.length > 0) {
			let listOfEndusers = _.map(this.itemTabDataList, item => {
				return {
					guid: item['end_user_id'],
					code: item['end_user_code'],
					name: item['end_user_name'],
					pricelistno: item['pricelistno'],
					pricelistname: item['pricelistname'],
				}
			});
			this.enduserDataSource = listOfEndusers || [];
		}
	}

	public onEndusersSelectionChanged(e) {
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			this.enduserGridBoxValue = [];
			for (let i = 0; i < e.selectedRowsData.length; i++) {
				this.enduserGridBoxValue.push(e.selectedRowsData[i].name);
			};
		}

		this.lengthSelectedEndusers = '';
		if (e.selectedRowsData && e.selectedRowsData.length > 0) {
			this.lengthSelectedEndusers = e.selectedRowsData.length + ' End-User(s) Selected';
		}

	}

	onEnduserValueChanged(e: any) {
		if (e.value === null) {
			this.enduserGridBoxValue = [];
			this.enduserDataGrid.instance.refresh();
			this.arrSelectedEnduserList = [];
		}
	}

	public displayExprEnduserName(enduser) {
		let listOfText = [];
		// if (this.territoryModel.chkAllTerritory) {
		// 	listOfText.push('All Territory');
		// }
		if (this.arrSelectedEnduserList && this.arrSelectedEnduserList.length > 0) {
			listOfText.push(this.arrSelectedEnduserList.length + ' End-User(s) Selected');
		}
		return listOfText.length > 0 ? listOfText.join(', ') : '';
	}
	//#endregion

	private fetchListOfCustomReason() {
		let dynamic_groups = [];
		let dynamic_properties = [];
		try {
			dynamic_groups = this._LoginService.loginUser.account_detail.dynamic_groups || [];
			dynamic_properties = this._LoginService.loginUser.account_detail.dynamic_properties || [];
		} catch (error) {
			dynamic_groups = [];
			dynamic_properties = [];
		}
		let dgIndex = -1;
		dgIndex = dynamic_groups.findIndex(dg => dg.group_value.toString().toLowerCase() === 'approvalreason');
		if (dgIndex !== -1) {
			const dgData = dynamic_groups[dgIndex];
			const dpIndex = dynamic_properties.findIndex(dp => dp.group_guid === dgData.guid && dp.property.toString().toLowerCase() === 'reasoncode');
			if (dpIndex !== -1) {
				const dpData = dynamic_properties[dpIndex];
				const reqFormData = new FormData();
				reqFormData.append('usr', this._LoginService.loginUser.user);
				reqFormData.append('token', this._LoginService.loginUser.token);
				reqFormData.append('method', ServerMethods.GET_ENTITY_DATA);
				reqFormData.append('entity', ServerEntity.DYNAMIC_PROPERTY_OPTIONS);
				reqFormData.append('search', JSON.stringify([{ property_guid: dpData.guid }]));
				reqFormData.append('sort_dir', 'asc');
				reqFormData.append('is_dropdown', 'true');
				reqFormData.append('sort_field', 'sequence');
				reqFormData.append('view_fields', JSON.stringify(['guid', 'property_guid', 'value', 'label', 'erpcode', 'sequence', 'reason_description']));
				if (this.getListOfReasonSubscription) {
					this.getListOfReasonSubscription.unsubscribe();
				}
				this.listOfCustomReason = [];
				this.listOfHeaderCustomReason = [];
				// this._LoaderService.show();
				this.getListOfReasonSubscription = this._RestApiService.doDataOutReqFormData(reqFormData).subscribe({
					next: response => {
						// this._LoaderService.hide();
						if (response && response.flag) {
							this.listOfCustomReason = response.data || [];
							if (response.data.length > 0) {
								this.listOfHeaderCustomReason = this.getHeaderFilter(response.data, 'guid', 'label');
							}
						}
					}, error: error => {
						// this._LoaderService.hide();
					}
				});
			}
		}
	}
	private getHeaderFilter(listOfArray: any[], codeField: string, textField: string): any[] {
		const listOfHeaderFilters = [];
		listOfArray.forEach(ele => {
			const txt = ele.label + ' (' + ele.erpcode + ')';
			listOfHeaderFilters.push({ value: ele[codeField], text: txt.toString() });
		});
		return listOfHeaderFilters;
	}
	public displayExprReasonCode(item) {
		if (!item) {
			return "";
		}
		const name = item.label + ' (' + item.erpcode + ')';
		return name.toString();
	}

	public onReasonCodeSelectionChanged(e) {
		if (e.name === "value") {
			this.isReasonCodeDropDownBoxOpened = false;
			this.ref.detectChanges();
		}
	}

	// #region for Date range Box
	public doValueChangedStartEndDate(e: any) {
		// this.chargeback_period_start_date = e && e.value ? e.value[0] || null : null;
		// this.chargeback_period_end_date = e && e.value ? e.value[1] || null : null;
		// let selectedEndDate = e && e.value ? e.value[1] || null : null;
		// let monthEndDate = new Date(moment(this.chargeback_period_start_date).endOf('month').format('YYYY-MM-DD hh:mm'));
		// if (monthEndDate >= selectedEndDate) {
		// 	this.chargeback_period_end_date = selectedEndDate;
		// } else {
		// 	this.chargeback_period_end_date = null;
		// }
		// if (this.chargeback_period_start_date) {
		// 	this.minDateRang = this.chargeback_period_start_date;
		// 	this.maxDateRang = new Date(moment(this.chargeback_period_start_date).endOf('month').format('YYYY-MM-DD hh:mm'));
		// }
	}
	// #endregion

	public doActionsBtnClick(event: any) {
		const addIndex = this.listOfActions.findIndex(i => i.btn_code
			=== 'ADD_MANUAL_ADJUSTMENT');
		if (addIndex !== -1) {
			this.listOfActions[addIndex].visible = this.minMaxEndUsersChargebackContractModelUI.chargebackBasis === 'endusers';
			// this.listOfActions[addIndex].disabled = this.minMaxEndUsersChargebackContractModelUI.chargebackBasis === 'customers';
		}
	}


	public doActionsBtnItemClick(event: any) {
		if (event.itemData && event.itemData.btn_code) {
			const buttonCode = event.itemData.btn_code;
			switch (buttonCode) {
				case 'ADD_CHARGEBACK':
					this.doAddChargeback();
					break;
				case 'ADD_MANUAL_ADJUSTMENT':
					this.doAddManulAdjustment();
					break;
			}
		}
	}

	public doAddChargeback() {
		this.doViewClaimPreview();
	}

	public doAddManulAdjustment() {
		if (!this.checkClaimValidation()) {
			return;
		}
		const selectedEndUserList: any[] = this.enduserDataGrid.instance.getSelectedRowsData() || [];
		if (selectedEndUserList.length > 1) {
			this._ToastrService.info('Please select only one end-user.', 'Info', { closeButton: true, tapToDismiss: true });
			return false;
		}
		let reason_code_label: string = '';
		let reason_code_erpcode: string = '';
		let reason_code_guid: string = '';
		if (this.resonCodeDataGrid) {
			const selectedReasonRow = this.resonCodeDataGrid.instance.getSelectedRowsData();
			if (selectedReasonRow && selectedReasonRow.length > 0) {
				reason_code_guid = selectedReasonRow[0].guid;
				reason_code_label = selectedReasonRow[0].label;
				reason_code_erpcode = selectedReasonRow[0].erpcode;
			}
		}
		const dialogData = {
			callFrom: 'preview',
			cut_off_date: this.cut_off_date ? moment(this.cut_off_date).format('YYYY-MM-DD') || null : null,
			transaction_date: this.transaction_date ? moment(this.transaction_date).format('YYYY-MM-DD') || null : null,
			chargeback_period: this.chargeback_period,
			reasonCodeGuid: this.reasonCodeGridBoxValue && this.reasonCodeGridBoxValue ? this.reasonCodeGridBoxValue[0] : '',
			reason_code_guid: reason_code_guid,
			reason_code_label: reason_code_label,
			reason_code_erpcode: reason_code_erpcode,
			calledFrom: 'min-max-chargeback-document',
			selectedEndUserList: selectedEndUserList,
			itemTabDataList: this.itemTabDataList,
			hide_quantity: this.hide_quantity,
			minMaxEndUsersChargebackContractModelUI: this.minMaxEndUsersChargebackContractModelUI,
		}
		this.manualAdjustmentProps = dialogData;
		this.isShowManualAdjustmentPopup = false;
		setTimeout(() => {
			this.isShowManualAdjustmentPopup = true;
		}, 50);
		this.resetAddClaimGrid();
	}

	public doCloseUploadChargebackEnduserClaimsPopup(result) {
		this.isShowUploadChargebackEnduserClaimsPopup = false;
	}

	public doCloseClaimedItemsLstPopup(result) {
		this.isShowClaimedItemlstPopup = false;
		if (result) {
			this.saveClaimedItems(result);
		}
	}
}


