<!-- <div class="popCnt" style="width:1150px;">
	<div class="popBox padB10 cf">
		<div class="popHeader">
			<span (click)="dismiss()" class="closePopup icon-close-popup"></span>
			<h2 class="popTitle">Billback Document</h2>
		</div>
		<div class="popContent">
			<div> -->
<dx-popup [width]="'1150px'" [showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="false"
	[title]="'Billback Document'" [showCloseButton]="true" [(visible)]="isShowPopup"
	[wrapperAttr]="{ class: 'popupToolbarPadding' }" (onHidden)="doHiddenPopop($event)">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'" [bounceEnabled]="false"
			[useNative]="false">
			<div class="orderDetail orderPdetails">

				<div class="custDetail">
					<h4 class="graySubHead textWrap">Document Reference No. :
						{{billBackDocumentDetails.documentReferenceNumber}}</h4>
					<h4 class="graySubHead textWrap">Document :
						<a href="{{downloadDocumentUrl}}" class="downloadIcon">
						</a>
						Download
					</h4>
					<div class="remarkBx fullNote" *ngIf="billBackDocumentDetails.claimedUserNote">
						<strong>User Note:- {{billBackDocumentDetails.claimedUserNote}}</strong>
					</div>
				</div>
				<div class="orderStatus">
					<ul class="statusBx">
						<li><span class="stLbl">Document Date :</span><strong
								class="black">{{billBackDocumentDetails.documentDate | toDateObj | date: 'yyyy-MM-dd'
								}}</strong>
						</li>
					</ul>
				</div>
			</div>
			<div class="hrLine"></div>

			<div class="tableOut">
				<dx-data-grid #documenDataGridContainer id="gridContainer" [dataSource]="documentDataSource"
					[remoteOperations]="false" [showBorders]="true" [columnAutoWidth]="true" [allowColumnResizing]="true"
					(onToolbarPreparing)="doToolbarPreparing($event)" [height]="450" class="hideFilterPanelInx">
					<dxo-sorting mode="single"></dxo-sorting>
					<dxo-filter-row [visible]="true"></dxo-filter-row>
					<dxo-header-filter [visible]="true"></dxo-header-filter>
					<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
					<dxo-editing mode="row" [allowUpdating]="false" [allowDeleting]="false" [useIcons]="true">
					</dxo-editing>
					<dxi-column caption="SKU" [visible]="isQBOnlineErpUser" dataField="itemsku" [allowEditing]="false"
						cellTemplate="cellTemplateItemSku" [allowHeaderFiltering]="false" [allowFiltering]="true"
						[allowSorting]="true">
						<div *dxTemplate="let element of 'cellTemplateItemSku'">
							{{element.data.itemsku || ''}}
						</div>
					</dxi-column>
					<dxi-column caption="Item" dataField="itemname" [allowEditing]="false"
						cellTemplate="cellTemplateItemName" [allowHeaderFiltering]="false" [allowFiltering]="true"
						[allowSorting]="true">
						<div *dxTemplate="let element of 'cellTemplateItemName'">
							{{element.data.itemname || ''}}
						</div>
					</dxi-column>
					<dxi-column caption="Description" dataField="description" [allowEditing]="false"
						cellTemplate="cellTemplateItemDesc" [allowHeaderFiltering]="false" [allowFiltering]="true"
						[allowSorting]="true">
						<div *dxTemplate="let element of 'cellTemplateItemDesc'">
							{{element.data.description || ''}}
						</div>
					</dxi-column>
					<dxi-column caption="Customer" dataField="customer_name" [allowEditing]="false"
						cellTemplate="cellTemplateCustomer" [allowHeaderFiltering]="false" [allowFiltering]="true"
						[allowSorting]="true">
						<div *dxTemplate="let element of 'cellTemplateCustomer'">
							{{element.data.customer_name || ''}}
						</div>
					</dxi-column>
					<dxi-column caption="UOM" dataField="selectedClaimedUom" [allowHeaderFiltering]="false"
						[allowFiltering]="false" [allowEditing]="true" cellTemplate="cellTemplateUomCode">
						<!-- <dxo-lookup [dataSource]="listOfUoms" valueExpr="code" [displayExpr]="getDisplayExprUomName">
						</dxo-lookup> -->
						<div *dxTemplate="let element of 'cellTemplateUomCode'">
							<span>
								{{element.data.selectedClaimedUom || '' | filterFromList:uomListData : 'code' : 'name' |
								uppercase}}
							</span>
						</div>
					</dxi-column>
					<dxi-column caption="Billback Amount" dataField="billbackAmount" [allowEditing]="true"
						[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true">
						<dxo-format type="currency" [precision]="decimalPointForCurrency">
						</dxo-format>
						<div *dxTemplate="let element of 'cellTemplateBillbackAmount'">
							{{element.data.billbackAmount ||0 | customCurrencyPipe}}
						</div>
					</dxi-column>
					<dxi-column caption="Claimed Qty" dataField="quantity" [allowEditing]="false"
						[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true"
						cellTemplate="cellTemplateQuantity">
						<div *dxTemplate="let element of 'cellTemplateQuantity'">
							{{(element.data.quantity % 1) === 0 ? element.data.quantity :element.data.quantity | number :
							'1.2-4'}}
						</div>
					</dxi-column>
					<dxi-column caption="Claimed Amount" dataField="linetotal" [allowEditing]="true"
						[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true">
						<dxo-format type="currency" [precision]="decimalPointForCurrency">
						</dxo-format>
						<div *dxTemplate="let element of 'cellTemplateLinetotal'">
							{{element.data.linetotal || 0 | customCurrencyPipe}}
						</div>
					</dxi-column>
					<dxo-summary>
						<dxi-total-item column="quantity" summaryType="sum" displayFormat="Total : {0}">
							<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-value-format>
						</dxi-total-item>
						<dxi-total-item column="linetotal" summaryType="sum" displayFormat="Total : {0}">
							<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
							</dxo-value-format>
						</dxi-total-item>
					</dxo-summary>
				</dx-data-grid>
				<!-- <table class="tableData invoiceTbl ">
					<tbody>
						<tr>
							<th *ngIf="isQBOnlineErpUser" class="left width80">SKU</th>
							<th class="left width80">Item</th>
							<th> Description</th>
							<th class="left width200"> Customer </th>
							<th class="center width60">UOM</th>
							<th class="right width80">Billback Amount</th>
							<th class="center width60">Claimed</th>
							<th class="right width80">Claimed Amount</th>
						</tr>
						<tr *ngFor="let ci of billBackDocumentDetails.billedItems;let i = index">
							<td *ngIf="isQBOnlineErpUser" class="left width80">{{ci.itemsku}}</td>
							<td class="width100">{{ci.itemname}}</td>
							<td> {{ci.description}}</td>
							<td class="left width200"> {{ci.customer_name}}</td>
							<td class="center width60">
								{{ci.selectedClaimedUom |filterFromList: uomListData : 'code' : 'name' | uppercase}}
							</td>
							<td class="right width80">{{ci.billbackAmount || 0 | customCurrencyPipe}}</td>
							<td class="center width60">{{ci.quantity || 0}}</td>
							<td class="right width80">{{ci.linetotal || 0 | customCurrencyPipe}}</td>
						</tr>
						<tr class="totalRowLast">
							<td [attr.colspan]="4">
								<div class="remarkBx fullNote" *ngIf="billBackDocumentDetails.claimedUserNote">
									<strong>User Note:- {{billBackDocumentDetails.claimedUserNote}}</strong>
								</div>
							</td>
							<td [attr.colspan]="3" class="totalCount">
								<div class="total">Total Claimed Quantity: <span>{{totalClaimed}}</span></div>
								<div class="total">Total Claimed Amount:
									<span>{{totalClaimedAmount || 0 | customCurrencyPipe}}</span></div>
							</td>
						</tr>
					</tbody>
				</table> -->
			</div>
		</dx-scroll-view>
	</div>
</dx-popup>