<div class="mrgT10">
	<dx-data-grid #billbackDocumentsTabDataGridContainer id="billbackDocumentsTabDataGridContainer"
		[dataSource]="billbackDocumentsTabDataSource" [remoteOperations]="false" [showBorders]="true"
		[allowColumnResizing]="true" [columnAutoWidth]="true" (onToolbarPreparing)="doToolbarPreparing($event)"
		(onRowClick)="viewBillBackDocumentDetails($event.data);" [height]="450" keyExpr="guid" class="hideFilterPanelInx">

		<dxo-sorting mode="single"></dxo-sorting>
		<dxo-filter-row [visible]="true"></dxo-filter-row>
		<dxo-header-filter [visible]="true"></dxo-header-filter>
		<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
		<!-- <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="false"></dxo-editing> -->
		<dxi-column caption="Document Name" dataField="documentURL" [allowEditing]="false"
			cellTemplate="cellTemplateDocumentURL" [allowHeaderFiltering]="false" [allowFiltering]="true"
			[allowSorting]="true">
			<div *dxTemplate="let element of 'cellTemplateDocumentURL'">
				{{element.data.documentURL || ''}}
			</div>
		</dxi-column>
		<dxi-column caption="Document Reference No." dataField="documentOriginalName" [allowEditing]="false"
			cellTemplate="cellTemplatedocumentReferenceNumber" [allowHeaderFiltering]="false" [allowFiltering]="true"
			[allowSorting]="true">
			<div *dxTemplate="let element of 'cellTemplatedocumentReferenceNumber'">
				{{element.data.documentReferenceNumber || ''}}
			</div>
		</dxi-column>
		<dxi-column caption="Document Date" dataField="documentDate" [allowEditing]="false"
			cellTemplate="cellTemplatedocumentDocumentDate" [allowHeaderFiltering]="false" [allowFiltering]="true"
			[allowSorting]="true">
			<div *dxTemplate="let element of 'cellTemplatedocumentDocumentDate'">
				{{element.data.documentDate | toDateObj | date: 'yyyy-MM-dd'}}
			</div>
		</dxi-column>
		<dxi-column caption="Total Claimed Amount" dataField="documentTotal" [allowEditing]="false"
			[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true">
			<dxo-format type="currency" [precision]="decimalPointForCurrency">
			</dxo-format>
			<div *dxTemplate="let element of 'cellTemplateTotalClaimedAmount'">
				{{element.data.documentTotal || '0' | customCurrencyPipe}}
			</div>
		</dxi-column>

		<dxi-column dataField="guid" caption="Action" [allowEditing]="false" cellTemplate="cellTemplateAction"
			[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="false" [allowExporting]="false">
			<div *dxTemplate="let element of 'cellTemplateAction'" class="center actBtn">
				<a (click)="viewBillBackDocumentDetails(element.data);$event.stopPropagation();">
					<span class="viewIcon sprite" id="viewDocument_{{element.rowIndex + 1}}"></span>
				</a>
				<a (click)="downloadBillBackDocument(element.data);$event.stopPropagation();">
					<span class="downloadIcon" id="downloadDocument_{{element.rowIndex + 1}}"></span>
				</a>
				<dx-tooltip target="#viewDocument_{{element.rowIndex + 1}}" showEvent="dxhoverstart" hideEvent="dxhoverend"
					position="bottom">
					<div *dxTemplate="let data = data of 'content'">
						View Document
					</div>
				</dx-tooltip>
				<dx-tooltip target="#downloadDocument_{{element.rowIndex + 1}}" showEvent="dxhoverstart"
					hideEvent="dxhoverend" position="bottom">
					<div *dxTemplate="let data = data of 'content'">
						Download Document
					</div>
				</dx-tooltip>
			</div>
		</dxi-column>
	</dx-data-grid>
</div>
<app-bc-view-billback-documents-dialog *ngIf="isShowBillbackDocumentPopup"
	[viewBillbackDocumentProps]="viewBillbackDocumentProps"
	(closeViewBillbackDocumentPopup)="doCloseViewBillbackDocumentPopup($event)"></app-bc-view-billback-documents-dialog>