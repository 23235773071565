import { any } from "underscore";

export class YearToDate {
	public reportBy?: string;
	public year1?: string;
	public year2?: number;
	public month?: number;
	public customersText?: string;
	public itemsText?: string;
	public selectedCustomers?: string;
	public selectedItems?: string;
	public salesReps?: any[] = [];
	public salesManagers?: any[] = [];
}

export class MonthWiseData {
	public month?: string;
	public year?: string;
	public total?: number;
}

export class YearToDateCustomData {
	public customername: any;
	public Jan: MonthWiseData = new MonthWiseData();
	public Feb: MonthWiseData = new MonthWiseData();
	public Mar: MonthWiseData = new MonthWiseData();
	public Apr: MonthWiseData = new MonthWiseData();
	public May: MonthWiseData = new MonthWiseData();
	public Jun: MonthWiseData = new MonthWiseData();
	public Jul: MonthWiseData = new MonthWiseData();
	public Aug: MonthWiseData = new MonthWiseData();
	public Sep: MonthWiseData = new MonthWiseData();
	public Oct: MonthWiseData = new MonthWiseData();
	public Nov: MonthWiseData = new MonthWiseData();
	public Dec: MonthWiseData = new MonthWiseData();
	public total: number;
}

export class YearToYearCustomData {
	public customername: any;
	public Jan1: MonthWiseData = new MonthWiseData();
	public Jan2: MonthWiseData = new MonthWiseData();
	public Feb1: MonthWiseData = new MonthWiseData();
	public Feb2: MonthWiseData = new MonthWiseData();
	public Mar1: MonthWiseData = new MonthWiseData();
	public Mar2: MonthWiseData = new MonthWiseData();
	public Apr1: MonthWiseData = new MonthWiseData();
	public Apr2: MonthWiseData = new MonthWiseData();
	public May1: MonthWiseData = new MonthWiseData();
	public May2: MonthWiseData = new MonthWiseData();
	public Jun1: MonthWiseData = new MonthWiseData();
	public Jun2: MonthWiseData = new MonthWiseData();
	public Jul1: MonthWiseData = new MonthWiseData();
	public Jul2: MonthWiseData = new MonthWiseData();
	public Aug1: MonthWiseData = new MonthWiseData();
	public Aug2: MonthWiseData = new MonthWiseData();
	public Sep1: MonthWiseData = new MonthWiseData();
	public Sep2: MonthWiseData = new MonthWiseData();
	public Oct1: MonthWiseData = new MonthWiseData();
	public Oct2: MonthWiseData = new MonthWiseData();
	public Nov1: MonthWiseData = new MonthWiseData();
	public Nov2: MonthWiseData = new MonthWiseData();
	public Dec1: MonthWiseData = new MonthWiseData();
	public Dec2: MonthWiseData = new MonthWiseData();
	public total1: number;
	public total2: number;
	public total: number;
}

export class PeriodToDateCustomData {
	public customername: any;
	public Year1: MonthWiseData = new MonthWiseData();
	public Year2: MonthWiseData = new MonthWiseData();
	public totalChange: number;
	public totalChangePercentage: number;
}
export class ColumnElement {
	customername: string;
	JanTotal: number;
	FebTotal: number;
	MarTotal: number;
	AprTotal: number;
	MayTotal: number;
	JunTotal: number;
	JulTotal: number;
	AugTotal: number;
	SepTotal: number;
	OctTotal: number;
	NovTotal: number;
	DecTotal: number;
	FinalTotal: number;
	constructor() {
		this.customername = '';
		this.JanTotal = 0;
		this.FebTotal = 0;
		this.MarTotal = 0;
		this.AprTotal = 0;
		this.MayTotal = 0;
		this.JunTotal = 0;
		this.JulTotal = 0;
		this.AugTotal = 0;
		this.SepTotal = 0;
		this.OctTotal = 0;
		this.NovTotal = 0;
		this.DecTotal = 0;
		this.FinalTotal = 0;
	}

}

export class Report {
	public guid: string;
	public reportname: string;
	public owner: any[] = [];
	public is_global: Boolean;
	public created_by: string;
	public updated_by: string;
	public created_date: string;
	public modified_date: string;
	public fieldschooser: any = {};
	public year?: any[] = [];
	public customer: any;
	public item: any;
	public margin_correction: number = null;
	public assignto: any;
	public report_by: string;
	public from_date?: any;
	public to_date?: any;
	constructor() {

	}
}

export class IncentiveReportUI {
	public incentive_type: string;
	public billback_chargeback_type?: string;
	public claim_date?: [Date, Date];
	public document_date?: [Date, Date];
	public chargeback_period?: [Date, Date];
	public chargeback_period_start?: any;
	public chargeback_period_end?: any;
	public customers?: any[];
	constructor() {

	}
}

export class StatementReportUI {
	public endusers?: string[];
	public endUsersDetails?: any[];
	public document_name?: string;
	public statement_code?: string;
	public statement_name?: string;
	public statement_discription?: string;
	public startdate?: string;
	public enddate?: string;
	public startdate_and_enddate?: [Date, Date];
	constructor() {
		this.endusers = [];
		this.endUsersDetails = [];
		this.startdate_and_enddate = [null, null];
	}
}
