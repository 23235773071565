<div class="row" *ngxPermissionsOnly="isCurrentTabPermission">
	<div
		*ngIf="productSearchItemType === 'price' && !isQBOnlineErpUser && minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListName"
		class="cols12 ">
		<div class="right secondaryTitle">
			<div class="noteBx mrgT0">
				<span>
					<strong>Customer Price List :</strong>
				</span>
				<span *ngIf="minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListName">
					{{minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListName || ''}}
				</span>
				<span *ngIf="!minMaxEndUsersChargebackContractModelUI.selectCustomerPriceListName">
					<strong> &mdash; </strong>
				</span>
			</div>
		</div>
	</div>
</div>

<div class="row">
	<div class="inputContolscf gridSearch cf cols4">
		<div class="dx-field feildCol">
			<div class="dx-field-label">End-Users</div>
			<div class="dx-field-value" style="width: 100%;">
				<dx-drop-down-box [(value)]="endUsersGridBoxValue" [displayExpr]="displayExprEndUserName" valueExpr="guid"
					placeholder="Select End-User" [(opened)]="isEndUsersDropDownBoxOpened" [showClearButton]="true"
					[deferRendering]="false" [dataSource]="endUsersDataSource"
					(onOptionChanged)="onEndUsersSelectionChanged($event)">
					<div *dxTemplate="let data of 'content'">
						<dx-data-grid #endUsersDataGrid [dataSource]="endUsersDataSource" [columns]="endUsersGridBoxColumns"
							[hoverStateEnabled]="true" [(selectedRowKeys)]="endUsersGridBoxValue">
							<dxo-selection mode="single"></dxo-selection>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxo-scrolling mode="standerd"></dxo-scrolling>
							<dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
						</dx-data-grid>
					</div>
				</dx-drop-down-box>
			</div>
		</div>
	</div>
	<div class="inputContolscf gridSearch cf cols7 cols7addbtn">
		<div class="dx-field feildCol">
			<div class="dx-field-label">Item</div>
			<div class="dx-field-value" style="width: 100%;">
				<dx-drop-down-box [dataSource]="itemDataSource" [(value)]="lengthSelectedItems" valueExpr="itemname"
					placeholder="Select Item..." [showClearButton]="true" (onValueChanged)="onItemValueChanged($event)">
					<div *dxTemplate="let data of 'content'">
						<!-- <dx-check-box text="All Item" (onValueChanged)="checkAllItem()" [(value)]="chkAllItem" class="mrgB10">
						</dx-check-box> -->
						<dx-tag-box [(value)]="arrSelectedItemList" [grouped]="true">
						</dx-tag-box>
						<dx-data-grid #itemDataGrid [dataSource]="itemDataSource" [columns]="itemGridBoxColumns"
							[selection]="{ mode: 'multiple'}" [hoverStateEnabled]="true"
							[paging]="{ enabled: true, pageSize: 10 }" [filterRow]="{ visible: true }" [height]="345"
							[(selectedRowKeys)]="arrSelectedItemList" [remoteOperations]="true" [wordWrapEnabled]="true"
							[showBorders]="true" (onSelectionChanged)="onItemSelectionChanged($event)">
							<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
							<dxo-selection [allowSelectAll]="false"></dxo-selection>
						</dx-data-grid>
					</div>
				</dx-drop-down-box>
			</div>
		</div>
	</div>
	<div class="cols1 cols1addbtn txtRight">
		<dx-button class="secondaryBtn button center" type="normal" stylingMode="contained" [useSubmitBehavior]="false"
			icon="add" (onClick)="addProductAgreementTabDetails()" text="Add">
		</dx-button>
	</div>
</div>

<div class="mrgT10">
	<dx-data-grid id="gridContainer" [dataSource]="itemTabDataSource" [remoteOperations]="false" [showBorders]="true"
		[columnAutoWidth]="true" [allowColumnResizing]="true" (onRowRemoved)="doOnRowRemovedItemTab($event)"
		keyExpr="guid" class="hideFilterPanelInx" [height]="'40%'">
		<dxo-sorting mode="single"></dxo-sorting>
		<dxo-filter-row [visible]="true"></dxo-filter-row>
		<dxo-filter-panel [visible]="true"></dxo-filter-panel>
		<dxo-header-filter [visible]="true"></dxo-header-filter>
		<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
		<dxo-master-detail [enabled]="true" template="itemDetail"></dxo-master-detail>
		<!-- <dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
		<dxo-pager [visible]="false" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
			[showInfo]="true"></dxo-pager> -->
		<dxo-editing mode="row" [allowUpdating]="!isDisablePermission" [allowDeleting]="!isDisablePermission"
			[useIcons]="true">
		</dxo-editing>

		​<div *dxTemplate="let data of 'templateFilterText'">
			<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
				<span>{{filterText}}</span>
			</div>
		</div>

		<dxi-column caption="End-User" dataField="end_user_name" [allowEditing]="false"
			cellTemplate="cellTemplateSelectEndUsers" [allowHeaderFiltering]="false" [allowFiltering]="true"
			[allowSorting]="true" dataType="string">
			<div *dxTemplate="let element of 'cellTemplateSelectEndUsers'">
				{{element.data.end_user_name || ''}}
			</div>
		</dxi-column>

		<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
			[allowSorting]="false" alignment="center" [allowResizing]="true" [minWidth]="150" [width]="150"
			[allowExporting]="false" dataType="string">
			<!-- <dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button> -->
			<dxi-button name="delete" cssClass="dx-grid-delete menuTip" [visible]="chkClaimedQty"></dxi-button>
		</dxi-column>

		<div *dxTemplate="let itemDetail of 'itemDetail'">
			<dx-data-grid #itemTabDataGridContainer id="gridContainer" [dataSource]="itemDetail.data.items"
				[remoteOperations]="false" [showBorders]="true" [columnAutoWidth]="true" [allowColumnResizing]="true"
				(onToolbarPreparing)="doToolbarPreparing($event)" (onEditorPreparing)="doOnEditorPreparingItemTab($event)"
				(onSaved)="doOnSavedItemTab()" keyExpr="guid" class="hideFilterPanelInx"
				(onCellClick)="doCellClick($event)">

				<dxo-sorting mode="single"></dxo-sorting>
				<dxo-filter-row [visible]="true"></dxo-filter-row>
				<dxo-filter-panel [visible]="true"></dxo-filter-panel>
				<dxo-header-filter [visible]="true"></dxo-header-filter>
				<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
				<dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="false" [allowAdding]="false"
					[useIcons]="true">
				</dxo-editing>
				<dxi-column caption="SKU" dataField="itemsku" [visible]="!checkAllItem && isQBOnlineErpUser"
					[allowEditing]="false" cellTemplate="cellTemplateSKU" [allowHeaderFiltering]="false"
					[allowFiltering]="true" [allowSorting]="true" dataType="string">
					<div *dxTemplate="let element of 'cellTemplateSKU'">
						{{element.data.itemsku || ''}}
					</div>
				</dxi-column>
				<dxi-column caption="Item" dataField="itemname" [allowEditing]="false" cellTemplate="cellTemplateItemName"
					[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true" dataType="string">
					<div *dxTemplate="let element of 'cellTemplateItemName'">
						{{element.data.itemname || ''}}
					</div>
				</dxi-column>
				<dxi-column caption="Description" dataField="description" [allowEditing]="false"
					cellTemplate="cellTemplateDescription" [allowHeaderFiltering]="false" [allowFiltering]="true"
					[allowSorting]="true" dataType="string">
					<div *dxTemplate="let element of 'cellTemplateDescription'">
						{{element.data.description || ''}}
					</div>
				</dxi-column>
				<dxi-column caption="UOM" dataField="selectedUom" [allowHeaderFiltering]="false" [allowFiltering]="false"
					[allowEditing]="true" cellTemplate="cellTemplateUomCode" dataType="string">
					<dxo-lookup [dataSource]="listOfUoms" valueExpr="code" [displayExpr]="getDisplayExprUomName">
					</dxo-lookup>
					<!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
					<div *dxTemplate="let element of 'cellTemplateUomCode'">
						<span>
							{{element.data.selectedUom || '' | filterFromList:listOfUoms : 'code' : 'name' | uppercase}}
						</span>
					</div>
				</dxi-column>
				<dxi-column caption="Chargeback Amount" dataField="chargebackAmount" [allowEditing]="true"
					[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true" alignment="right"
					dataType="number" [visible]="!enable_chargeback_look_back_period">
					<dxo-format type="currency" [precision]="decimalPointForCurrency">
					</dxo-format>
					<div *dxTemplate="let element of 'cellTemplateChargebackAmount'">
						{{element.data.chargebackAmount || 0 | customCurrencyPipe }}
					</div>
				</dxi-column>
				<dxi-column caption="Sold" dataField="sold" [allowEditing]="false" cellTemplate="cellTemplateSold"
					[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true" dataType="number">
					<div *dxTemplate="let element of 'cellTemplateSold'">
						<!-- {{element.data.sold}} -->
						<span
							[ngClass]="{'highlight pointerMark': viewTotalQuantity(element.data.sold) > 0}">{{viewTotalQuantity(element.data.sold)}}</span>
					</div>
				</dxi-column>
				<dxi-column caption="Claimed" dataField="claimed" [allowEditing]="false" cellTemplate="cellTemplateClaimed"
					[allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="true" dataType="number">
					<div *dxTemplate="let element of 'cellTemplateClaimed'">
						<span
							[ngClass]="{'highlight pointerMark': viewTotalQuantity(element.data.claimed) > 0}">{{viewTotalQuantity(element.data.claimed)}}
						</span>
					</div>
				</dxi-column>
				<dxi-column caption="Available" dataField="available" [allowEditing]="false"
					cellTemplate="cellTemplateAvailable" [allowHeaderFiltering]="false" [allowFiltering]="false"
					[allowSorting]="true" dataType="number">
					<div *dxTemplate="let element of 'cellTemplateAvailable'">
						<span
							[ngClass]="{'highlight pointerMark': viewTotalQuantity(element.data.available) > 0}">{{viewTotalQuantity(element.data.available)}}</span>
					</div>
				</dxi-column>
				<dxi-column type="buttons" caption="Action" [allowFiltering]="false" [allowHeaderFiltering]="false"
					[allowSorting]="false" alignment="center" [allowResizing]="true" [minWidth]="150" [width]="150"
					[allowExporting]="false">
					<dxi-button name="edit" cssClass="dx-grid-edit menuTip"></dxi-button>
					<!-- <dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button> -->
				</dxi-column>
			</dx-data-grid>
		</div>
	</dx-data-grid>

	<div *ngIf="isUploadExcelCsvFileItmGrpTab" class="noteBx mrgT10 mrgB0">
		<span>
			<b>{{noOfUnmatchedItem || 0}}</b> Item(s) out of <b>{{noOfTotalItem || 0}}</b> Item(s)
			ignored
			<span class="noteSeprator"></span>
			<b>{{noOfUnmatchedItemUom || 0}}</b> UOM(s) out of <b>{{noOfTotalItem || 0}}</b> UOM(S)
			ignored
			<span>
				<span class="noteSeprator"></span>
				<b>{{noOfmatchedItemPrice || 0}}</b> Item(s) out of <b>{{noOfTotalItem || 0}}</b>
				item(S)
				Price / Chargeback ignored
			</span>
			<strong *ngIf="listOfCsvRowForItmGrpTab && listOfCsvRowForItmGrpTab.length > 0"
				(click)="doShowCsvItemsListItmGrpTab()" class="pointerMark">Click here to
				view</strong>
		</span>
	</div>

</div>

<dx-popup width="1200" height="650" maxHeight="650" [showTitle]="true" [title]="quantityHeaderText" [dragEnabled]="true"
	[hideOnOutsideClick]="true" [showCloseButton]="true" [(visible)]="isVisiblePopup" (onHidden)="doHiddenPopop($event)"
	[wrapperAttr]="{ class: 'popupToolbarPadding' }">
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'"
			[bounceEnabled]="false">
			<!-- <div class="subTitleRow">
				<h2>{{quantityHeaderText}}</h2>
			</div> -->
			<dx-data-grid #quantityDataGridContainer [dataSource]="listOfAvailableQuantity" [showBorders]="true"
				[remoteOperations]="true" [showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
				[columnResizingMode]="'nextColumn'" [columnAutoWidth]="true" [width]="'100%'" class="hideFilterPanelInx ">
				<dxo-header-filter [visible]="false"></dxo-header-filter>
				<dxo-filter-row [visible]="false"></dxo-filter-row>
				<dxo-filter-panel [visible]="false"></dxo-filter-panel>
				<dxo-sorting mode="single"></dxo-sorting>
				<dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
				<dxo-master-detail [enabled]="true" template="invoiceDetail"></dxo-master-detail>

				<dxi-column dataField="quantity" [allowHeaderFiltering]="false" caption="Quantity"
					cellTemplate="cellTemplateQuantity" alignment="center" dataType="number">
					<div *dxTemplate="let element of 'cellTemplateQuantity'">
						{{element.data.quantity || ''}}
					</div>
				</dxi-column>
				<dxi-column dataField="price" [allowHeaderFiltering]="false" caption="Price"
					[visible]="quantityDataField === 'sold'" dataType="number">
					<dxo-format type="currency" [precision]="decimalPointForCurrency">
					</dxo-format>
					<div *dxTemplate="let element of 'cellTemplatePrice'">
						{{element.data.price || 0 | customCurrencyPipe}}
					</div>
				</dxi-column>
				<dxi-column dataField="chargebackAmount" [allowHeaderFiltering]="false" caption="Chargeback"
					dataType="number" [visible]="quantityDataField === 'claimed' || quantityDataField === 'available'">
					<dxo-format type="currency" [precision]="decimalPointForCurrency">
					</dxo-format>
					<div *dxTemplate="let element of 'cellTemplateChargeback'">
						{{element.data.chargebackAmount || 0 | customCurrencyPipe}}
					</div>
				</dxi-column>
				<div *dxTemplate="let invDetail of 'invoiceDetail'">
					<dx-data-grid [dataSource]="invDetail.data.invoices" [showBorders]="true" [remoteOperations]="true"
						[showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
						[columnResizingMode]="'nextColumn'" [columnAutoWidth]="true" [width]="'100%'"
						class="hideFilterPanelInx ">
						<dxo-header-filter [visible]="false"></dxo-header-filter>
						<dxo-filter-row [visible]="false"></dxo-filter-row>
						<dxo-filter-panel [visible]="false"></dxo-filter-panel>
						<dxo-sorting mode="single"></dxo-sorting>
						<dxi-column dataField="docnum" [allowHeaderFiltering]="false" caption="Invoice No."
							cellTemplate="cellTemplateDocnum" alignment="center" dataType="string">
							<div *dxTemplate="let element of 'cellTemplateDocnum'">
								{{element.data.docnum || ''}}
							</div>
						</dxi-column>
						<dxi-column dataField="docdate" [allowHeaderFiltering]="false" caption="Invoice Date"
							cellTemplate="cellTemplateDocdate" alignment="center" dataType="string">
							<div *dxTemplate="let element of 'cellTemplateDocdate'">
								{{element.data.docdate? convertToLocalDate(element.data.docdate) :''}}
							</div>
						</dxi-column>
						<dxi-column dataField="quantity" [allowHeaderFiltering]="false" caption="Quantity"
							cellTemplate="cellTemplateQuantity" alignment="center" dataType="number">
							<div *dxTemplate="let element of 'cellTemplateQuantity'">
								{{element.data.quantity || ''}}
							</div>
						</dxi-column>
					</dx-data-grid>
				</div>
			</dx-data-grid>
		</dx-scroll-view>
	</div>
</dx-popup>

<app-mmeucc-upload-items-dialog *ngIf="isShowUploadItemsPopup" [uploadItemsProps]="uploadItemsProps"
	(closeUploadItemsPopup)="doCloseUploadItemsPopup($event)">></app-mmeucc-upload-items-dialog>