<dx-popup [fullScreen]="true" [showTitle]="true" [title]="titleText" [dragEnabled]="true" [hideOnOutsideClick]="false"
	[showCloseButton]="true" [(visible)]="isVisiblePopup" (onHidden)="doHiddenPopup()"
	[wrapperAttr]="{ class: 'popupToolbarPadding popupZIndex' }" id="ViewClaimPopUp">
	<!-- [wrapperAttr]="{ class: 'popup-dx-overlay-content dx-popup-content-pad0' }" id="ViewClaimPopUp"> -->
	<dxo-position at="center" my="center"> </dxo-position>
	<div *dxTemplate="let data of 'content'">
		<dx-drawer [openedStateMode]="'shrink'" [position]="'right'" [revealMode]="'slide'" template="template"
			[(opened)]="isOpenAuditTrailDrawer" [closeOnOutsideClick]="false" [openedStateMode]="'overlap'"
			[elementAttr]="{ class: isOpenAuditTrailDrawer ?'priceListDetailsDrawerPadIx':'' }">
			<div class="cols12 pad0">
				<!-- <div class="cols12"> -->
				<dx-scroll-view [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'always'"
					[bounceEnabled]="false" [useNative]="false" width="100%" height="100%">
					<div>
						<div class="orderDetail orderPdetails">
							<div class="custDetail">
								<!-- <h4 class="graySubHead textWrap">Claim No. :
								{{claimId}}</h4> -->
								<h4 class="graySubHead textWrap" *ngIf="claimPreviewDetails?.ixcode">Charegback No.:
									<span class="black">{{claimPreviewDetails?.ixcode}}</span>
								</h4>
								<h4 class="graySubHead textWrap" *ngIf="claimPreviewDetails?.selectCustomerId">Customer Code:
									<span class="black">{{claimPreviewDetails?.selectCustomerId}}</span>
								</h4>
								<h4 class="graySubHead textWrap" *ngIf="claimPreviewDetails?.selectCustomer">Customer Name:
									<span class="black">{{claimPreviewDetails?.selectCustomer}}</span>
								</h4>
								<h4 class="graySubHead textWrap" *ngIf="claimPreviewDetails?.reason_code_erpcode">Reason Code:
									<span class="black">{{claimPreviewDetails?.reason_code_label}}
										({{claimPreviewDetails?.reason_code_erpcode}})</span>
								</h4>
								<h4 class="graySubHead textWrap" *ngIf="claimPreviewDetails?.transaction_date">Transaction Date
									:
									<span class="black">{{claimPreviewDetails?.transaction_date| toDateObj | date: 'yyyy-MM-dd'
										}}</span>
								</h4>
								<h4 class="graySubHead textWrap" *ngIf="claimPreviewDetails?.source_claim_no">Source Claim
									No.
									:
									<a><span class="highlight"
											(click)="doClickViewClaim(claimPreviewDetails)">{{claimPreviewDetails?.source_claim_no}}</span></a>
								</h4>
								<h4>
									<dx-button *ngIf="downloadDocumentURL" (onClick)="doclickRefrenceDocument()"
										class="secondaryBtn button" type="normal" stylingMode="contained"
										[useSubmitBehavior]="false" icon="download" text="Reference Document">
									</dx-button>
									<dx-button *ngIf="claimDocumentURL" (onClick)="doClickClaimedDocument()"
										class="secondaryBtn button mrgL10" type="normal" stylingMode="contained"
										[useSubmitBehavior]="false" icon="download" text="Claim Document">
									</dx-button>
									<dx-button *ngIf="claimPreviewDetails?.claimedStatus !=='inprocess' && outputDocumentURL"
										(onClick)="doClickOutputDocument()" class="secondaryBtn button mrgL10" type="normal"
										stylingMode="contained" [useSubmitBehavior]="false" icon="download"
										text="Output Document">
									</dx-button>
								</h4>

							</div>
							<div class="orderStatus">
								<ul class="statusBx">
									<li><span class="stLbl">Claim Status : </span>
										<span class="stData stBtn"
											*ngIf="(claimPreviewDetails.claimedStatus === 'inprocess' && callFrom !== 'notifications') || claimPreviewDetails.claimedStatus !== 'inprocess'"
											[ngClass]="claimPreviewDetails.claimedStatus || '' | filterFromList:listOfStatuses : 'value' : 'classnameBg'">
											{{claimPreviewDetails.claimedStatus || '' | filterFromList:listOfStatuses
											:'value':'text'}}</span>
										<span
											*ngIf="(claimPreviewDetails.claimedStatus === 'inprocess' && callFrom === 'notifications')">
											<dx-drop-down-button [splitButton]="false"
												[ngClass]="getClassNameForStatusField(claimPreviewDetails.claimedStatus)"
												[text]="claimPreviewDetails.claimedStatus || '' | filterFromList:listOfStatuses : 'value' : 'text'"
												stylingMode="contained"
												class="dynamicBgColors secondaryBtn dynamicarow ix-textleftalign"
												[dataSource]="listOfApproveStatuses" [wrapItemText]="true" displayExpr="text"
												keyExpr="value" (onButtonClick)="getListOfApproveStatus(claimPreviewDetails)">
											</dx-drop-down-button>
										</span>
									</li>
									<li class="d-flex"><span class="stLbl">Chargeback Period :</span>
										<strong class="black stLbl">{{claimPreviewDetails?.chargeback_period| toDateObj | date:
											'MMMM,yyyy'}}</strong>
										<!-- <dx-date-box type="date" [value]="claimPreviewDetails?.chargeback_period || nowDate"
											[min]="minMaxEndUsersChargebackContractModelUI?.startDate || nowDate"
											[max]="minMaxEndUsersChargebackContractModelUI?.endDate || nowDate"
											displayFormat="monthAndYear" [elementAttr]="{ class: 'dxDateBoxHeight' }"
											(onValueChanged)="doFilterDateOnValueChanged($event)"
											[calendarOptions]="{ maxZoomLevel: 'year', minZoomLevel: 'decade' }">
										</dx-date-box> -->
									</li>
									<li><span class="stLbl">Document Date :</span><strong
											class="black">{{claimPreviewDetails?.documentDate| toDateObj | date: 'yyyy-MM-dd'
											}}</strong>
									</li>
									<li *ngIf="claimPreviewDetails?.claimtype"><span class="stLbl">Claim Type :</span><strong
											class="black">{{claimPreviewDetails?.claimtype == 'manual_adjustment' ? 'Manual
											Adjustment' : '' }}</strong>
									</li>
									<li><span class="stLbl">Cut-Off Date :</span><strong
											class="black">{{claimPreviewDetails?.cut_off_date| toDateObj | date: 'yyyy-MM-dd'
											}}</strong>
									</li>
									<!-- <li><span class="stLbl">Transaction Date :</span><strong
											class="black">{{claimPreviewDetails?.transaction_date| toDateObj | date: 'yyyy-MM-dd'
											}}</strong>
									</li> -->
									<!-- <li>
										<div>
											<dx-button class="secondaryBtn" text="Approval Trail" type="normal"
												stylingMode="contained" (onClick)="doOpenAuditTrailDrawer()"
												[ngClass]="{'grayButton':!isShowAuditTrailBtn}" [useSubmitBehavior]="false"
												[disabled]="!isShowAuditTrailBtn">
											</dx-button>
										</div>
									</li> -->
								</ul>
							</div>
						</div>
					</div>
					<div class="hrLine"></div>
					<div class="tabbing cf">
						<ul>
							<li *ngIf="!isShowOverrideItemsTab" (click)="doChangeTab('succeed_items')"
								[ngClass]="{active: currentTab === 'succeed_items'}">
								<a>Claimed Item(s)</a>
							</li>
							<li *ngIf="!isShowOverrideItemsTab" (click)="doChangeTab('ignored_items')"
								[ngClass]="{active: currentTab === 'ignored_items'}">
								<a>Ignored Item(s)</a>
							</li>
							<li *ngIf="isShowOverrideItemsTab" (click)="doChangeTab('override_items')"
								[ngClass]="{active: currentTab === 'override_items'}">
								<a>Override Item(s)</a>
							</li>
							<li (click)="doChangeTab('approvals_trail')"
								[ngClass]="{active: currentTab === 'approvals_trail'}">
								<a>Approval Trail</a>
							</li>
						</ul>
					</div>
					<div *ngIf="currentTab === 'succeed_items' || currentTab === 'override_items'">
						<dx-data-grid #succeedClaimItemsRef id="gridContainer" [dataSource]="succeedClaimItemsDataSource"
							[remoteOperations]="false" keyExpr="guid" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
							[showBorders]="true" [showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
							[filterSyncEnabled]="true" [filterValue]="filterValue" [remoteOperations]="false"
							[columnAutoWidth]="true" [allowColumnReordering]="true" [width]="'100%'"
							(onToolbarPreparing)="doSucceedItemsToolbarPreparing($event)" [height]="'calc(100vh - 410px)'"
							class="cellColor hoverClr hideFilterPanelInx" (onCellClick)="doSucceedItemsCellClick($event)"
							(onInitialized)="doInitializedSucceedClaimItemDataGrid($event)">
							<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [showScrollbar]="'always'"
								[bounceEnabled]="false" [useNative]="false"></dxo-scrolling>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxo-filter-panel [visible]="true"></dxo-filter-panel>
							<dxo-group-panel [visible]="true"></dxo-group-panel>
							<dxo-header-filter [visible]="false"></dxo-header-filter>
							<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
							<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadSucceedItemTab"
								[customSave]="doSaveSucceedItemTab" [storageKey]="stateStorageSucceedTabKey">
							</dxo-state-storing>
							<div *dxTemplate="let data of 'templateSucceedFilterText'">
								<div (click)="doOpenSucceedFilterBuilder()" class="highlight mrgR10 pointerMark">
									<span>{{filterTextForSucceed}}</span>
								</div>
							</div>

							<div *dxTemplate="let data of 'templateSucceedActionBtns'">
								<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false"
									[showArrowIcon]="false" text="Action"
									class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
									[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code"
									icon="spindown" (onButtonClick)="doActionsBtnClickForSucceed($event)"
									(onItemClick)="doActionsBtnItemClickForSucceed($event)"
									[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
							</div>
							<dxi-column caption="End-User Code" dataField="end_user_code"
								cellTemplate="cellTemplateEndUserCode" dataType="string" [visible]="!isCustomerChargebackbasis"
								[showInColumnChooser]="!isCustomerChargebackbasis">
								<div *dxTemplate="let element of 'cellTemplateEndUserCode'" class="documentStatusColor"
									[ngClass]="(!isCustomerChargebackbasis && (element.data.type === 'override') && element.data.status) || '' | filterFromList:listOfStatuses : 'value' : 'classnameClrBefore'">
									{{element.data.end_user_code || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="End-User" [groupIndex]="isCustomerChargebackbasis ? undefined : 0"
								dataField="end_user_name" cellTemplate="cellTemplateEndUser" dataType="string"
								[visible]="!isCustomerChargebackbasis" [showInColumnChooser]="!isCustomerChargebackbasis">
								<div *dxTemplate="let element of 'cellTemplateEndUser'">
									{{element.data.end_user_name || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="PriceList No." dataField="pricelistno" cellTemplate="cellTemplatePriceListNo"
								dataType="string">
								<div *dxTemplate="let element of 'cellTemplatePriceListNo'" class=""
									[ngClass]="{'documentStatusColor orangeClrBefore':isCustomerChargebackbasis && element.data.type === 'override'}">
									{{element.data.pricelistno || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="PriceList Name" dataField="pricelistname"
								cellTemplate="cellTemplatePriceListName" dataType="string">
								<div *dxTemplate="let element of 'cellTemplatePriceListName'">
									{{element.data.pricelistname || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="Item" dataField="itemname" cellTemplate="cellTemplateItemName"
								dataType="string">
								<div *dxTemplate="let element of 'cellTemplateItemName'">
									{{element.data.itemname || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="Item Description" dataField="description"
								cellTemplate="cellTemplateDescription" dataType="string">
								<div *dxTemplate="let element of 'cellTemplateDescription'">
									{{element.data.description || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="Min" dataField="min" [allowEditing]="false" cellTemplate="cellTemplateMin"
								[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true" dataType="string">
								<div *dxTemplate="let element of 'cellTemplateMin'">
									{{element.data.min || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="Max" dataField="max" [allowEditing]="false" cellTemplate="cellTemplateMax"
								[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true" dataType="string">
								<div *dxTemplate="let element of 'cellTemplateMax'">
									{{element.data.max || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="UOM (Contract)" dataField="selectedUom" cellTemplate="cellTemplateContractUOM"
								alignment="center" dataType="string">
								<div *dxTemplate="let element of 'cellTemplateContractUOM'">
									{{element.data.selectedUom | uppercase}}
								</div>
							</dxi-column>>
							<dxi-column caption="Total Purchase" dataField="total_bought" dataType="number"
								[allowHeaderFiltering]="false" [allowFiltering]="false" [allowEditing]="false"
								[visible]="isShowMinMaxColumn" cellTemplate="cellTemplateTotalPurchase" alignment="right">
								<div *dxTemplate="let element of 'cellTemplateTotalPurchase'">
									<span
										[ngClass]="{'highlight pointerMark': element.data.total_bought > 0}">{{element.data.total_bought}}</span>
								</div>
							</dxi-column>
							<dxi-column caption="Other Purchase" dataField="other_bought" [allowEditing]="false"
								cellTemplate="cellTemplateOtherBought" [allowHeaderFiltering]="false" [allowFiltering]="false"
								[allowSorting]="true" dataType="number" alignment="right" [visible]="isShowMinMaxColumn">
								<div *dxTemplate="let element of 'cellTemplateOtherBought'">
									<span
										[ngClass]="{'highlight pointerMark': element.data.other_bought > 0}">{{element.data.other_bought}}</span>
								</div>
							</dxi-column>
							<dxi-column caption="Claimed Qty" dataField="quantity" cellTemplate="cellTemplateClaimed"
								alignment="center" dataType="string">
								<div *dxTemplate="let element of 'cellTemplateClaimed'">
									{{element.data.quantity || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="Customer Price" dataField="listPrice" alignment="right" dataType="number"
								[allowHeaderFiltering]="false" [allowFiltering]="false">
								<dxo-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-format>
								<div *dxTemplate="let element of 'cellTemplateCustomerPrice'">
									{{element.data.listPrice|| 0 | customCurrencyPipe}}
								</div>
							</dxi-column>
							<dxi-column
								[caption]="isCustomerChargebackbasis ? 'Customer Invoice Price' : 'End-user Invoice Price'"
								dataField="final_amount" dataType="number" [allowHeaderFiltering]="false"
								[allowFiltering]="false">
								<dxo-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-format>
							</dxi-column>

							<dxi-column caption="Chargeback Amount (Contract)" dataField="chargebackAmount" alignment="right"
								dataType="number" [allowHeaderFiltering]="false" [allowFiltering]="false">
								<dxo-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-format>
								<div *dxTemplate="let element of 'cellTemplateChargebackAmountContract'">
									{{element.data.chargebackAmount|| 0 | customCurrencyPipe}}
								</div>
							</dxi-column>
							<dxi-column caption="End-User Payout Code" dataField="enduser_payout_code"
								[visible]="!isCustomerChargebackbasis" [showInColumnChooser]="!isCustomerChargebackbasis"
								cellTemplate="cellTemplateEndUserPayoutCode" alignment="center" dataType="string">
								<div *dxTemplate="let element of 'cellTemplateEndUserPayoutCode'">
									{{element.data.enduser_payout_code || ''}}
								</div>
							</dxi-column>
							<dxi-column dataField="taxexempt" caption="Tax Exempt" cellTemplate="cellTemplateTaxExempt"
								dataType="boolean">
								<dxo-header-filter [dataSource]="listOfTaxExemptHeaderFilter">
								</dxo-header-filter>
								<div *dxTemplate="let element of 'cellTemplateTaxExempt'">
									{{element.data.taxexempt ? 'YES' :'NO'}}
								</div>
							</dxi-column>
							<dxi-column caption="Tax Rate" dataField="taxrate" cellTemplate="cellTemplatetaxrate"
								alignment="center" dataType="number">
								<div *dxTemplate="let element of 'cellTemplatetaxrate'">
									{{element.data.taxrate || 0 | customNumberNoRoundingPipe}} %
								</div>
							</dxi-column>
							<dxi-column caption="Tax Amount" dataField="taxamount" dataType="number">
								<dxo-format type="currency" [precision]="decimalPointForCurrency"></dxo-format>
							</dxi-column>

							<dxi-column caption="Claimed Amount" dataField="linetotal" dataType="number"
								[allowHeaderFiltering]="false" [allowFiltering]="false">
								<dxo-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-format>
							</dxi-column>
							<dxi-column caption="Status" dataField="status" dataType="string" [allowHeaderFiltering]="true"
								[allowFiltering]="false" cellTemplate="cellTemplateWorkflowStatus">
								<dxo-header-filter [dataSource]="listOfStatuses"> </dxo-header-filter>
								<div *dxTemplate="let element of 'cellTemplateWorkflowStatus'">
									<div
										[ngClass]="element.data.status || '' | filterFromList:listOfStatuses : 'value' : 'classname'">
										{{element.data.status || '' | filterFromList: listOfStatuses:'value':'text'}}
									</div>
								</div>
							</dxi-column>
							<dxi-column dataField="reason_value" dataType="string" caption="Reason"
								cellTemplate="cellTemplateReasonValue">
								<dxo-header-filter [dataSource]="listOfCustomReason" valueExpr="value" displayExpr="label">
								</dxo-header-filter>
								<div *dxTemplate="let element of 'cellTemplateReasonValue'">
									{{element.data.reason_value || '' | filterFromList:listOfCustomReason : 'value' : 'label'}}
								</div>
							</dxi-column>
							<dxi-column dataField="reason_description" dataType="string" caption="Reason Description">
							</dxi-column>

							<dxo-summary>
								<dxi-total-item column="quantity" alignment="center" summaryType="sum" displayFormat="{0}">
								</dxi-total-item>
								<dxi-total-item column="amount" alignment="right" summaryType="sum" displayFormat="{0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>
								<dxi-total-item column="linetotal" alignment="right" summaryType="sum" displayFormat="{0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>

								<dxi-total-item column="taxamount" alignment="right" summaryType="sum" displayFormat="{0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>

								<dxi-group-item column="quantity" summaryType="sum" displayFormat="{0}"
									[showInGroupFooter]="false" [alignByColumn]="true">
								</dxi-group-item>
								<dxi-group-item column="linetotal" summaryType="sum" displayFormat="{0}"
									[showInGroupFooter]="false" [alignByColumn]="true">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-group-item>

								<dxi-group-item column="taxamount" summaryType="sum" displayFormat="{0}"
									[showInGroupFooter]="false" [alignByColumn]="true">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-group-item>

							</dxo-summary>
						</dx-data-grid>
					</div>
					<div *ngIf="currentTab === 'ignored_items'">
						<dx-data-grid #ignoredClaimItemsRef id="gridContainer" [dataSource]="ignoredClaimItemsDataSource"
							[remoteOperations]="false" keyExpr="guid" [wordWrapEnabled]="true" [hoverStateEnabled]="true"
							[showBorders]="true" [showColumnLines]="true" [showRowLines]="false" [allowColumnResizing]="true"
							[filterSyncEnabled]="true" [filterValue]="filterValue" [remoteOperations]="false"
							[columnAutoWidth]="true" [allowColumnReordering]="true" [width]="'100%'"
							(onSelectionChanged)="doSelectionChangedIgnoredGrd($event)"
							(onEditorPreparing)="doEditorPreparingIgnoredGrd($event)"
							[(selectedRowKeys)]="selectedRowKeysForIgnoreItems"
							[customizeColumns]="ignoredClaimItemGridCustomizeColumns"
							(onInitialized)="doInitializedIgnoredClaimItemDataGrid($event)"
							(onCellClick)="doIgnoredItemsCellClick($event)"
							(onToolbarPreparing)="doIgnoredItemsToolbarPreparing($event)" [height]="'calc(100vh - 410px)'"
							class="cellColor hoverClr hideFilterPanelInx">
							<dxo-scrolling mode="virtual" rowRenderingMode="virtual" [showScrollbar]="'always'"
								[bounceEnabled]="false" [useNative]="false"></dxo-scrolling>
							<dxo-filter-row [visible]="true"></dxo-filter-row>
							<dxo-filter-panel [visible]="true"></dxo-filter-panel>
							<dxo-group-panel [visible]="true"></dxo-group-panel>
							<dxo-header-filter [visible]="false"></dxo-header-filter>
							<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
							<dxo-selection mode="multiple"
								*ngIf="claimPreviewDetails.claimedStatus === MinMaxChargebackStatusValEnum.DRAFT && !isNotficationApprovalView"></dxo-selection>
							<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadIgnoredItemTab"
								[customSave]="doSaveIgnoredItemTab" [storageKey]="stateStorageIgnoredTabKey">
							</dxo-state-storing>
							<dxo-editing mode="row"
								[allowUpdating]="claimPreviewDetails.claimedStatus === MinMaxChargebackStatusValEnum.DRAFT && !isNotficationApprovalView"
								[allowDeleting]="false" [allowAdding]="false" [useIcons]="true">
							</dxo-editing>
							<div *dxTemplate="let data of 'templateIgnoredFilterText'">
								<div (click)="doOpenIgnoredFilterBuilder()" class="highlight mrgR10 pointerMark">
									<span>{{filterTextForIgnored}}</span>
								</div>
							</div>
							<div *dxTemplate="let data of 'templateIgnoredActionBtns'">
								<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false"
									[showArrowIcon]="false" text="Action"
									class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
									[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code"
									icon="spindown" (onButtonClick)="doActionsBtnClickForIgnored($event)"
									(onItemClick)="doActionsBtnItemClickForIgnored($event)"
									[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
							</div>
							<dxi-column caption="End-User Code" dataField="end_user_code"
								cellTemplate="cellTemplateEndUserCode" dataType="string" [visible]="!isCustomerChargebackbasis"
								[showInColumnChooser]="!isCustomerChargebackbasis" [allowEditing]="false">
								<div *dxTemplate="let element of 'cellTemplateEndUserCode'">
									{{element.data.end_user_code || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="End-User" dataField="end_user_name" cellTemplate="cellTemplateEndUser"
								dataType="string" [groupIndex]="isCustomerChargebackbasis ? undefined : 0"
								[visible]="!isCustomerChargebackbasis" [showInColumnChooser]="!isCustomerChargebackbasis"
								[allowEditing]="false">
								<div *dxTemplate="let element of 'cellTemplateEndUser'">
									{{element.data.end_user_name || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="PriceList No." dataField="pricelistno" cellTemplate="cellTemplatePriceListNo"
								dataType="string" [allowEditing]="false">
								<div *dxTemplate="let element of 'cellTemplatePriceListNo'">
									{{element.data.pricelistno || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="PriceList Name" dataField="pricelistname"
								cellTemplate="cellTemplatePriceListName" dataType="string" [allowEditing]="false">
								<div *dxTemplate="let element of 'cellTemplatePriceListName'">
									{{element.data.pricelistname || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="Item" dataField="itemname" cellTemplate="cellTemplateItemName"
								dataType="string" [allowEditing]="false">
								<div *dxTemplate="let element of 'cellTemplateItemName'">
									{{element.data.itemname || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="Item Description" dataField="description"
								cellTemplate="cellTemplateDescription" dataType="string" [allowEditing]="false">
								<div *dxTemplate="let element of 'cellTemplateDescription'">
									{{element.data.description || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="Min" dataField="min" [allowEditing]="false" cellTemplate="cellTemplateMin"
								[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true" dataType="string">
								<div *dxTemplate="let element of 'cellTemplateMin'">
									{{element.data.min || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="Max" dataField="max" [allowEditing]="false" cellTemplate="cellTemplateMax"
								[allowHeaderFiltering]="false" [allowFiltering]="true" [allowSorting]="true" dataType="string">
								<div *dxTemplate="let element of 'cellTemplateMax'">
									{{element.data.max || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="UOM (Contract)" dataField="selectedUom" cellTemplate="cellTemplateContractUOM"
								alignment="center" dataType="string" [allowEditing]="false">
								<div *dxTemplate="let element of 'cellTemplateContractUOM'">
									{{element.data.selectedUom | uppercase}}
								</div>
							</dxi-column>
							<dxi-column caption="Total Purchase" dataField="total_bought" dataType="number"
								[allowHeaderFiltering]="false" [allowFiltering]="false" [allowEditing]="false"
								[visible]="isShowMinMaxColumn" cellTemplate="cellTemplateTotalPurchase" alignment="right">
								<div *dxTemplate="let element of 'cellTemplateTotalPurchase'">
									<span
										[ngClass]="{'highlight pointerMark': element.data.total_bought > 0}">{{element.data.total_bought}}</span>
								</div>
							</dxi-column>
							<dxi-column caption="Other Purchase" dataField="other_bought" [allowEditing]="false"
								cellTemplate="cellTemplateOtherBought" [allowHeaderFiltering]="false" [allowFiltering]="false"
								[allowSorting]="true" dataType="number" alignment="right" [visible]="isShowMinMaxColumn">
								<div *dxTemplate="let element of 'cellTemplateOtherBought'">
									<span
										[ngClass]="{'highlight pointerMark': element.data.other_bought > 0}">{{element.data.other_bought}}</span>
								</div>
							</dxi-column>
							<dxi-column caption="Claimed Qty" dataField="quantity" cellTemplate="cellTemplateClaimed"
								alignment="center" dataType="string" [allowEditing]="false">
								<div *dxTemplate="let element of 'cellTemplateClaimed'">
									{{element.data.quantity || ''}}
								</div>
							</dxi-column>
							<dxi-column caption="Customer Price" dataField="listPrice" alignment="right" dataType="number"
								[allowHeaderFiltering]="false" [allowFiltering]="false" [allowEditing]="false">
								<dxo-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-format>
								<div *dxTemplate="let element of 'cellTemplateCustomerPrice'">
									{{element.data.listPrice|| 0 | customCurrencyPipe}}
								</div>
							</dxi-column>
							<dxi-column
								[caption]="isCustomerChargebackbasis ? 'Customer Invoice Price' : 'End-user Invoice Price'"
								dataField="final_amount" dataType="number" [allowHeaderFiltering]="false"
								[allowFiltering]="false" [allowEditing]="false">
								<dxo-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-format>
							</dxi-column>

							<dxi-column caption="Chargeback Amount (Contract)" dataField="chargebackAmount" alignment="right"
								dataType="number" [allowHeaderFiltering]="false" [allowFiltering]="false"
								[allowEditing]="false">
								<dxo-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-format>
								<div *dxTemplate="let element of 'cellTemplateChargebackAmountContract'">
									{{element.data.chargebackAmount|| 0 | customCurrencyPipe}}
								</div>
							</dxi-column>
							<dxi-column caption="End-User Payout Code" dataField="enduser_payout_code"
								[visible]="!isCustomerChargebackbasis" [showInColumnChooser]="!isCustomerChargebackbasis"
								cellTemplate="cellTemplateEndUserPayoutCode" alignment="center" dataType="string"
								[allowEditing]="false">
								<div *dxTemplate="let element of 'cellTemplateEndUserPayoutCode'">
									{{element.data.enduser_payout_code || ''}}
								</div>
							</dxi-column>
							<dxi-column dataField="taxexempt" caption="Tax Exempt" cellTemplate="cellTemplateTaxExempt"
								dataType="boolean" [allowEditing]="false">
								<dxo-header-filter [dataSource]="listOfTaxExemptHeaderFilter">
								</dxo-header-filter>
								<div *dxTemplate="let element of 'cellTemplateTaxExempt'">
									{{element.data.taxexempt ? 'YES' :'NO'}}
								</div>
							</dxi-column>
							<dxi-column caption="Tax Rate" dataField="taxrate" cellTemplate="cellTemplatetaxrate"
								alignment="center" dataType="number" [allowEditing]="false">
								<div *dxTemplate="let element of 'cellTemplatetaxrate'">
									{{element.data.taxrate || 0 | customNumberNoRoundingPipe}} %
								</div>
							</dxi-column>
							<dxi-column caption="Tax Amount" dataField="taxamount" dataType="number" [allowEditing]="false">
								<dxo-format type="currency" [precision]="decimalPointForCurrency"></dxo-format>
							</dxi-column>

							<dxi-column caption="Claimed Amount" dataField="linetotal" dataType="number"
								[allowHeaderFiltering]="false" [allowFiltering]="false" [allowEditing]="false">
								<dxo-format type="currency" [precision]="decimalPointForCurrency">
								</dxo-format>
								<div *dxTemplate="let element of 'cellTemplateClaimedAmount'">
									{{element.data.linetotal|| 0 | customCurrencyPipe}}
								</div>
							</dxi-column>
							<dxi-column caption="Remark" dataField="remark" cellTemplate="cellTemplateRemark" dataType="string"
								[allowHeaderFiltering]="false" [allowFiltering]="false" [allowEditing]="false">
								<div *dxTemplate="let element of 'cellTemplateRemark'">
									{{element.data.remark || ''}}
								</div>
							</dxi-column>
							<dxi-column dataField="reason_value" [allowEditing]="true" dataType="string" caption="Reason"
								[visible]="true" [showInColumnChooser]="true" cellTemplate="cellTemplateReasonValue">
								<dxo-header-filter [dataSource]="listOfCustomReason" valueExpr="value" displayExpr="label">
								</dxo-header-filter>
								<dxo-lookup [dataSource]="listOfCustomReason" valueExpr="value" displayExpr="label"
									[allowSelectAll]="false" searchMode="contains">
								</dxo-lookup>
								<div *dxTemplate="let element of 'cellTemplateReasonValue'">
									{{element.data.reason_value || '' | filterFromList:listOfCustomReason : 'value' : 'label'}}
								</div>
								<dxi-validation-rule type="required"></dxi-validation-rule>
							</dxi-column>
							<dxi-column dataField="reason_description" dataType="string" caption="Reason Description"
								[visible]="true" [showInColumnChooser]="true" [allowEditing]="false">
								<dxi-validation-rule type="required"></dxi-validation-rule>
							</dxi-column>

							<dxi-column type="buttons" caption="Action"
								[visible]="claimPreviewDetails.claimedStatus === MinMaxChargebackStatusValEnum.DRAFT && !isNotficationApprovalView"
								[allowFiltering]="false" [allowHeaderFiltering]="false" [allowSorting]="false"
								alignment="center" [allowResizing]="true" [minWidth]="120" [width]="120"
								[allowExporting]="false" [allowEditing]="false">
								<dxi-button name="edit" cssClass="dx-grid-edit dx-icon-edit menuTip"></dxi-button>
								<!-- <dxi-button name="delete" cssClass="dx-grid-delete menuTip"></dxi-button> -->
							</dxi-column>

							<dxo-summary>
								<dxi-total-item column="quantity" alignment="center" summaryType="sum" displayFormat="{0}">
								</dxi-total-item>
								<dxi-total-item column="amount" alignment="right" summaryType="sum" displayFormat="{0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>
								<dxi-total-item column="linetotal" alignment="right" summaryType="sum" displayFormat="{0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>
								<dxi-total-item column="taxamount" alignment="right" summaryType="sum" displayFormat="{0}">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-total-item>

								<dxi-group-item column="taxamount" summaryType="sum" displayFormat="{0}"
									[showInGroupFooter]="false" [alignByColumn]="true">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-group-item>
								<dxi-group-item column="quantity" summaryType="sum" displayFormat="{0}"
									[showInGroupFooter]="false" [alignByColumn]="true">
								</dxi-group-item>
								<dxi-group-item column="linetotal" summaryType="sum" displayFormat="{0}"
									[showInGroupFooter]="false" [alignByColumn]="true">
									<dxo-value-format type="currency" [precision]="decimalPointForCurrency">
									</dxo-value-format>
								</dxi-group-item>
							</dxo-summary>

						</dx-data-grid>
					</div>
					<div *ngIf="currentTab === 'approvals_trail'">
						<app-mmeucc-claim-approval-trail [callFrom]="'claim'"
							[approvalTrailDetails]="approvalTrailDetails"></app-mmeucc-claim-approval-trail>
					</div>
					<div class="remarkBx fullNote" *ngIf="claimPreviewDetails?.claimedUserNote">
						<strong>User Note:- {{claimPreviewDetails?.claimedUserNote}}</strong>
					</div>
					<!-- <dx-button [visible]="currentTab === 'succeed_items'" [disabled]="true" icon="save"
						class="primaryBtn mrgT20 alignLeft" id="button" text="Generate Claim" type="normal"
						stylingMode="contained" (onClick)="doGenerateClaim()">
					</dx-button> -->
					<!-- <dx-button [visible]="(currentTab === 'ignored_items') && isVisibleConsiderClaimBtn" [disabled]="true" -->
					<dx-button [visible]="(currentTab === 'ignored_items' && !isNotficationApprovalView)"
						[disabled]="claimPreviewDetails.claimedStatus !== MinMaxChargebackStatusValEnum.DRAFT || selectedRowKeysForIgnoreItems.length <= 0"
						class="primaryBtn mrgT20 alignLight" text="Add Item(s) to Claim" type="normal" stylingMode="contained"
						appDebounceClick (debounceClick)="doClickConsiderForClaimItem($event)">
					</dx-button>

					<div *ngIf="isShowDropDownButton && !isNotficationApprovalView" class="mrgT20 padR0 alignRight">
						<dx-drop-down-button [dropDownOptions]="{ width: 250 }" [splitButton]="true" [showArrowIcon]="true"
							[useSelectMode]="true" [selectedItemKey]="selectedKeyDropDownBtn" [text]="getSubmitBtnText()"
							[items]="listOfStatusesBtns" [wrapItemText]="true" displayExpr="label" keyExpr="value"
							(onButtonClick)="doSaveUpdateClaimList($event)"
							(onItemClick)="doSaveUpdateClaimStatusChanged($event)" class="button dxSubmitBtn submitDDBtn"
							[width]="250">
						</dx-drop-down-button>
						<!-- doSubmitForApproval -->
					</div>
				</dx-scroll-view>
			</div>
			<!-- <div class="cols3 approvaltimelinetopix"> -->
			<div *dxTemplate="let data of 'template'" style="width: 400px">
				<div class="priceListHistoryix noBdr">
					<h2 class="mrgT10">Approval Trail</h2>
					<dx-button icon="refresh" class="alignRight borderradiusnone mrgR15" id="button" text="Refresh"
						type="normal" stylingMode="contained" (onClick)="doRefreshAuditTrail()">
					</dx-button>
					<dx-button icon="close" class="alignRight priceListHistorycloseix" type="normal" stylingMode="outlined"
						(click)="doCloseAuditTrailtDrawer()">
					</dx-button>
					<div *ngIf="auditTrailList?.length > 0">
						<dx-list class="approvaltimelinelistix">
							<dxi-item class="approvaltimelineitemix" *ngFor="let item of auditTrailList">
								<div class="approvaltimelineinnerboxix">
									<div class="approvaltimelinecalandtxt">
										<div class="approvaltimelinecal">
											<span class="calendarBx mrgT0">
												<span class="cDate">{{ item.comment_date ?
													getDateOperation(item.comment_date,'DATE') : ''}}</span>
												<span class="cMonth">
													{{ item.comment_date ?
													getDateOperation(item.comment_date,'MONTHNAME') : ''}}-{{ item.comment_date ?
													getDateOperation(item.comment_date,'YEAR') : ''}}</span>
											</span>
										</div>
										<div class="approvaltimelinetxt">
											<h3>{{item.username}}</h3>
											<h4 *ngIf="item.source_claim_no">Source Claim No.<a><span class="highlight"
														(click)="doClickViewClaim(item)">{{item.source_claim_no}}</span></a></h4>
											<span class="tlTime">
												<span class="clockIcon"></span>
												{{ item.comment_date ? getDateOperation(item.created_date,'TIME') : ''}}
											</span>
										</div>
									</div>
									<p [ngClass]="{generatedmessageix:item.comment_from === 'system'}">{{item.comment}}</p>
								</div>
							</dxi-item>
						</dx-list>
					</div>
					<div class="approvaltimelinelistix" *ngIf="auditTrailList?.length === 0">
						<div class="nocommentavailableix">
							<img [src]="_AppCommonSrvc.cdnSvgPath + 'nocommentavailable.svg'" class="nocommentavailableimgix"
								alt="No Comment Available">
							<p class="nocommentavailabletextix">No Comment Available</p>
						</div>
					</div>
					<app-ix-load-panel *ngIf="isVisibleLoader"></app-ix-load-panel>
					<!-- <div class="approvaltimelinetextareaix">
					<form class="inputDisable"
						[ngClass]="{inputDisable: claimDetail.claimedStatus !== 'inprocess' && claimDetail.claimedStatus !== 'under_review'}">
						<textarea name="w3review" rows="3" placeholder="Enter Comment" [(ngModel)]="txtComment"
							[disabled]="claimDetail.claimedStatus !== 'inprocess' && claimDetail.claimedStatus !== 'under_review'"></textarea>
						<dx-button icon="sendix" class="secondaryBtn approvaltimelinetextareaixbtn" id="button" text="Send"
							[ngClass]="{grayButton: claimDetail.claimedStatus !== 'inprocess' && claimDetail.claimedStatus !== 'under_review'}"
							type="normal" stylingMode="contained" (onClick)="doAddAuditTrail()"
							[disabled]="claimDetail.claimedStatus !== 'inprocess' && claimDetail.claimedStatus !== 'under_review'">
						</dx-button>
					</form>
				</div> -->
				</div>
			</div>
			<!-- <app-message-trall [claimDetail]="claimPreviewDetails"></app-message-trall> -->
			<!-- </div> -->
		</dx-drawer>
	</div>
</dx-popup>
<app-mmeucc-view-claim-qty-detail-popup *ngIf="isShowOtherPurchasePopup"
	[otherPurchaseProps]="otherPurchaseProps"></app-mmeucc-view-claim-qty-detail-popup>